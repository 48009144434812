import { OnOff, SettingsIec } from "src/app/types/api/settings.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField, validateNumberField } from "src/app/utils/forms";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import useForm from "src/app/utils/hooks/useForm";
import { translateValueToPercent } from "src/app/utils/helpers";
import { Button, Card, ToggleSwitch, Tooltip } from "flowbite-react";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { useEffect } from "react";
import Input from "src/app/components/Form/Input.component";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { FaDownload } from "react-icons/fa";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { isEmptyString } from "src/app/utils/typeguards";
import i18n from "src/app/translations/i18n";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		settingsIec: SettingsIec
		fetchIecSettingsProfile: (profileName: string) => void
	};

type IecForm = {
	cfgIec61850Enable: boolean
	cfgIec61850IedName: string
	cfgIec61850IedNameMax: number
	cfgIec61850PhaseVoltageDbRef: string
	cfgIec61850PhaseVoltageDbRefMin: number
	cfgIec61850PhaseVoltageDbRefMax: number
	cfgIec61850PhaseVoltageDb: string
	cfgIec61850PhaseToPhaseVoltageDbRef: string
	cfgIec61850PhaseToPhaseVoltageDbRefMin: number
	cfgIec61850PhaseToPhaseVoltageDbRefMax: number
	cfgIec61850PhaseToPhaseVoltageDb: string
	cfgIec61850UneVoltageDbRef: string
	cfgIec61850UneVoltageDbRefMin: number
	cfgIec61850UneVoltageDbRefMax: number
	cfgIec61850UneVoltageDb: string
	cfgIec61850PhaseCurrentDbRef: string
	cfgIec61850PhaseCurrentDbRefMin: number
	cfgIec61850PhaseCurrentDbRefMax: number
	cfgIec61850PhaseCurrentDb: string
	cfgIec61850NCurrentDbRef: string
	cfgIec61850NCurrentDbRefMin: number
	cfgIec61850NCurrentDbRefMax: number
	cfgIec61850NCurrentDb: string
	cfgIec61850ECurrentDbRef: string
	cfgIec61850ECurrentDbRefMin: number
	cfgIec61850ECurrentDbRefMax: number
	cfgIec61850ECurrentDb: string
	cfgIec61850ActivePowerTotalDbRef: string
	cfgIec61850ActivePowerTotalDbRefMin: number
	cfgIec61850ActivePowerTotalDbRefMax: number
	cfgIec61850ActivePowerTotalDb: string
	cfgIec61850ReactivePowerTotalDbRef: string
	cfgIec61850ReactivePowerTotalDbRefMin: number
	cfgIec61850ReactivePowerTotalDbRefMax: number
	cfgIec61850ReactivePowerTotalDb: string
	cfgIec61850ApparentPowerTotalDbRef: string
	cfgIec61850ApparentPowerTotalDbRefMin: number
	cfgIec61850ApparentPowerTotalDbRefMax: number
	cfgIec61850ApparentPowerTotalDb: string
	cfgIec61850ActivePowerPhaseDbRef: string
	cfgIec61850ActivePowerPhaseDbRefMin: number
	cfgIec61850ActivePowerPhaseDbRefMax: number
	cfgIec61850ActivePowerPhaseDb: string
	cfgIec61850ReactivePowerPhaseDbRef: string
	cfgIec61850ReactivePowerPhaseDbRefMin: number
	cfgIec61850ReactivePowerPhaseDbRefMax: number
	cfgIec61850ReactivePowerPhaseDb: string
	cfgIec61850ApparentPowerPhaseDbRef: string
	cfgIec61850ApparentPowerPhaseDbRefMin: number
	cfgIec61850ApparentPowerPhaseDbRefMax: number
	cfgIec61850ApparentPowerPhaseDb: string
	cfgIec61850FrequencyDbRef: string
	cfgIec61850FrequencyDbRefMin: number
	cfgIec61850FrequencyDbRefMax: number
	cfgIec61850FrequencyDb: string
	cfgIec61850VoltageHarmonicsDbRef: string
	cfgIec61850VoltageHarmonicsDbRefMin: number
	cfgIec61850VoltageHarmonicsDbRefMax: number
	cfgIec61850VoltageHarmonicsDb: string
	cfgIec61850CurrentHarmonicsDbRef: string
	cfgIec61850CurrentHarmonicsDbRefMin: number
	cfgIec61850CurrentHarmonicsDbRefMax: number
	cfgIec61850CurrentHarmonicsDb: string
	cfgIec61850VoltageInterharmonicsDbRef: string
	cfgIec61850VoltageInterharmonicsDbRefMin: number
	cfgIec61850VoltageInterharmonicsDbRefMax: number
	cfgIec61850VoltageInterharmonicsDb: string
	cfgIec61850CurrentInterharmonicsDbRef: string
	cfgIec61850CurrentInterharmonicsDbRefMin: number
	cfgIec61850CurrentInterharmonicsDbRefMax: number
	cfgIec61850CurrentInterharmonicsDb: string
	cfgIec61850VoltageThdDbRef: string
	cfgIec61850VoltageThdDbRefMin: number
	cfgIec61850VoltageThdDbRefMax: number
	cfgIec61850VoltageThdDb: string
	cfgIec61850CurrentThdDbRef: string
	cfgIec61850CurrentThdDbRefMin: number
	cfgIec61850CurrentThdDbRefMax: number
	cfgIec61850CurrentThdDb: string
	cfgIec61850VoltageTidDbRef: string
	cfgIec61850VoltageTidDbRefMin: number
	cfgIec61850VoltageTidDbRefMax: number
	cfgIec61850VoltageTidDb: string
	cfgIec61850CurrentTidDbRef: string
	cfgIec61850CurrentTidDbRefMin: number
	cfgIec61850CurrentTidDbRefMax: number
	cfgIec61850CurrentTidDb: string
	cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: string
	cfgIec61850UnbalanceVoltagePositiveSequenceDbRefMin: number
	cfgIec61850UnbalanceVoltagePositiveSequenceDbRefMax: number
	cfgIec61850UnbalanceVoltagePositiveSequenceDb: string
	cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: string
	cfgIec61850UnbalanceVoltageNegativeSequenceDbRefMin: number
	cfgIec61850UnbalanceVoltageNegativeSequenceDbRefMax: number
	cfgIec61850UnbalanceVoltageNegativeSequenceDb: string
	cfgIec61850UnbalanceVoltageZeroSequenceDbRef: string
	cfgIec61850UnbalanceVoltageZeroSequenceDbRefMin: number
	cfgIec61850UnbalanceVoltageZeroSequenceDbRefMax: number
	cfgIec61850UnbalanceVoltageZeroSequenceDb: string
	cfgIec61850UnbalanceVoltageNegativeDbRef: string
	cfgIec61850UnbalanceVoltageNegativeDbRefMin: number
	cfgIec61850UnbalanceVoltageNegativeDbRefMax: number
	cfgIec61850UnbalanceVoltageNegativeDb: string
	cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: string
	cfgIec61850UnbalanceCurrentPositiveSequenceDbRefMin: number
	cfgIec61850UnbalanceCurrentPositiveSequenceDbRefMax: number
	cfgIec61850UnbalanceCurrentPositiveSequenceDb: string
	cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: string
	cfgIec61850UnbalanceCurrentNegativeSequenceDbRefMin: number
	cfgIec61850UnbalanceCurrentNegativeSequenceDbRefMax: number
	cfgIec61850UnbalanceCurrentNegativeSequenceDb: string
	cfgIec61850UnbalanceCurrentZeroSequenceDbRef: string
	cfgIec61850UnbalanceCurrentZeroSequenceDbRefMin: number
	cfgIec61850UnbalanceCurrentZeroSequenceDbRefMax: number
	cfgIec61850UnbalanceCurrentZeroSequenceDb: string
	cfgIec61850UnbalanceCurrentNegativeDbRef: string
	cfgIec61850UnbalanceCurrentNegativeDbRefMin: number
	cfgIec61850UnbalanceCurrentNegativeDbRefMax: number
	cfgIec61850UnbalanceCurrentNegativeDb: string
	cfgIec61850FlickerPstDbRef: string
	cfgIec61850FlickerPstDbRefMin: number
	cfgIec61850FlickerPstDbRefMax: number
	cfgIec61850FlickerPstDb: string
	cfgIec61850FlickerPltDbRef: string
	cfgIec61850FlickerPltDbRefMin: number
	cfgIec61850FlickerPltDbRefMax: number
	cfgIec61850FlickerPltDb: string
	cfgIec6185029KhzVoltageDbRef: string
	cfgIec6185029KhzVoltageDbRefMin: number
	cfgIec6185029KhzVoltageDbRefMax: number
	cfgIec6185029KhzVoltageDb: string
	cfgIec6185029KhzVoltageMaxDbRef: string
	cfgIec6185029KhzVoltageMaxDbRefMin: number
	cfgIec6185029KhzVoltageMaxDbRefMax: number
	cfgIec6185029KhzVoltageMaxDb: string
	cfgIec618509150KhzVoltageDbRef: string
	cfgIec618509150KhzVoltageDbRefMin: number
	cfgIec618509150KhzVoltageDbRefMax: number
	cfgIec618509150KhzVoltageDb: string
	cfgIec618509150KhzVoltageMaxDbRef: string
	cfgIec618509150KhzVoltageMaxDbRefMin: number
	cfgIec618509150KhzVoltageMaxDbRefMax: number
	cfgIec618509150KhzVoltageMaxDb: string
	cfgIec61850BatteryVoltageDbRef: string
	cfgIec61850BatteryVoltageDbRefMin: number
	cfgIec61850BatteryVoltageDbRefMax: number
	cfgIec61850BatteryVoltageDb: string
}

const validator: FormValidator<IecForm> = {
	cfgIec61850Enable: () => null,
	cfgIec61850IedName: (cfgIec61850IedName, optional, form) => validateField(i18n.t("COMMUNICATIONS.IED name"), cfgIec61850IedName, optional, "she", { maxLength: form.cfgIec61850IedNameMax.value }),
	cfgIec61850IedNameMax: () => null,
	//
	cfgIec61850PhaseVoltageDbRef: (cfgIec61850PhaseVoltageDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850PhaseVoltageDbRef, optional, "it", { from: form.cfgIec61850PhaseVoltageDbRefMin.value, to: form.cfgIec61850PhaseVoltageDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850PhaseVoltageDbRefMin: () => null,
	cfgIec61850PhaseVoltageDbRefMax: () => null,
	cfgIec61850PhaseVoltageDb: (cfgIec61850PhaseVoltageDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850PhaseVoltageDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850PhaseToPhaseVoltageDbRef: (cfgIec61850PhaseToPhaseVoltageDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850PhaseToPhaseVoltageDbRef, optional, "it", { from: form.cfgIec61850PhaseToPhaseVoltageDbRefMin.value, to: form.cfgIec61850PhaseToPhaseVoltageDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850PhaseToPhaseVoltageDbRefMin: () => null,
	cfgIec61850PhaseToPhaseVoltageDbRefMax: () => null,
	cfgIec61850PhaseToPhaseVoltageDb: (cfgIec61850PhaseToPhaseVoltageDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850PhaseToPhaseVoltageDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850UneVoltageDbRef: (cfgIec61850UneVoltageDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850UneVoltageDbRef, optional, "it", { from: form.cfgIec61850UneVoltageDbRefMin.value, to: form.cfgIec61850UneVoltageDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850UneVoltageDbRefMin: () => null,
	cfgIec61850UneVoltageDbRefMax: () => null,
	cfgIec61850UneVoltageDb: (cfgIec61850UneVoltageDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850UneVoltageDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850PhaseCurrentDbRef: (cfgIec61850PhaseCurrentDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850PhaseCurrentDbRef, optional, "it", { from: form.cfgIec61850PhaseCurrentDbRefMin.value, to: form.cfgIec61850PhaseCurrentDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850PhaseCurrentDbRefMin: () => null,
	cfgIec61850PhaseCurrentDbRefMax: () => null,
	cfgIec61850PhaseCurrentDb: (cfgIec61850PhaseCurrentDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850PhaseCurrentDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850NCurrentDbRef: (cfgIec61850NCurrentDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850NCurrentDbRef, optional, "it", { from: form.cfgIec61850NCurrentDbRefMin.value, to: form.cfgIec61850NCurrentDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850NCurrentDbRefMin: () => null,
	cfgIec61850NCurrentDbRefMax: () => null,
	cfgIec61850NCurrentDb: (cfgIec61850NCurrentDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850NCurrentDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850ECurrentDbRef: (cfgIec61850ECurrentDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850ECurrentDbRef, optional, "it", { from: form.cfgIec61850ECurrentDbRefMin.value, to: form.cfgIec61850ECurrentDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850ECurrentDbRefMin: () => null,
	cfgIec61850ECurrentDbRefMax: () => null,
	cfgIec61850ECurrentDb: (cfgIec61850ECurrentDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850ECurrentDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850ActivePowerTotalDbRef: (cfgIec61850ActivePowerTotalDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850ActivePowerTotalDbRef, optional, "it", { from: form.cfgIec61850ActivePowerTotalDbRefMin.value, to: form.cfgIec61850ActivePowerTotalDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850ActivePowerTotalDbRefMin: () => null,
	cfgIec61850ActivePowerTotalDbRefMax: () => null,
	cfgIec61850ActivePowerTotalDb: (cfgIec61850ActivePowerTotalDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850ActivePowerTotalDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850ReactivePowerTotalDbRef: (cfgIec61850ReactivePowerTotalDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850ReactivePowerTotalDbRef, optional, "it", { from: form.cfgIec61850ReactivePowerTotalDbRefMin.value, to: form.cfgIec61850ReactivePowerTotalDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850ReactivePowerTotalDbRefMin: () => null,
	cfgIec61850ReactivePowerTotalDbRefMax: () => null,
	cfgIec61850ReactivePowerTotalDb: (cfgIec61850ReactivePowerTotalDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850ReactivePowerTotalDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850ApparentPowerTotalDbRef: (cfgIec61850ApparentPowerTotalDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850ApparentPowerTotalDbRef, optional, "it", { from: form.cfgIec61850ApparentPowerTotalDbRefMin.value, to: form.cfgIec61850ApparentPowerTotalDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850ApparentPowerTotalDbRefMin: () => null,
	cfgIec61850ApparentPowerTotalDbRefMax: () => null,
	cfgIec61850ApparentPowerTotalDb: (cfgIec61850ApparentPowerTotalDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850ApparentPowerTotalDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850ActivePowerPhaseDbRef: (cfgIec61850ActivePowerPhaseDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850ActivePowerPhaseDbRef, optional, "it", { from: form.cfgIec61850ActivePowerPhaseDbRefMin.value, to: form.cfgIec61850ActivePowerPhaseDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850ActivePowerPhaseDbRefMin: () => null,
	cfgIec61850ActivePowerPhaseDbRefMax: () => null,
	cfgIec61850ActivePowerPhaseDb: (cfgIec61850ActivePowerPhaseDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850ActivePowerPhaseDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850ReactivePowerPhaseDbRef: (cfgIec61850ReactivePowerPhaseDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850ReactivePowerPhaseDbRef, optional, "it", { from: form.cfgIec61850ReactivePowerPhaseDbRefMin.value, to: form.cfgIec61850ReactivePowerPhaseDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850ReactivePowerPhaseDbRefMin: () => null,
	cfgIec61850ReactivePowerPhaseDbRefMax: () => null,
	cfgIec61850ReactivePowerPhaseDb: (cfgIec61850ReactivePowerPhaseDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850ReactivePowerPhaseDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850ApparentPowerPhaseDbRef: (cfgIec61850ApparentPowerPhaseDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850ApparentPowerPhaseDbRef, optional, "it", { from: form.cfgIec61850ApparentPowerPhaseDbRefMin.value, to: form.cfgIec61850ApparentPowerPhaseDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850ApparentPowerPhaseDbRefMin: () => null,
	cfgIec61850ApparentPowerPhaseDbRefMax: () => null,
	cfgIec61850ApparentPowerPhaseDb: (cfgIec61850ApparentPowerPhaseDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850ApparentPowerPhaseDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850FrequencyDbRef: (cfgIec61850FrequencyDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850FrequencyDbRef, optional, "it", { from: form.cfgIec61850FrequencyDbRefMin.value, to: form.cfgIec61850FrequencyDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850FrequencyDbRefMin: () => null,
	cfgIec61850FrequencyDbRefMax: () => null,
	cfgIec61850FrequencyDb: (cfgIec61850FrequencyDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850FrequencyDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850VoltageHarmonicsDbRef: (cfgIec61850VoltageHarmonicsDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850VoltageHarmonicsDbRef, optional, "it", { from: form.cfgIec61850VoltageHarmonicsDbRefMin.value, to: form.cfgIec61850VoltageHarmonicsDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850VoltageHarmonicsDbRefMin: () => null,
	cfgIec61850VoltageHarmonicsDbRefMax: () => null,
	cfgIec61850VoltageHarmonicsDb: (cfgIec61850VoltageHarmonicsDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850VoltageHarmonicsDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850CurrentHarmonicsDbRef: (cfgIec61850CurrentHarmonicsDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850CurrentHarmonicsDbRef, optional, "it", { from: form.cfgIec61850CurrentHarmonicsDbRefMin.value, to: form.cfgIec61850CurrentHarmonicsDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850CurrentHarmonicsDbRefMin: () => null,
	cfgIec61850CurrentHarmonicsDbRefMax: () => null,
	cfgIec61850CurrentHarmonicsDb: (cfgIec61850CurrentHarmonicsDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850CurrentHarmonicsDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850VoltageInterharmonicsDbRef: (cfgIec61850VoltageInterharmonicsDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850VoltageInterharmonicsDbRef, optional, "it", { from: form.cfgIec61850VoltageInterharmonicsDbRefMin.value, to: form.cfgIec61850VoltageInterharmonicsDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850VoltageInterharmonicsDbRefMin: () => null,
	cfgIec61850VoltageInterharmonicsDbRefMax: () => null,
	cfgIec61850VoltageInterharmonicsDb: (cfgIec61850VoltageInterharmonicsDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850VoltageInterharmonicsDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850CurrentInterharmonicsDbRef: (cfgIec61850CurrentInterharmonicsDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850CurrentInterharmonicsDbRef, optional, "it", { from: form.cfgIec61850CurrentInterharmonicsDbRefMin.value, to: form.cfgIec61850CurrentInterharmonicsDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850CurrentInterharmonicsDbRefMin: () => null,
	cfgIec61850CurrentInterharmonicsDbRefMax: () => null,
	cfgIec61850CurrentInterharmonicsDb: (cfgIec61850CurrentInterharmonicsDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850CurrentInterharmonicsDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850VoltageThdDbRef: (cfgIec61850VoltageThdDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850VoltageThdDbRef, optional, "it", { from: form.cfgIec61850VoltageThdDbRefMin.value, to: form.cfgIec61850VoltageThdDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850VoltageThdDbRefMin: () => null,
	cfgIec61850VoltageThdDbRefMax: () => null,
	cfgIec61850VoltageThdDb: (cfgIec61850VoltageThdDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850VoltageThdDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850CurrentThdDbRef: (cfgIec61850CurrentThdDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850CurrentThdDbRef, optional, "it", { from: form.cfgIec61850CurrentThdDbRefMin.value, to: form.cfgIec61850CurrentThdDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850CurrentThdDbRefMin: () => null,
	cfgIec61850CurrentThdDbRefMax: () => null,
	cfgIec61850CurrentThdDb: (cfgIec61850CurrentThdDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850CurrentThdDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850VoltageTidDbRef: (cfgIec61850VoltageTidDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850VoltageTidDbRef, optional, "it", { from: form.cfgIec61850VoltageTidDbRefMin.value, to: form.cfgIec61850VoltageTidDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850VoltageTidDbRefMin: () => null,
	cfgIec61850VoltageTidDbRefMax: () => null,
	cfgIec61850VoltageTidDb: (cfgIec61850VoltageTidDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850VoltageTidDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850CurrentTidDbRef: (cfgIec61850CurrentTidDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850CurrentTidDbRef, optional, "it", { from: form.cfgIec61850CurrentTidDbRefMin.value, to: form.cfgIec61850CurrentTidDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850CurrentTidDbRefMin: () => null,
	cfgIec61850CurrentTidDbRefMax: () => null,
	cfgIec61850CurrentTidDb: (cfgIec61850CurrentTidDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850CurrentTidDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: (cfgIec61850UnbalanceVoltagePositiveSequenceDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850UnbalanceVoltagePositiveSequenceDbRef, optional, "it", { from: form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRefMin.value, to: form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850UnbalanceVoltagePositiveSequenceDbRefMin: () => null,
	cfgIec61850UnbalanceVoltagePositiveSequenceDbRefMax: () => null,
	cfgIec61850UnbalanceVoltagePositiveSequenceDb: (cfgIec61850UnbalanceVoltagePositiveSequenceDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850UnbalanceVoltagePositiveSequenceDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: (cfgIec61850UnbalanceVoltageNegativeSequenceDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850UnbalanceVoltageNegativeSequenceDbRef, optional, "it", { from: form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRefMin.value, to: form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850UnbalanceVoltageNegativeSequenceDbRefMin: () => null,
	cfgIec61850UnbalanceVoltageNegativeSequenceDbRefMax: () => null,
	cfgIec61850UnbalanceVoltageNegativeSequenceDb: (cfgIec61850UnbalanceVoltageNegativeSequenceDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850UnbalanceVoltageNegativeSequenceDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850UnbalanceVoltageZeroSequenceDbRef: (cfgIec61850UnbalanceVoltageZeroSequenceDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850UnbalanceVoltageZeroSequenceDbRef, optional, "it", { from: form.cfgIec61850UnbalanceVoltageZeroSequenceDbRefMin.value, to: form.cfgIec61850UnbalanceVoltageZeroSequenceDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850UnbalanceVoltageZeroSequenceDbRefMin: () => null,
	cfgIec61850UnbalanceVoltageZeroSequenceDbRefMax: () => null,
	cfgIec61850UnbalanceVoltageZeroSequenceDb: (cfgIec61850UnbalanceVoltageZeroSequenceDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850UnbalanceVoltageZeroSequenceDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850UnbalanceVoltageNegativeDbRef: (cfgIec61850UnbalanceVoltageNegativeDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850UnbalanceVoltageNegativeDbRef, optional, "it", { from: form.cfgIec61850UnbalanceVoltageNegativeDbRefMin.value, to: form.cfgIec61850UnbalanceVoltageNegativeDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850UnbalanceVoltageNegativeDbRefMin: () => null,
	cfgIec61850UnbalanceVoltageNegativeDbRefMax: () => null,
	cfgIec61850UnbalanceVoltageNegativeDb: (cfgIec61850UnbalanceVoltageNegativeDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850UnbalanceVoltageNegativeDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: (cfgIec61850UnbalanceCurrentPositiveSequenceDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850UnbalanceCurrentPositiveSequenceDbRef, optional, "it", { from: form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRefMin.value, to: form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850UnbalanceCurrentPositiveSequenceDbRefMin: () => null,
	cfgIec61850UnbalanceCurrentPositiveSequenceDbRefMax: () => null,
	cfgIec61850UnbalanceCurrentPositiveSequenceDb: (cfgIec61850UnbalanceCurrentPositiveSequenceDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850UnbalanceCurrentPositiveSequenceDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: (cfgIec61850UnbalanceCurrentNegativeSequenceDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850UnbalanceCurrentNegativeSequenceDbRef, optional, "it", { from: form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRefMin.value, to: form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850UnbalanceCurrentNegativeSequenceDbRefMin: () => null,
	cfgIec61850UnbalanceCurrentNegativeSequenceDbRefMax: () => null,
	cfgIec61850UnbalanceCurrentNegativeSequenceDb: (cfgIec61850UnbalanceCurrentNegativeSequenceDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850UnbalanceCurrentNegativeSequenceDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850UnbalanceCurrentZeroSequenceDbRef: (cfgIec61850UnbalanceCurrentZeroSequenceDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850UnbalanceCurrentZeroSequenceDbRef, optional, "it", { from: form.cfgIec61850UnbalanceCurrentZeroSequenceDbRefMin.value, to: form.cfgIec61850UnbalanceCurrentZeroSequenceDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850UnbalanceCurrentZeroSequenceDbRefMin: () => null,
	cfgIec61850UnbalanceCurrentZeroSequenceDbRefMax: () => null,
	cfgIec61850UnbalanceCurrentZeroSequenceDb: (cfgIec61850UnbalanceCurrentZeroSequenceDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850UnbalanceCurrentZeroSequenceDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850UnbalanceCurrentNegativeDbRef: (cfgIec61850UnbalanceCurrentNegativeDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850UnbalanceCurrentNegativeDbRef, optional, "it", { from: form.cfgIec61850UnbalanceCurrentNegativeDbRefMin.value, to: form.cfgIec61850UnbalanceCurrentNegativeDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850UnbalanceCurrentNegativeDbRefMin: () => null,
	cfgIec61850UnbalanceCurrentNegativeDbRefMax: () => null,
	cfgIec61850UnbalanceCurrentNegativeDb: (cfgIec61850UnbalanceCurrentNegativeDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850UnbalanceCurrentNegativeDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850FlickerPstDbRef: (cfgIec61850FlickerPstDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850FlickerPstDbRef, optional, "it", { from: form.cfgIec61850FlickerPstDbRefMin.value, to: form.cfgIec61850FlickerPstDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850FlickerPstDbRefMin: () => null,
	cfgIec61850FlickerPstDbRefMax: () => null,
	cfgIec61850FlickerPstDb: (cfgIec61850FlickerPstDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850FlickerPstDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850FlickerPltDbRef: (cfgIec61850FlickerPltDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850FlickerPltDbRef, optional, "it", { from: form.cfgIec61850FlickerPltDbRefMin.value, to: form.cfgIec61850FlickerPltDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850FlickerPltDbRefMin: () => null,
	cfgIec61850FlickerPltDbRefMax: () => null,
	cfgIec61850FlickerPltDb: (cfgIec61850FlickerPltDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850FlickerPltDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec6185029KhzVoltageDbRef: (cfgIec6185029KhzVoltageDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec6185029KhzVoltageDbRef, optional, "it", { from: form.cfgIec6185029KhzVoltageDbRefMin.value, to: form.cfgIec6185029KhzVoltageDbRefMax.value, decimalPlaces: 2 }),
	cfgIec6185029KhzVoltageDbRefMin: () => null,
	cfgIec6185029KhzVoltageDbRefMax: () => null,
	cfgIec6185029KhzVoltageDb: (cfgIec6185029KhzVoltageDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec6185029KhzVoltageDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec6185029KhzVoltageMaxDbRef: (cfgIec6185029KhzVoltageMaxDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec6185029KhzVoltageMaxDbRef, optional, "it", { from: form.cfgIec6185029KhzVoltageMaxDbRefMin.value, to: form.cfgIec6185029KhzVoltageMaxDbRefMax.value, decimalPlaces: 2 }),
	cfgIec6185029KhzVoltageMaxDbRefMin: () => null,
	cfgIec6185029KhzVoltageMaxDbRefMax: () => null,
	cfgIec6185029KhzVoltageMaxDb: (cfgIec6185029KhzVoltageMaxDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec6185029KhzVoltageMaxDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec618509150KhzVoltageDbRef: (cfgIec618509150KhzVoltageDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec618509150KhzVoltageDbRef, optional, "it", { from: form.cfgIec618509150KhzVoltageDbRefMin.value, to: form.cfgIec618509150KhzVoltageDbRefMax.value, decimalPlaces: 2 }),
	cfgIec618509150KhzVoltageDbRefMin: () => null,
	cfgIec618509150KhzVoltageDbRefMax: () => null,
	cfgIec618509150KhzVoltageDb: (cfgIec618509150KhzVoltageDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec618509150KhzVoltageDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec618509150KhzVoltageMaxDbRef: (cfgIec618509150KhzVoltageMaxDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec618509150KhzVoltageMaxDbRef, optional, "it", { from: form.cfgIec618509150KhzVoltageMaxDbRefMin.value, to: form.cfgIec618509150KhzVoltageMaxDbRefMax.value, decimalPlaces: 2 }),
	cfgIec618509150KhzVoltageMaxDbRefMin: () => null,
	cfgIec618509150KhzVoltageMaxDbRefMax: () => null,
	cfgIec618509150KhzVoltageMaxDb: (cfgIec618509150KhzVoltageMaxDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec618509150KhzVoltageMaxDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
	//
	cfgIec61850BatteryVoltageDbRef: (cfgIec61850BatteryVoltageDbRef, optional, form) => validateNumberField(i18n.t("COMMUNICATIONS.dead band ref"), cfgIec61850BatteryVoltageDbRef, optional, "it", { from: form.cfgIec61850BatteryVoltageDbRefMin.value, to: form.cfgIec61850BatteryVoltageDbRefMax.value, decimalPlaces: 2 }),
	cfgIec61850BatteryVoltageDbRefMin: () => null,
	cfgIec61850BatteryVoltageDbRefMax: () => null,
	cfgIec61850BatteryVoltageDb: (cfgIec61850BatteryVoltageDb, optional) => validateNumberField(i18n.t("COMMUNICATIONS.dead band"), cfgIec61850BatteryVoltageDb, optional, "it", { from: 0, to: 100, decimalPlaces: 2 }),
};

function Iec61850Card(props: Props) {

	const { t } = useTranslation();

	const {
		settingsIec,
		fetchIecSettingsProfile,
		isAdmin,
		isDownloadingProfile,
	} = props;

	const reducerForm = useReducerForm(
		"buffor",
		bufforFormActions,
		() => undefined,
	);

	const isDhcp = settingsIec.communications?.cfgEthernetDhcpEnable?.enum?.find(enumValue => enumValue.value === settingsIec.communications?.cfgEthernetDhcpEnable?.value)?.text === OnOff.ON;

	const _handleSubmit = (values: IecForm) => {
		reducerForm.handleChange("cfgIec61850Enable", values.cfgIec61850Enable);
		reducerForm.handleChange("cfgIec61850IedName", values.cfgIec61850IedName);
		reducerForm.handleChange("cfgIec61850PhaseVoltageDbRef", values.cfgIec61850PhaseVoltageDbRef);
		reducerForm.handleChange("cfgIec61850PhaseVoltageDb", values.cfgIec61850PhaseVoltageDb);
		reducerForm.handleChange("cfgIec61850PhaseToPhaseVoltageDbRef", values.cfgIec61850PhaseToPhaseVoltageDbRef);
		reducerForm.handleChange("cfgIec61850PhaseToPhaseVoltageDb", values.cfgIec61850PhaseToPhaseVoltageDb);
		reducerForm.handleChange("cfgIec61850UneVoltageDbRef", values.cfgIec61850UneVoltageDbRef);
		reducerForm.handleChange("cfgIec61850UneVoltageDb", values.cfgIec61850UneVoltageDb);
		reducerForm.handleChange("cfgIec61850PhaseCurrentDbRef", values.cfgIec61850PhaseCurrentDbRef);
		reducerForm.handleChange("cfgIec61850PhaseCurrentDb", values.cfgIec61850PhaseCurrentDb);
		reducerForm.handleChange("cfgIec61850NCurrentDbRef", values.cfgIec61850NCurrentDbRef);
		reducerForm.handleChange("cfgIec61850NCurrentDb", values.cfgIec61850NCurrentDb);
		reducerForm.handleChange("cfgIec61850ECurrentDbRef", values.cfgIec61850ECurrentDbRef);
		reducerForm.handleChange("cfgIec61850ECurrentDb", values.cfgIec61850ECurrentDb);
		reducerForm.handleChange("cfgIec61850ActivePowerTotalDbRef", values.cfgIec61850ActivePowerTotalDbRef);
		reducerForm.handleChange("cfgIec61850ActivePowerTotalDb", values.cfgIec61850ActivePowerTotalDb);
		reducerForm.handleChange("cfgIec61850ReactivePowerTotalDbRef", values.cfgIec61850ReactivePowerTotalDbRef);
		reducerForm.handleChange("cfgIec61850ReactivePowerTotalDb", values.cfgIec61850ReactivePowerTotalDb);
		reducerForm.handleChange("cfgIec61850ApparentPowerTotalDbRef", values.cfgIec61850ApparentPowerTotalDbRef);
		reducerForm.handleChange("cfgIec61850ApparentPowerTotalDb", values.cfgIec61850ApparentPowerTotalDb);
		reducerForm.handleChange("cfgIec61850ActivePowerPhaseDbRef", values.cfgIec61850ActivePowerPhaseDbRef);
		reducerForm.handleChange("cfgIec61850ActivePowerPhaseDb", values.cfgIec61850ActivePowerPhaseDb);
		reducerForm.handleChange("cfgIec61850ReactivePowerPhaseDbRef", values.cfgIec61850ReactivePowerPhaseDbRef);
		reducerForm.handleChange("cfgIec61850ReactivePowerPhaseDb", values.cfgIec61850ReactivePowerPhaseDb);
		reducerForm.handleChange("cfgIec61850ApparentPowerPhaseDbRef", values.cfgIec61850ApparentPowerPhaseDbRef);
		reducerForm.handleChange("cfgIec61850ApparentPowerPhaseDb", values.cfgIec61850ApparentPowerPhaseDb);
		reducerForm.handleChange("cfgIec61850FrequencyDbRef", values.cfgIec61850FrequencyDbRef);
		reducerForm.handleChange("cfgIec61850FrequencyDb", values.cfgIec61850FrequencyDb);
		reducerForm.handleChange("cfgIec61850VoltageHarmonicsDbRef", values.cfgIec61850VoltageHarmonicsDbRef);
		reducerForm.handleChange("cfgIec61850VoltageHarmonicsDb", values.cfgIec61850VoltageHarmonicsDb);
		reducerForm.handleChange("cfgIec61850CurrentHarmonicsDbRef", values.cfgIec61850CurrentHarmonicsDbRef);
		reducerForm.handleChange("cfgIec61850CurrentHarmonicsDb", values.cfgIec61850CurrentHarmonicsDb);
		reducerForm.handleChange("cfgIec61850VoltageInterharmonicsDbRef", values.cfgIec61850VoltageInterharmonicsDbRef);
		reducerForm.handleChange("cfgIec61850VoltageInterharmonicsDb", values.cfgIec61850VoltageInterharmonicsDb);
		reducerForm.handleChange("cfgIec61850CurrentInterharmonicsDbRef", values.cfgIec61850CurrentInterharmonicsDbRef);
		reducerForm.handleChange("cfgIec61850CurrentInterharmonicsDb", values.cfgIec61850CurrentInterharmonicsDb);
		reducerForm.handleChange("cfgIec61850VoltageThdDbRef", values.cfgIec61850VoltageThdDbRef);
		reducerForm.handleChange("cfgIec61850VoltageThdDb", values.cfgIec61850VoltageThdDb);
		reducerForm.handleChange("cfgIec61850CurrentThdDbRef", values.cfgIec61850CurrentThdDbRef);
		reducerForm.handleChange("cfgIec61850CurrentThdDb", values.cfgIec61850CurrentThdDb);
		reducerForm.handleChange("cfgIec61850VoltageTidDbRef", values.cfgIec61850VoltageTidDbRef);
		reducerForm.handleChange("cfgIec61850VoltageTidDb", values.cfgIec61850VoltageTidDb);
		reducerForm.handleChange("cfgIec61850CurrentTidDbRef", values.cfgIec61850CurrentTidDbRef);
		reducerForm.handleChange("cfgIec61850CurrentTidDb", values.cfgIec61850CurrentTidDb);
		reducerForm.handleChange("cfgIec61850UnbalanceVoltagePositiveSequenceDbRef", values.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef);
		reducerForm.handleChange("cfgIec61850UnbalanceVoltagePositiveSequenceDb", values.cfgIec61850UnbalanceVoltagePositiveSequenceDb);
		reducerForm.handleChange("cfgIec61850UnbalanceVoltageNegativeSequenceDbRef", values.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef);
		reducerForm.handleChange("cfgIec61850UnbalanceVoltageNegativeSequenceDb", values.cfgIec61850UnbalanceVoltageNegativeSequenceDb);
		reducerForm.handleChange("cfgIec61850UnbalanceVoltageZeroSequenceDbRef", values.cfgIec61850UnbalanceVoltageZeroSequenceDbRef);
		reducerForm.handleChange("cfgIec61850UnbalanceVoltageZeroSequenceDb", values.cfgIec61850UnbalanceVoltageZeroSequenceDb);
		reducerForm.handleChange("cfgIec61850UnbalanceVoltageNegativeDbRef", values.cfgIec61850UnbalanceVoltageNegativeDbRef);
		reducerForm.handleChange("cfgIec61850UnbalanceVoltageNegativeDb", values.cfgIec61850UnbalanceVoltageNegativeDb);
		reducerForm.handleChange("cfgIec61850UnbalanceCurrentPositiveSequenceDbRef", values.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef);
		reducerForm.handleChange("cfgIec61850UnbalanceCurrentPositiveSequenceDb", values.cfgIec61850UnbalanceCurrentPositiveSequenceDb);
		reducerForm.handleChange("cfgIec61850UnbalanceCurrentNegativeSequenceDbRef", values.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef);
		reducerForm.handleChange("cfgIec61850UnbalanceCurrentNegativeSequenceDb", values.cfgIec61850UnbalanceCurrentNegativeSequenceDb);
		reducerForm.handleChange("cfgIec61850UnbalanceCurrentZeroSequenceDbRef", values.cfgIec61850UnbalanceCurrentZeroSequenceDbRef);
		reducerForm.handleChange("cfgIec61850UnbalanceCurrentZeroSequenceDb", values.cfgIec61850UnbalanceCurrentZeroSequenceDb);
		reducerForm.handleChange("cfgIec61850UnbalanceCurrentNegativeDbRef", values.cfgIec61850UnbalanceCurrentNegativeDbRef);
		reducerForm.handleChange("cfgIec61850UnbalanceCurrentNegativeDb", values.cfgIec61850UnbalanceCurrentNegativeDb);
		reducerForm.handleChange("cfgIec61850FlickerPstDbRef", values.cfgIec61850FlickerPstDbRef);
		reducerForm.handleChange("cfgIec61850FlickerPstDb", values.cfgIec61850FlickerPstDb);
		reducerForm.handleChange("cfgIec61850FlickerPltDbRef", values.cfgIec61850FlickerPltDbRef);
		reducerForm.handleChange("cfgIec61850FlickerPltDb", values.cfgIec61850FlickerPltDb);
		reducerForm.handleChange("cfgIec6185029KhzVoltageDbRef", values.cfgIec6185029KhzVoltageDbRef);
		reducerForm.handleChange("cfgIec6185029KhzVoltageDb", values.cfgIec6185029KhzVoltageDb);
		reducerForm.handleChange("cfgIec6185029KhzVoltageMaxDbRef", values.cfgIec6185029KhzVoltageMaxDbRef);
		reducerForm.handleChange("cfgIec6185029KhzVoltageMaxDb", values.cfgIec6185029KhzVoltageMaxDb);
		reducerForm.handleChange("cfgIec618509150KhzVoltageDbRef", values.cfgIec618509150KhzVoltageDbRef);
		reducerForm.handleChange("cfgIec618509150KhzVoltageDb", values.cfgIec618509150KhzVoltageDb);
		reducerForm.handleChange("cfgIec618509150KhzVoltageMaxDbRef", values.cfgIec618509150KhzVoltageMaxDbRef);
		reducerForm.handleChange("cfgIec618509150KhzVoltageMaxDb", values.cfgIec618509150KhzVoltageMaxDb);
		reducerForm.handleChange("cfgIec61850BatteryVoltageDbRef", values.cfgIec61850BatteryVoltageDbRef);
		reducerForm.handleChange("cfgIec61850BatteryVoltageDb", values.cfgIec61850BatteryVoltageDb);
	};

	const _getInitialState = () => {
		const isEnabled = isDhcp ? false : (reducerForm.form.cfgIec61850Enable.value !== reducerForm.form.cfgIec61850Enable.initialValue) ? reducerForm.form.cfgIec61850Enable.value : settingsIec.communications?.cfgIec61850Enable?.enum?.find(enumValue => enumValue.value === settingsIec.communications?.cfgIec61850Enable?.value)?.text === OnOff.ON;
		return {
			cfgIec61850Enable: createFormField(isEnabled, { disabled: !isAdmin || isDhcp }),
			cfgIec61850IedName: createFormField((reducerForm.form.cfgIec61850IedName.value !== reducerForm.form.cfgIec61850IedName.initialValue) ? reducerForm.form.cfgIec61850IedName.value : (isEmptyString(settingsIec.communications?.cfgIec61850IedName?.value?.toString() ?? "") ? "template" : (settingsIec.communications?.cfgIec61850IedName?.value?.toString() ?? "")), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850IedNameMax: createFormField(settingsIec.communications?.cfgIec61850IedName?.maxValue ?? 0 ?? 0),
			cfgIec61850PhaseVoltageDbRef: createFormField((reducerForm.form.cfgIec61850PhaseVoltageDbRef.value !== reducerForm.form.cfgIec61850PhaseVoltageDbRef.initialValue) ? reducerForm.form.cfgIec61850PhaseVoltageDbRef.value : settingsIec.communications?.cfgIec61850PhaseVoltageDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850PhaseVoltageDbRefMin: createFormField(settingsIec.communications?.cfgIec61850PhaseVoltageDbRef?.minValue ?? 0 ?? 0),
			cfgIec61850PhaseVoltageDbRefMax: createFormField(settingsIec.communications?.cfgIec61850PhaseVoltageDbRef?.maxValue ?? 0 ?? 0),
			cfgIec61850PhaseVoltageDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850PhaseVoltageDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850PhaseToPhaseVoltageDbRef: createFormField((reducerForm.form.cfgIec61850PhaseToPhaseVoltageDbRef.value !== reducerForm.form.cfgIec61850PhaseToPhaseVoltageDbRef.initialValue) ? reducerForm.form.cfgIec61850PhaseToPhaseVoltageDbRef.value : settingsIec.communications?.cfgIec61850PhaseToPhaseVoltageDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850PhaseToPhaseVoltageDbRefMin: createFormField(settingsIec.communications?.cfgIec61850PhaseToPhaseVoltageDbRef?.minValue ?? 0 ?? 0),
			cfgIec61850PhaseToPhaseVoltageDbRefMax: createFormField(settingsIec.communications?.cfgIec61850PhaseToPhaseVoltageDbRef?.maxValue ?? 0 ?? 0),
			cfgIec61850PhaseToPhaseVoltageDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850PhaseToPhaseVoltageDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UneVoltageDbRef: createFormField((reducerForm.form.cfgIec61850UneVoltageDbRef.value !== reducerForm.form.cfgIec61850UneVoltageDbRef.initialValue) ? reducerForm.form.cfgIec61850UneVoltageDbRef.value : settingsIec.communications?.cfgIec61850UneVoltageDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UneVoltageDbRefMin: createFormField(settingsIec.communications?.cfgIec61850UneVoltageDbRef?.minValue ?? 0),
			cfgIec61850UneVoltageDbRefMax: createFormField(settingsIec.communications?.cfgIec61850UneVoltageDbRef?.maxValue ?? 0),
			cfgIec61850UneVoltageDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850UneVoltageDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850PhaseCurrentDbRef: createFormField((reducerForm.form.cfgIec61850PhaseCurrentDbRef.value !== reducerForm.form.cfgIec61850PhaseCurrentDbRef.initialValue) ? reducerForm.form.cfgIec61850PhaseCurrentDbRef.value : settingsIec.communications?.cfgIec61850PhaseCurrentDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850PhaseCurrentDbRefMin: createFormField(settingsIec.communications?.cfgIec61850PhaseCurrentDbRef?.minValue ?? 0),
			cfgIec61850PhaseCurrentDbRefMax: createFormField(settingsIec.communications?.cfgIec61850PhaseCurrentDbRef?.maxValue ?? 0),
			cfgIec61850PhaseCurrentDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850PhaseCurrentDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850NCurrentDbRef: createFormField((reducerForm.form.cfgIec61850NCurrentDbRef.value !== reducerForm.form.cfgIec61850NCurrentDbRef.initialValue) ? reducerForm.form.cfgIec61850NCurrentDbRef.value : settingsIec.communications?.cfgIec61850NCurrentDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850NCurrentDbRefMin: createFormField(settingsIec.communications?.cfgIec61850NCurrentDbRef?.minValue ?? 0),
			cfgIec61850NCurrentDbRefMax: createFormField(settingsIec.communications?.cfgIec61850NCurrentDbRef?.maxValue ?? 0),
			cfgIec61850NCurrentDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850NCurrentDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ECurrentDbRef: createFormField((reducerForm.form.cfgIec61850ECurrentDbRef.value !== reducerForm.form.cfgIec61850ECurrentDbRef.initialValue) ? reducerForm.form.cfgIec61850ECurrentDbRef.value : settingsIec.communications?.cfgIec61850ECurrentDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ECurrentDbRefMin: createFormField(settingsIec.communications?.cfgIec61850ECurrentDbRef?.minValue ?? 0),
			cfgIec61850ECurrentDbRefMax: createFormField(settingsIec.communications?.cfgIec61850ECurrentDbRef?.maxValue ?? 0),
			cfgIec61850ECurrentDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850ECurrentDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ActivePowerTotalDbRef: createFormField((reducerForm.form.cfgIec61850ActivePowerTotalDbRef.value !== reducerForm.form.cfgIec61850ActivePowerTotalDbRef.initialValue) ? reducerForm.form.cfgIec61850ActivePowerTotalDbRef.value : settingsIec.communications?.cfgIec61850ActivePowerTotalDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ActivePowerTotalDbRefMin: createFormField(settingsIec.communications?.cfgIec61850ActivePowerTotalDbRef?.minValue ?? 0),
			cfgIec61850ActivePowerTotalDbRefMax: createFormField(settingsIec.communications?.cfgIec61850ActivePowerTotalDbRef?.maxValue ?? 0),
			cfgIec61850ActivePowerTotalDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850ActivePowerTotalDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ReactivePowerTotalDbRef: createFormField((reducerForm.form.cfgIec61850ReactivePowerTotalDbRef.value !== reducerForm.form.cfgIec61850ReactivePowerTotalDbRef.initialValue) ? reducerForm.form.cfgIec61850ReactivePowerTotalDbRef.value : settingsIec.communications?.cfgIec61850ReactivePowerTotalDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ReactivePowerTotalDbRefMin: createFormField(settingsIec.communications?.cfgIec61850ReactivePowerTotalDbRef?.minValue ?? 0),
			cfgIec61850ReactivePowerTotalDbRefMax: createFormField(settingsIec.communications?.cfgIec61850ReactivePowerTotalDbRef?.maxValue ?? 0),
			cfgIec61850ReactivePowerTotalDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850ReactivePowerTotalDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ApparentPowerTotalDbRef: createFormField((reducerForm.form.cfgIec61850ApparentPowerTotalDbRef.value !== reducerForm.form.cfgIec61850ApparentPowerTotalDbRef.initialValue) ? reducerForm.form.cfgIec61850ApparentPowerTotalDbRef.value : settingsIec.communications?.cfgIec61850ApparentPowerTotalDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ApparentPowerTotalDbRefMin: createFormField(settingsIec.communications?.cfgIec61850ApparentPowerTotalDbRef?.minValue ?? 0),
			cfgIec61850ApparentPowerTotalDbRefMax: createFormField(settingsIec.communications?.cfgIec61850ApparentPowerTotalDbRef?.maxValue ?? 0),
			cfgIec61850ApparentPowerTotalDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850ApparentPowerTotalDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ActivePowerPhaseDbRef: createFormField((reducerForm.form.cfgIec61850ActivePowerPhaseDbRef.value !== reducerForm.form.cfgIec61850ActivePowerPhaseDbRef.initialValue) ? reducerForm.form.cfgIec61850ActivePowerPhaseDbRef.value : settingsIec.communications?.cfgIec61850ActivePowerPhaseDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ActivePowerPhaseDbRefMin: createFormField(settingsIec.communications?.cfgIec61850ActivePowerPhaseDbRef?.minValue ?? 0),
			cfgIec61850ActivePowerPhaseDbRefMax: createFormField(settingsIec.communications?.cfgIec61850ActivePowerPhaseDbRef?.maxValue ?? 0),
			cfgIec61850ActivePowerPhaseDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850ActivePowerPhaseDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ReactivePowerPhaseDbRef: createFormField((reducerForm.form.cfgIec61850ReactivePowerPhaseDbRef.value !== reducerForm.form.cfgIec61850ReactivePowerPhaseDbRef.initialValue) ? reducerForm.form.cfgIec61850ReactivePowerPhaseDbRef.value : settingsIec.communications?.cfgIec61850ReactivePowerPhaseDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ReactivePowerPhaseDbRefMin: createFormField(settingsIec.communications?.cfgIec61850ReactivePowerPhaseDbRef?.minValue ?? 0),
			cfgIec61850ReactivePowerPhaseDbRefMax: createFormField(settingsIec.communications?.cfgIec61850ReactivePowerPhaseDbRef?.maxValue ?? 0),
			cfgIec61850ReactivePowerPhaseDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850ReactivePowerPhaseDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ApparentPowerPhaseDbRef: createFormField((reducerForm.form.cfgIec61850ApparentPowerPhaseDbRef.value !== reducerForm.form.cfgIec61850ApparentPowerPhaseDbRef.initialValue) ? reducerForm.form.cfgIec61850ApparentPowerPhaseDbRef.value : settingsIec.communications?.cfgIec61850ApparentPowerPhaseDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850ApparentPowerPhaseDbRefMin: createFormField(settingsIec.communications?.cfgIec61850ApparentPowerPhaseDbRef?.minValue ?? 0),
			cfgIec61850ApparentPowerPhaseDbRefMax: createFormField(settingsIec.communications?.cfgIec61850ApparentPowerPhaseDbRef?.maxValue ?? 0),
			cfgIec61850ApparentPowerPhaseDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850ApparentPowerPhaseDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850FrequencyDbRef: createFormField((reducerForm.form.cfgIec61850FrequencyDbRef.value !== reducerForm.form.cfgIec61850FrequencyDbRef.initialValue) ? reducerForm.form.cfgIec61850FrequencyDbRef.value : settingsIec.communications?.cfgIec61850FrequencyDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850FrequencyDbRefMin: createFormField(settingsIec.communications?.cfgIec61850FrequencyDbRef?.minValue ?? 0),
			cfgIec61850FrequencyDbRefMax: createFormField(settingsIec.communications?.cfgIec61850FrequencyDbRef?.maxValue ?? 0),
			cfgIec61850FrequencyDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850FrequencyDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850VoltageHarmonicsDbRef: createFormField((reducerForm.form.cfgIec61850VoltageHarmonicsDbRef.value !== reducerForm.form.cfgIec61850VoltageHarmonicsDbRef.initialValue) ? reducerForm.form.cfgIec61850VoltageHarmonicsDbRef.value : settingsIec.communications?.cfgIec61850VoltageHarmonicsDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850VoltageHarmonicsDbRefMin: createFormField(settingsIec.communications?.cfgIec61850VoltageHarmonicsDbRef?.minValue ?? 0),
			cfgIec61850VoltageHarmonicsDbRefMax: createFormField(settingsIec.communications?.cfgIec61850VoltageHarmonicsDbRef?.maxValue ?? 0),
			cfgIec61850VoltageHarmonicsDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850VoltageHarmonicsDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850CurrentHarmonicsDbRef: createFormField((reducerForm.form.cfgIec61850CurrentHarmonicsDbRef.value !== reducerForm.form.cfgIec61850CurrentHarmonicsDbRef.initialValue) ? reducerForm.form.cfgIec61850CurrentHarmonicsDbRef.value : settingsIec.communications?.cfgIec61850CurrentHarmonicsDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850CurrentHarmonicsDbRefMin: createFormField(settingsIec.communications?.cfgIec61850CurrentHarmonicsDbRef?.minValue ?? 0),
			cfgIec61850CurrentHarmonicsDbRefMax: createFormField(settingsIec.communications?.cfgIec61850CurrentHarmonicsDbRef?.maxValue ?? 0),
			cfgIec61850CurrentHarmonicsDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850CurrentHarmonicsDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850VoltageInterharmonicsDbRef: createFormField((reducerForm.form.cfgIec61850VoltageInterharmonicsDbRef.value !== reducerForm.form.cfgIec61850VoltageInterharmonicsDbRef.initialValue) ? reducerForm.form.cfgIec61850VoltageInterharmonicsDbRef.value : settingsIec.communications?.cfgIec61850VoltageInterharmonicsDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850VoltageInterharmonicsDbRefMin: createFormField(settingsIec.communications?.cfgIec61850VoltageInterharmonicsDbRef?.minValue ?? 0),
			cfgIec61850VoltageInterharmonicsDbRefMax: createFormField(settingsIec.communications?.cfgIec61850VoltageInterharmonicsDbRef?.maxValue ?? 0),
			cfgIec61850VoltageInterharmonicsDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850VoltageInterharmonicsDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850CurrentInterharmonicsDbRef: createFormField((reducerForm.form.cfgIec61850CurrentInterharmonicsDbRef.value !== reducerForm.form.cfgIec61850CurrentInterharmonicsDbRef.initialValue) ? reducerForm.form.cfgIec61850CurrentInterharmonicsDbRef.value : settingsIec.communications?.cfgIec61850CurrentInterharmonicsDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850CurrentInterharmonicsDbRefMin: createFormField(settingsIec.communications?.cfgIec61850CurrentInterharmonicsDbRef?.minValue ?? 0),
			cfgIec61850CurrentInterharmonicsDbRefMax: createFormField(settingsIec.communications?.cfgIec61850CurrentInterharmonicsDbRef?.maxValue ?? 0),
			cfgIec61850CurrentInterharmonicsDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850CurrentInterharmonicsDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850VoltageThdDbRef: createFormField((reducerForm.form.cfgIec61850VoltageThdDbRef.value !== reducerForm.form.cfgIec61850VoltageThdDbRef.initialValue) ? reducerForm.form.cfgIec61850VoltageThdDbRef.value : settingsIec.communications?.cfgIec61850VoltageThdDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850VoltageThdDbRefMin: createFormField(settingsIec.communications?.cfgIec61850VoltageThdDbRef?.minValue ?? 0),
			cfgIec61850VoltageThdDbRefMax: createFormField(settingsIec.communications?.cfgIec61850VoltageThdDbRef?.maxValue ?? 0),
			cfgIec61850VoltageThdDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850VoltageThdDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850CurrentThdDbRef: createFormField((reducerForm.form.cfgIec61850CurrentThdDbRef.value !== reducerForm.form.cfgIec61850CurrentThdDbRef.initialValue) ? reducerForm.form.cfgIec61850CurrentThdDbRef.value : settingsIec.communications?.cfgIec61850CurrentThdDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850CurrentThdDbRefMin: createFormField(settingsIec.communications?.cfgIec61850CurrentThdDbRef?.minValue ?? 0),
			cfgIec61850CurrentThdDbRefMax: createFormField(settingsIec.communications?.cfgIec61850CurrentThdDbRef?.maxValue ?? 0),
			cfgIec61850CurrentThdDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850CurrentThdDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850VoltageTidDbRef: createFormField((reducerForm.form.cfgIec61850VoltageTidDbRef.value !== reducerForm.form.cfgIec61850VoltageTidDbRef.initialValue) ? reducerForm.form.cfgIec61850VoltageTidDbRef.value : settingsIec.communications?.cfgIec61850VoltageTidDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850VoltageTidDbRefMin: createFormField(settingsIec.communications?.cfgIec61850VoltageTidDbRef?.minValue ?? 0),
			cfgIec61850VoltageTidDbRefMax: createFormField(settingsIec.communications?.cfgIec61850VoltageTidDbRef?.maxValue ?? 0),
			cfgIec61850VoltageTidDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850VoltageTidDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850CurrentTidDbRef: createFormField((reducerForm.form.cfgIec61850CurrentTidDbRef.value !== reducerForm.form.cfgIec61850CurrentTidDbRef.initialValue) ? reducerForm.form.cfgIec61850CurrentTidDbRef.value : settingsIec.communications?.cfgIec61850CurrentTidDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850CurrentTidDbRefMin: createFormField(settingsIec.communications?.cfgIec61850CurrentTidDbRef?.minValue ?? 0),
			cfgIec61850CurrentTidDbRefMax: createFormField(settingsIec.communications?.cfgIec61850CurrentTidDbRef?.maxValue ?? 0),
			cfgIec61850CurrentTidDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850CurrentTidDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: createFormField((reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.initialValue) ? reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.value : settingsIec.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceVoltagePositiveSequenceDbRefMin: createFormField(settingsIec.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef?.minValue ?? 0),
			cfgIec61850UnbalanceVoltagePositiveSequenceDbRefMax: createFormField(settingsIec.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef?.maxValue ?? 0),
			cfgIec61850UnbalanceVoltagePositiveSequenceDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: createFormField((reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.initialValue) ? reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.value : settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceVoltageNegativeSequenceDbRefMin: createFormField(settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef?.minValue ?? 0),
			cfgIec61850UnbalanceVoltageNegativeSequenceDbRefMax: createFormField(settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef?.maxValue ?? 0),
			cfgIec61850UnbalanceVoltageNegativeSequenceDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceVoltageZeroSequenceDbRef: createFormField((reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.initialValue) ? reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.value : settingsIec.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceVoltageZeroSequenceDbRefMin: createFormField(settingsIec.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDbRef?.minValue ?? 0),
			cfgIec61850UnbalanceVoltageZeroSequenceDbRefMax: createFormField(settingsIec.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDbRef?.maxValue ?? 0),
			cfgIec61850UnbalanceVoltageZeroSequenceDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceVoltageNegativeDbRef: createFormField((reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDbRef.value !== reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDbRef.initialValue) ? reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDbRef.value : settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceVoltageNegativeDbRefMin: createFormField(settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeDbRef?.minValue ?? 0),
			cfgIec61850UnbalanceVoltageNegativeDbRefMax: createFormField(settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeDbRef?.maxValue ?? 0),
			cfgIec61850UnbalanceVoltageNegativeDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: createFormField((reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.initialValue) ? reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.value : settingsIec.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceCurrentPositiveSequenceDbRefMin: createFormField(settingsIec.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef?.minValue ?? 0),
			cfgIec61850UnbalanceCurrentPositiveSequenceDbRefMax: createFormField(settingsIec.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef?.maxValue ?? 0),
			cfgIec61850UnbalanceCurrentPositiveSequenceDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: createFormField((reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.initialValue) ? reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.value : settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceCurrentNegativeSequenceDbRefMin: createFormField(settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef?.minValue ?? 0),
			cfgIec61850UnbalanceCurrentNegativeSequenceDbRefMax: createFormField(settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef?.maxValue ?? 0),
			cfgIec61850UnbalanceCurrentNegativeSequenceDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceCurrentZeroSequenceDbRef: createFormField((reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.initialValue) ? reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.value : settingsIec.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceCurrentZeroSequenceDbRefMin: createFormField(settingsIec.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDbRef?.minValue ?? 0),
			cfgIec61850UnbalanceCurrentZeroSequenceDbRefMax: createFormField(settingsIec.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDbRef?.maxValue ?? 0),
			cfgIec61850UnbalanceCurrentZeroSequenceDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceCurrentNegativeDbRef: createFormField((reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDbRef.value !== reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDbRef.initialValue) ? reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDbRef.value : settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850UnbalanceCurrentNegativeDbRefMin: createFormField(settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeDbRef?.minValue ?? 0),
			cfgIec61850UnbalanceCurrentNegativeDbRefMax: createFormField(settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeDbRef?.maxValue ?? 0),
			cfgIec61850UnbalanceCurrentNegativeDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850FlickerPstDbRef: createFormField((reducerForm.form.cfgIec61850FlickerPstDbRef.value !== reducerForm.form.cfgIec61850FlickerPstDbRef.initialValue) ? reducerForm.form.cfgIec61850FlickerPstDbRef.value : settingsIec.communications?.cfgIec61850FlickerPstDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850FlickerPstDbRefMin: createFormField(settingsIec.communications?.cfgIec61850FlickerPstDbRef?.minValue ?? 0),
			cfgIec61850FlickerPstDbRefMax: createFormField(settingsIec.communications?.cfgIec61850FlickerPstDbRef?.maxValue ?? 0),
			cfgIec61850FlickerPstDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850FlickerPstDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850FlickerPltDbRef: createFormField((reducerForm.form.cfgIec61850FlickerPltDbRef.value !== reducerForm.form.cfgIec61850FlickerPltDbRef.initialValue) ? reducerForm.form.cfgIec61850FlickerPltDbRef.value : settingsIec.communications?.cfgIec61850FlickerPltDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850FlickerPltDbRefMin: createFormField(settingsIec.communications?.cfgIec61850FlickerPltDbRef?.minValue ?? 0),
			cfgIec61850FlickerPltDbRefMax: createFormField(settingsIec.communications?.cfgIec61850FlickerPltDbRef?.maxValue ?? 0),
			cfgIec61850FlickerPltDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850FlickerPltDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec6185029KhzVoltageDbRef: createFormField((reducerForm.form.cfgIec6185029KhzVoltageDbRef.value !== reducerForm.form.cfgIec6185029KhzVoltageDbRef.initialValue) ? reducerForm.form.cfgIec6185029KhzVoltageDbRef.value : settingsIec.communications?.cfgIec6185029KhzVoltageDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec6185029KhzVoltageDbRefMin: createFormField(settingsIec.communications?.cfgIec6185029KhzVoltageDbRef?.minValue ?? 0),
			cfgIec6185029KhzVoltageDbRefMax: createFormField(settingsIec.communications?.cfgIec6185029KhzVoltageDbRef?.maxValue ?? 0),
			cfgIec6185029KhzVoltageDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec6185029KhzVoltageDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec6185029KhzVoltageMaxDbRef: createFormField((reducerForm.form.cfgIec6185029KhzVoltageMaxDbRef.value !== reducerForm.form.cfgIec6185029KhzVoltageMaxDbRef.initialValue) ? reducerForm.form.cfgIec6185029KhzVoltageMaxDbRef.value : settingsIec.communications?.cfgIec6185029KhzVoltageMaxDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec6185029KhzVoltageMaxDbRefMin: createFormField(settingsIec.communications?.cfgIec6185029KhzVoltageMaxDbRef?.minValue ?? 0),
			cfgIec6185029KhzVoltageMaxDbRefMax: createFormField(settingsIec.communications?.cfgIec6185029KhzVoltageMaxDbRef?.maxValue ?? 0),
			cfgIec6185029KhzVoltageMaxDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec6185029KhzVoltageMaxDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec618509150KhzVoltageDbRef: createFormField((reducerForm.form.cfgIec618509150KhzVoltageDbRef.value !== reducerForm.form.cfgIec618509150KhzVoltageDbRef.initialValue) ? reducerForm.form.cfgIec618509150KhzVoltageDbRef.value : settingsIec.communications?.cfgIec618509150KhzVoltageDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec618509150KhzVoltageDbRefMin: createFormField(settingsIec.communications?.cfgIec618509150KhzVoltageDbRef?.minValue ?? 0),
			cfgIec618509150KhzVoltageDbRefMax: createFormField(settingsIec.communications?.cfgIec618509150KhzVoltageDbRef?.maxValue ?? 0),
			cfgIec618509150KhzVoltageDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec618509150KhzVoltageDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec618509150KhzVoltageMaxDbRef: createFormField((reducerForm.form.cfgIec618509150KhzVoltageMaxDbRef.value !== reducerForm.form.cfgIec618509150KhzVoltageMaxDbRef.initialValue) ? reducerForm.form.cfgIec618509150KhzVoltageMaxDbRef.value : settingsIec.communications?.cfgIec618509150KhzVoltageMaxDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec618509150KhzVoltageMaxDbRefMin: createFormField(settingsIec.communications?.cfgIec618509150KhzVoltageMaxDbRef?.minValue ?? 0),
			cfgIec618509150KhzVoltageMaxDbRefMax: createFormField(settingsIec.communications?.cfgIec618509150KhzVoltageMaxDbRef?.maxValue ?? 0),
			cfgIec618509150KhzVoltageMaxDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec618509150KhzVoltageMaxDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850BatteryVoltageDbRef: createFormField((reducerForm.form.cfgIec61850BatteryVoltageDbRef.value !== reducerForm.form.cfgIec61850BatteryVoltageDbRef.initialValue) ? reducerForm.form.cfgIec61850BatteryVoltageDbRef.value : settingsIec.communications?.cfgIec61850BatteryVoltageDbRef?.value?.toFixed(2) ?? "", { disabled: (!isAdmin || isDhcp || !isEnabled) }),
			cfgIec61850BatteryVoltageDbRefMin: createFormField(settingsIec.communications?.cfgIec61850BatteryVoltageDbRef?.minValue ?? 0),
			cfgIec61850BatteryVoltageDbRefMax: createFormField(settingsIec.communications?.cfgIec61850BatteryVoltageDbRef?.maxValue ?? 0),
			cfgIec61850BatteryVoltageDb: createFormField(translateValueToPercent(settingsIec.communications?.cfgIec61850BatteryVoltageDb).toFixed(2), { disabled: (!isAdmin || isDhcp || !isEnabled) }),
		};
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		toggleDisable,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ settingsIec ]);

	useEffect(() => {
		const isEnabled = reducerForm.form.cfgIec61850Enable.value;
		handleChange("cfgIec61850Enable", isEnabled);

		toggleDisable("cfgIec61850IedName", false);
		toggleDisable("cfgIec61850PhaseVoltageDbRef", false);
		toggleDisable("cfgIec61850PhaseVoltageDb", false);
		toggleDisable("cfgIec61850PhaseToPhaseVoltageDbRef", false);
		toggleDisable("cfgIec61850PhaseToPhaseVoltageDb", false);
		toggleDisable("cfgIec61850UneVoltageDbRef", false);
		toggleDisable("cfgIec61850UneVoltageDb", false);
		toggleDisable("cfgIec61850PhaseCurrentDbRef", false);
		toggleDisable("cfgIec61850PhaseCurrentDb", false);
		toggleDisable("cfgIec61850NCurrentDbRef", false);
		toggleDisable("cfgIec61850NCurrentDb", false);
		toggleDisable("cfgIec61850ECurrentDbRef", false);
		toggleDisable("cfgIec61850ECurrentDb", false);
		toggleDisable("cfgIec61850ActivePowerTotalDbRef", false);
		toggleDisable("cfgIec61850ActivePowerTotalDb", false);
		toggleDisable("cfgIec61850ReactivePowerTotalDbRef", false);
		toggleDisable("cfgIec61850ReactivePowerTotalDb", false);
		toggleDisable("cfgIec61850ApparentPowerTotalDbRef", false);
		toggleDisable("cfgIec61850ApparentPowerTotalDb", false);
		toggleDisable("cfgIec61850ActivePowerPhaseDbRef", false);
		toggleDisable("cfgIec61850ActivePowerPhaseDb", false);
		toggleDisable("cfgIec61850ReactivePowerPhaseDbRef", false);
		toggleDisable("cfgIec61850ReactivePowerPhaseDb", false);
		toggleDisable("cfgIec61850ApparentPowerPhaseDbRef", false);
		toggleDisable("cfgIec61850ApparentPowerPhaseDb", false);
		toggleDisable("cfgIec61850FrequencyDbRef", false);
		toggleDisable("cfgIec61850FrequencyDb", false);
		toggleDisable("cfgIec61850VoltageHarmonicsDbRef", false);
		toggleDisable("cfgIec61850VoltageHarmonicsDb", false);
		toggleDisable("cfgIec61850CurrentHarmonicsDbRef", false);
		toggleDisable("cfgIec61850CurrentHarmonicsDb", false);
		toggleDisable("cfgIec61850VoltageInterharmonicsDbRef", false);
		toggleDisable("cfgIec61850VoltageInterharmonicsDb", false);
		toggleDisable("cfgIec61850CurrentInterharmonicsDbRef", false);
		toggleDisable("cfgIec61850CurrentInterharmonicsDb", false);
		toggleDisable("cfgIec61850VoltageThdDbRef", false);
		toggleDisable("cfgIec61850VoltageThdDb", false);
		toggleDisable("cfgIec61850CurrentThdDbRef", false);
		toggleDisable("cfgIec61850CurrentThdDb", false);
		toggleDisable("cfgIec61850VoltageTidDbRef", false);
		toggleDisable("cfgIec61850VoltageTidDb", false);
		toggleDisable("cfgIec61850CurrentTidDbRef", false);
		toggleDisable("cfgIec61850CurrentTidDb", false);
		toggleDisable("cfgIec61850UnbalanceVoltagePositiveSequenceDbRef", false);
		toggleDisable("cfgIec61850UnbalanceVoltagePositiveSequenceDb", false);
		toggleDisable("cfgIec61850UnbalanceVoltageNegativeSequenceDbRef", false);
		toggleDisable("cfgIec61850UnbalanceVoltageNegativeSequenceDb", false);
		toggleDisable("cfgIec61850UnbalanceVoltageZeroSequenceDbRef", false);
		toggleDisable("cfgIec61850UnbalanceVoltageZeroSequenceDb", false);
		toggleDisable("cfgIec61850UnbalanceVoltageNegativeDbRef", false);
		toggleDisable("cfgIec61850UnbalanceVoltageNegativeDb", false);
		toggleDisable("cfgIec61850UnbalanceCurrentPositiveSequenceDbRef", false);
		toggleDisable("cfgIec61850UnbalanceCurrentPositiveSequenceDb", false);
		toggleDisable("cfgIec61850UnbalanceCurrentNegativeSequenceDbRef", false);
		toggleDisable("cfgIec61850UnbalanceCurrentNegativeSequenceDb", false);
		toggleDisable("cfgIec61850UnbalanceCurrentZeroSequenceDbRef", false);
		toggleDisable("cfgIec61850UnbalanceCurrentZeroSequenceDb", false);
		toggleDisable("cfgIec61850UnbalanceCurrentNegativeDbRef", false);
		toggleDisable("cfgIec61850UnbalanceCurrentNegativeDb", false);
		toggleDisable("cfgIec61850FlickerPstDbRef", false);
		toggleDisable("cfgIec61850FlickerPstDb", false);
		toggleDisable("cfgIec61850FlickerPltDbRef", false);
		toggleDisable("cfgIec61850FlickerPltDb", false);
		toggleDisable("cfgIec6185029KhzVoltageDbRef", false);
		toggleDisable("cfgIec6185029KhzVoltageDb", false);
		toggleDisable("cfgIec6185029KhzVoltageMaxDbRef", false);
		toggleDisable("cfgIec6185029KhzVoltageMaxDb", false);
		toggleDisable("cfgIec618509150KhzVoltageDbRef", false);
		toggleDisable("cfgIec618509150KhzVoltageDb", false);
		toggleDisable("cfgIec618509150KhzVoltageMaxDbRef", false);
		toggleDisable("cfgIec618509150KhzVoltageMaxDb", false);
		toggleDisable("cfgIec61850BatteryVoltageDbRef", false);
		toggleDisable("cfgIec61850BatteryVoltageDb", false);

		handleChange("cfgIec61850IedName", reducerForm.form.cfgIec61850IedName.value);
		handleChange("cfgIec61850PhaseVoltageDbRef", reducerForm.form.cfgIec61850PhaseVoltageDbRef.value);
		handleChange("cfgIec61850PhaseVoltageDb", reducerForm.form.cfgIec61850PhaseVoltageDb.value);
		handleChange("cfgIec61850PhaseToPhaseVoltageDbRef", reducerForm.form.cfgIec61850PhaseToPhaseVoltageDbRef.value);
		handleChange("cfgIec61850PhaseToPhaseVoltageDb", reducerForm.form.cfgIec61850PhaseToPhaseVoltageDb.value);
		handleChange("cfgIec61850UneVoltageDbRef", reducerForm.form.cfgIec61850UneVoltageDbRef.value);
		handleChange("cfgIec61850UneVoltageDb", reducerForm.form.cfgIec61850UneVoltageDb.value);
		handleChange("cfgIec61850PhaseCurrentDbRef", reducerForm.form.cfgIec61850PhaseCurrentDbRef.value);
		handleChange("cfgIec61850PhaseCurrentDb", reducerForm.form.cfgIec61850PhaseCurrentDb.value);
		handleChange("cfgIec61850NCurrentDbRef", reducerForm.form.cfgIec61850NCurrentDbRef.value);
		handleChange("cfgIec61850NCurrentDb", reducerForm.form.cfgIec61850NCurrentDb.value);
		handleChange("cfgIec61850ECurrentDbRef", reducerForm.form.cfgIec61850ECurrentDbRef.value);
		handleChange("cfgIec61850ECurrentDb", reducerForm.form.cfgIec61850ECurrentDb.value);
		handleChange("cfgIec61850ActivePowerTotalDbRef", reducerForm.form.cfgIec61850ActivePowerTotalDbRef.value);
		handleChange("cfgIec61850ActivePowerTotalDb", reducerForm.form.cfgIec61850ActivePowerTotalDb.value);
		handleChange("cfgIec61850ReactivePowerTotalDbRef", reducerForm.form.cfgIec61850ReactivePowerTotalDbRef.value);
		handleChange("cfgIec61850ReactivePowerTotalDb", reducerForm.form.cfgIec61850ReactivePowerTotalDb.value);
		handleChange("cfgIec61850ApparentPowerTotalDbRef", reducerForm.form.cfgIec61850ApparentPowerTotalDbRef.value);
		handleChange("cfgIec61850ApparentPowerTotalDb", reducerForm.form.cfgIec61850ApparentPowerTotalDb.value);
		handleChange("cfgIec61850ActivePowerPhaseDbRef", reducerForm.form.cfgIec61850ActivePowerPhaseDbRef.value);
		handleChange("cfgIec61850ActivePowerPhaseDb", reducerForm.form.cfgIec61850ActivePowerPhaseDb.value);
		handleChange("cfgIec61850ReactivePowerPhaseDbRef", reducerForm.form.cfgIec61850ReactivePowerPhaseDbRef.value);
		handleChange("cfgIec61850ReactivePowerPhaseDb", reducerForm.form.cfgIec61850ReactivePowerPhaseDb.value);
		handleChange("cfgIec61850ApparentPowerPhaseDbRef", reducerForm.form.cfgIec61850ApparentPowerPhaseDbRef.value);
		handleChange("cfgIec61850ApparentPowerPhaseDb", reducerForm.form.cfgIec61850ApparentPowerPhaseDb.value);
		handleChange("cfgIec61850FrequencyDbRef", reducerForm.form.cfgIec61850FrequencyDbRef.value);
		handleChange("cfgIec61850FrequencyDb", reducerForm.form.cfgIec61850FrequencyDb.value);
		handleChange("cfgIec61850VoltageHarmonicsDbRef", reducerForm.form.cfgIec61850VoltageHarmonicsDbRef.value);
		handleChange("cfgIec61850VoltageHarmonicsDb", reducerForm.form.cfgIec61850VoltageHarmonicsDb.value);
		handleChange("cfgIec61850CurrentHarmonicsDbRef", reducerForm.form.cfgIec61850CurrentHarmonicsDbRef.value);
		handleChange("cfgIec61850CurrentHarmonicsDb", reducerForm.form.cfgIec61850CurrentHarmonicsDb.value);
		handleChange("cfgIec61850VoltageInterharmonicsDbRef", reducerForm.form.cfgIec61850VoltageInterharmonicsDbRef.value);
		handleChange("cfgIec61850VoltageInterharmonicsDb", reducerForm.form.cfgIec61850VoltageInterharmonicsDb.value);
		handleChange("cfgIec61850CurrentInterharmonicsDbRef", reducerForm.form.cfgIec61850CurrentInterharmonicsDbRef.value);
		handleChange("cfgIec61850CurrentInterharmonicsDb", reducerForm.form.cfgIec61850CurrentInterharmonicsDb.value);
		handleChange("cfgIec61850VoltageThdDbRef", reducerForm.form.cfgIec61850VoltageThdDbRef.value);
		handleChange("cfgIec61850VoltageThdDb", reducerForm.form.cfgIec61850VoltageThdDb.value);
		handleChange("cfgIec61850CurrentThdDbRef", reducerForm.form.cfgIec61850CurrentThdDbRef.value);
		handleChange("cfgIec61850CurrentThdDb", reducerForm.form.cfgIec61850CurrentThdDb.value);
		handleChange("cfgIec61850VoltageTidDbRef", reducerForm.form.cfgIec61850VoltageTidDbRef.value);
		handleChange("cfgIec61850VoltageTidDb", reducerForm.form.cfgIec61850VoltageTidDb.value);
		handleChange("cfgIec61850CurrentTidDbRef", reducerForm.form.cfgIec61850CurrentTidDbRef.value);
		handleChange("cfgIec61850CurrentTidDb", reducerForm.form.cfgIec61850CurrentTidDb.value);
		handleChange("cfgIec61850UnbalanceVoltagePositiveSequenceDbRef", reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.value);
		handleChange("cfgIec61850UnbalanceVoltagePositiveSequenceDb", reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDb.value);
		handleChange("cfgIec61850UnbalanceVoltageNegativeSequenceDbRef", reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.value);
		handleChange("cfgIec61850UnbalanceVoltageNegativeSequenceDb", reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDb.value);
		handleChange("cfgIec61850UnbalanceVoltageZeroSequenceDbRef", reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.value);
		handleChange("cfgIec61850UnbalanceVoltageZeroSequenceDb", reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDb.value);
		handleChange("cfgIec61850UnbalanceVoltageNegativeDbRef", reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDbRef.value);
		handleChange("cfgIec61850UnbalanceVoltageNegativeDb", reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDb.value);
		handleChange("cfgIec61850UnbalanceCurrentPositiveSequenceDbRef", reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.value);
		handleChange("cfgIec61850UnbalanceCurrentPositiveSequenceDb", reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDb.value);
		handleChange("cfgIec61850UnbalanceCurrentNegativeSequenceDbRef", reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.value);
		handleChange("cfgIec61850UnbalanceCurrentNegativeSequenceDb", reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDb.value);
		handleChange("cfgIec61850UnbalanceCurrentZeroSequenceDbRef", reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.value);
		handleChange("cfgIec61850UnbalanceCurrentZeroSequenceDb", reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDb.value);
		handleChange("cfgIec61850UnbalanceCurrentNegativeDbRef", reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDbRef.value);
		handleChange("cfgIec61850UnbalanceCurrentNegativeDb", reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDb.value);
		handleChange("cfgIec61850FlickerPstDbRef", reducerForm.form.cfgIec61850FlickerPstDbRef.value);
		handleChange("cfgIec61850FlickerPstDb", reducerForm.form.cfgIec61850FlickerPstDb.value);
		handleChange("cfgIec61850FlickerPltDbRef", reducerForm.form.cfgIec61850FlickerPltDbRef.value);
		handleChange("cfgIec61850FlickerPltDb", reducerForm.form.cfgIec61850FlickerPltDb.value);
		handleChange("cfgIec6185029KhzVoltageDbRef", reducerForm.form.cfgIec6185029KhzVoltageDbRef.value);
		handleChange("cfgIec6185029KhzVoltageDb", reducerForm.form.cfgIec6185029KhzVoltageDb.value);
		handleChange("cfgIec6185029KhzVoltageMaxDbRef", reducerForm.form.cfgIec6185029KhzVoltageMaxDbRef.value);
		handleChange("cfgIec6185029KhzVoltageMaxDb", reducerForm.form.cfgIec6185029KhzVoltageMaxDb.value);
		handleChange("cfgIec618509150KhzVoltageDbRef", reducerForm.form.cfgIec618509150KhzVoltageDbRef.value);
		handleChange("cfgIec618509150KhzVoltageDb", reducerForm.form.cfgIec618509150KhzVoltageDb.value);
		handleChange("cfgIec618509150KhzVoltageMaxDbRef", reducerForm.form.cfgIec618509150KhzVoltageMaxDbRef.value);
		handleChange("cfgIec618509150KhzVoltageMaxDb", reducerForm.form.cfgIec618509150KhzVoltageMaxDb.value);
		handleChange("cfgIec61850BatteryVoltageDbRef", reducerForm.form.cfgIec61850BatteryVoltageDbRef.value);
		handleChange("cfgIec61850BatteryVoltageDb", reducerForm.form.cfgIec61850BatteryVoltageDb.value);

		toggleDisable("cfgIec61850IedName", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850PhaseVoltageDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850PhaseVoltageDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850PhaseToPhaseVoltageDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850PhaseToPhaseVoltageDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UneVoltageDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UneVoltageDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850PhaseCurrentDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850PhaseCurrentDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850NCurrentDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850NCurrentDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ECurrentDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ECurrentDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ActivePowerTotalDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ActivePowerTotalDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ReactivePowerTotalDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ReactivePowerTotalDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ApparentPowerTotalDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ApparentPowerTotalDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ActivePowerPhaseDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ActivePowerPhaseDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ReactivePowerPhaseDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ReactivePowerPhaseDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ApparentPowerPhaseDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850ApparentPowerPhaseDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850FrequencyDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850FrequencyDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850VoltageHarmonicsDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850VoltageHarmonicsDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850CurrentHarmonicsDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850CurrentHarmonicsDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850VoltageInterharmonicsDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850VoltageInterharmonicsDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850CurrentInterharmonicsDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850CurrentInterharmonicsDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850VoltageThdDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850VoltageThdDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850CurrentThdDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850CurrentThdDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850VoltageTidDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850VoltageTidDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850CurrentTidDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850CurrentTidDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceVoltagePositiveSequenceDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceVoltagePositiveSequenceDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceVoltageNegativeSequenceDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceVoltageNegativeSequenceDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceVoltageZeroSequenceDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceVoltageZeroSequenceDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceVoltageNegativeDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceVoltageNegativeDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceCurrentPositiveSequenceDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceCurrentPositiveSequenceDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceCurrentNegativeSequenceDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceCurrentNegativeSequenceDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceCurrentZeroSequenceDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceCurrentZeroSequenceDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceCurrentNegativeDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850UnbalanceCurrentNegativeDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850FlickerPstDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850FlickerPstDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850FlickerPltDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850FlickerPltDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec6185029KhzVoltageDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec6185029KhzVoltageDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec6185029KhzVoltageMaxDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec6185029KhzVoltageMaxDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec618509150KhzVoltageDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec618509150KhzVoltageDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec618509150KhzVoltageMaxDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec618509150KhzVoltageMaxDb", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850BatteryVoltageDbRef", isDhcp || !isEnabled);
		toggleDisable("cfgIec61850BatteryVoltageDb", isDhcp || !isEnabled);
	}, [
		reducerForm.form.cfgIec61850Enable.value,
		reducerForm.form.cfgIec61850IedName.value,
		reducerForm.form.cfgIec61850PhaseVoltageDbRef.value,
		reducerForm.form.cfgIec61850PhaseVoltageDb.value,
		reducerForm.form.cfgIec61850PhaseToPhaseVoltageDbRef.value,
		reducerForm.form.cfgIec61850PhaseToPhaseVoltageDb.value,
		reducerForm.form.cfgIec61850UneVoltageDbRef.value,
		reducerForm.form.cfgIec61850UneVoltageDb.value,
		reducerForm.form.cfgIec61850PhaseCurrentDbRef.value,
		reducerForm.form.cfgIec61850PhaseCurrentDb.value,
		reducerForm.form.cfgIec61850NCurrentDbRef.value,
		reducerForm.form.cfgIec61850NCurrentDb.value,
		reducerForm.form.cfgIec61850ECurrentDbRef.value,
		reducerForm.form.cfgIec61850ECurrentDb.value,
		reducerForm.form.cfgIec61850ActivePowerTotalDbRef.value,
		reducerForm.form.cfgIec61850ActivePowerTotalDb.value,
		reducerForm.form.cfgIec61850ReactivePowerTotalDbRef.value,
		reducerForm.form.cfgIec61850ReactivePowerTotalDb.value,
		reducerForm.form.cfgIec61850ApparentPowerTotalDbRef.value,
		reducerForm.form.cfgIec61850ApparentPowerTotalDb.value,
		reducerForm.form.cfgIec61850ActivePowerPhaseDbRef.value,
		reducerForm.form.cfgIec61850ActivePowerPhaseDb.value,
		reducerForm.form.cfgIec61850ReactivePowerPhaseDbRef.value,
		reducerForm.form.cfgIec61850ReactivePowerPhaseDb.value,
		reducerForm.form.cfgIec61850ApparentPowerPhaseDbRef.value,
		reducerForm.form.cfgIec61850ApparentPowerPhaseDb.value,
		reducerForm.form.cfgIec61850FrequencyDbRef.value,
		reducerForm.form.cfgIec61850FrequencyDb.value,
		reducerForm.form.cfgIec61850VoltageHarmonicsDbRef.value,
		reducerForm.form.cfgIec61850VoltageHarmonicsDb.value,
		reducerForm.form.cfgIec61850CurrentHarmonicsDbRef.value,
		reducerForm.form.cfgIec61850CurrentHarmonicsDb.value,
		reducerForm.form.cfgIec61850VoltageInterharmonicsDbRef.value,
		reducerForm.form.cfgIec61850VoltageInterharmonicsDb.value,
		reducerForm.form.cfgIec61850CurrentInterharmonicsDbRef.value,
		reducerForm.form.cfgIec61850CurrentInterharmonicsDb.value,
		reducerForm.form.cfgIec61850VoltageThdDbRef.value,
		reducerForm.form.cfgIec61850VoltageThdDb.value,
		reducerForm.form.cfgIec61850CurrentThdDbRef.value,
		reducerForm.form.cfgIec61850CurrentThdDb.value,
		reducerForm.form.cfgIec61850VoltageTidDbRef.value,
		reducerForm.form.cfgIec61850VoltageTidDb.value,
		reducerForm.form.cfgIec61850CurrentTidDbRef.value,
		reducerForm.form.cfgIec61850CurrentTidDb.value,
		reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.value,
		reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDb.value,
		reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.value,
		reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDb.value,
		reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.value,
		reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDb.value,
		reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDbRef.value,
		reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDb.value,
		reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.value,
		reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDb.value,
		reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.value,
		reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDb.value,
		reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.value,
		reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDb.value,
		reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDbRef.value,
		reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDb.value,
		reducerForm.form.cfgIec61850FlickerPstDbRef.value,
		reducerForm.form.cfgIec61850FlickerPstDb.value,
		reducerForm.form.cfgIec61850FlickerPltDbRef.value,
		reducerForm.form.cfgIec61850FlickerPltDb.value,
		reducerForm.form.cfgIec6185029KhzVoltageDbRef.value,
		reducerForm.form.cfgIec6185029KhzVoltageDb.value,
		reducerForm.form.cfgIec6185029KhzVoltageMaxDbRef.value,
		reducerForm.form.cfgIec6185029KhzVoltageMaxDb.value,
		reducerForm.form.cfgIec618509150KhzVoltageDbRef.value,
		reducerForm.form.cfgIec618509150KhzVoltageDb.value,
		reducerForm.form.cfgIec618509150KhzVoltageMaxDbRef.value,
		reducerForm.form.cfgIec618509150KhzVoltageMaxDb.value,
		reducerForm.form.cfgIec61850BatteryVoltageDbRef.value,
		reducerForm.form.cfgIec61850BatteryVoltageDb.value,
	]);

	return (
		<Card>
			<form noValidate className="flex flex-col gap-3" onSubmit={ handleSubmit }>
				<div className="flex justify-between gap-4">
					<div className="flex gap-4">
						{
							isDhcp
								?
								<LabelValue
									label={ t("COMMUNICATIONS.DHCP") }
									value={ t("COMMUNICATIONS.enabled_it") }
								/>
								:
								<>
									<LabelValue
										label={ t("COMMUNICATIONS.DHCP") }
										value={ t("COMMUNICATIONS.disabled_it") }
									/>
									<LabelValue
										label={ t("COMMUNICATIONS.profile ip address") }
										value={ settingsIec.communications?.cfgIec61850IpAddress?.value ?? "---" }
									/>
									<LabelValue
										label={ t("COMMUNICATIONS.profile ip port") }
										value={ settingsIec.communications?.cfgIec61850IpPort?.value ?? "---" }
									/>
									<LabelValue
										label={ t("COMMUNICATIONS.current ip address") }
										value={ settingsIec.communications?.cfgEthernetIpAddress?.value ?? "---" }
									/>
								</>
						}
					</div>
					<div>
						<Button
							color="primary"
							onClick={ () => fetchIecSettingsProfile(settingsIec.communications?.cfgIec61850IedName?.value?.toString() ?? "template") }
							isProcessing={ isDownloadingProfile }
						>
							<FaDownload className="w-3 h-3 mr-2"/>
							{ t("COMMUNICATIONS.download profile file") }
						</Button>
					</div>
				</div>
				<ToggleSwitch
					className={
						classNames(
							"[&>div]:w-9 [&>div]:h-5 [&>div]:after:absolute [&>div]:after:top-[2px] [&>div]:after:left-[2px] [&>div]:after:h-4 [&>div]:after:w-4",
							{ "[&>span]:!text-yellow-400": reducerForm.form.cfgIec61850Enable.value !== reducerForm.form.cfgIec61850Enable.initialValue },
						)
					}
					checked={ form.cfgIec61850Enable.value }
					disabled={ form.cfgIec61850Enable.disabled }
					label={ t("COMMUNICATIONS.enable") }
					onChange={ () => {
						const isIecEnabled = form.cfgIec61850Enable.value;
						handleChange("cfgIec61850Enable", !isIecEnabled);

						toggleDisable("cfgIec61850IedName", isIecEnabled);
						toggleDisable("cfgIec61850PhaseVoltageDbRef", isIecEnabled);
						toggleDisable("cfgIec61850PhaseVoltageDb", isIecEnabled);
						toggleDisable("cfgIec61850PhaseToPhaseVoltageDbRef", isIecEnabled);
						toggleDisable("cfgIec61850PhaseToPhaseVoltageDb", isIecEnabled);
						toggleDisable("cfgIec61850UneVoltageDbRef", isIecEnabled);
						toggleDisable("cfgIec61850UneVoltageDb", isIecEnabled);
						toggleDisable("cfgIec61850PhaseCurrentDbRef", isIecEnabled);
						toggleDisable("cfgIec61850PhaseCurrentDb", isIecEnabled);
						toggleDisable("cfgIec61850NCurrentDbRef", isIecEnabled);
						toggleDisable("cfgIec61850NCurrentDb", isIecEnabled);
						toggleDisable("cfgIec61850ECurrentDbRef", isIecEnabled);
						toggleDisable("cfgIec61850ECurrentDb", isIecEnabled);
						toggleDisable("cfgIec61850ActivePowerTotalDbRef", isIecEnabled);
						toggleDisable("cfgIec61850ActivePowerTotalDb", isIecEnabled);
						toggleDisable("cfgIec61850ReactivePowerTotalDbRef", isIecEnabled);
						toggleDisable("cfgIec61850ReactivePowerTotalDb", isIecEnabled);
						toggleDisable("cfgIec61850ApparentPowerTotalDbRef", isIecEnabled);
						toggleDisable("cfgIec61850ApparentPowerTotalDb", isIecEnabled);
						toggleDisable("cfgIec61850ActivePowerPhaseDbRef", isIecEnabled);
						toggleDisable("cfgIec61850ActivePowerPhaseDb", isIecEnabled);
						toggleDisable("cfgIec61850ReactivePowerPhaseDbRef", isIecEnabled);
						toggleDisable("cfgIec61850ReactivePowerPhaseDb", isIecEnabled);
						toggleDisable("cfgIec61850ApparentPowerPhaseDbRef", isIecEnabled);
						toggleDisable("cfgIec61850ApparentPowerPhaseDb", isIecEnabled);
						toggleDisable("cfgIec61850FrequencyDbRef", isIecEnabled);
						toggleDisable("cfgIec61850FrequencyDb", isIecEnabled);
						toggleDisable("cfgIec61850VoltageHarmonicsDbRef", isIecEnabled);
						toggleDisable("cfgIec61850VoltageHarmonicsDb", isIecEnabled);
						toggleDisable("cfgIec61850CurrentHarmonicsDbRef", isIecEnabled);
						toggleDisable("cfgIec61850CurrentHarmonicsDb", isIecEnabled);
						toggleDisable("cfgIec61850VoltageInterharmonicsDbRef", isIecEnabled);
						toggleDisable("cfgIec61850VoltageInterharmonicsDb", isIecEnabled);
						toggleDisable("cfgIec61850CurrentInterharmonicsDbRef", isIecEnabled);
						toggleDisable("cfgIec61850CurrentInterharmonicsDb", isIecEnabled);
						toggleDisable("cfgIec61850VoltageThdDbRef", isIecEnabled);
						toggleDisable("cfgIec61850VoltageThdDb", isIecEnabled);
						toggleDisable("cfgIec61850CurrentThdDbRef", isIecEnabled);
						toggleDisable("cfgIec61850CurrentThdDb", isIecEnabled);
						toggleDisable("cfgIec61850VoltageTidDbRef", isIecEnabled);
						toggleDisable("cfgIec61850VoltageTidDb", isIecEnabled);
						toggleDisable("cfgIec61850CurrentTidDbRef", isIecEnabled);
						toggleDisable("cfgIec61850CurrentTidDb", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceVoltagePositiveSequenceDbRef", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceVoltagePositiveSequenceDb", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceVoltageNegativeSequenceDbRef", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceVoltageNegativeSequenceDb", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceVoltageZeroSequenceDbRef", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceVoltageZeroSequenceDb", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceVoltageNegativeDbRef", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceVoltageNegativeDb", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceCurrentPositiveSequenceDbRef", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceCurrentPositiveSequenceDb", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceCurrentNegativeSequenceDbRef", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceCurrentNegativeSequenceDb", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceCurrentZeroSequenceDbRef", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceCurrentZeroSequenceDb", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceCurrentNegativeDbRef", isIecEnabled);
						toggleDisable("cfgIec61850UnbalanceCurrentNegativeDb", isIecEnabled);
						toggleDisable("cfgIec61850FlickerPstDbRef", isIecEnabled);
						toggleDisable("cfgIec61850FlickerPstDb", isIecEnabled);
						toggleDisable("cfgIec61850FlickerPltDbRef", isIecEnabled);
						toggleDisable("cfgIec61850FlickerPltDb", isIecEnabled);
						toggleDisable("cfgIec6185029KhzVoltageDbRef", isIecEnabled);
						toggleDisable("cfgIec6185029KhzVoltageDb", isIecEnabled);
						toggleDisable("cfgIec6185029KhzVoltageMaxDbRef", isIecEnabled);
						toggleDisable("cfgIec6185029KhzVoltageMaxDb", isIecEnabled);
						toggleDisable("cfgIec618509150KhzVoltageDbRef", isIecEnabled);
						toggleDisable("cfgIec618509150KhzVoltageDb", isIecEnabled);
						toggleDisable("cfgIec618509150KhzVoltageMaxDbRef", isIecEnabled);
						toggleDisable("cfgIec618509150KhzVoltageMaxDb", isIecEnabled);
						toggleDisable("cfgIec61850BatteryVoltageDbRef", isIecEnabled);
						toggleDisable("cfgIec61850BatteryVoltageDb", isIecEnabled);
					} }
				/>
				<Input
					formItem={ form.cfgIec61850IedName }
					label={ t("COMMUNICATIONS.IED name") }
					name="cfgIec61850IedName"
					inputProps={ {
						type: "text",
						onChange: (e) => handleChange("cfgIec61850IedName", e.target.value),
						onBlur: () => handleBlur("cfgIec61850IedName"),
					} }
					hasBeenChanged={ reducerForm.form.cfgIec61850IedName.value !== reducerForm.form.cfgIec61850IedName.initialValue }
				/>
				<div className="grid grid-cols-2 gap-4">
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.phase voltage") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850PhaseVoltageDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850PhaseVoltageDbRef?.unit ?? "---" }]` }
								name="cfgIec61850PhaseVoltageDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850PhaseVoltageDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850PhaseVoltageDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850PhaseVoltageDbRef.value !== reducerForm.form.cfgIec61850PhaseVoltageDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850PhaseVoltageDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850PhaseVoltageDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850PhaseVoltageDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850PhaseVoltageDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850PhaseVoltageDb.value !== reducerForm.form.cfgIec61850PhaseVoltageDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.phase to phase voltage") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850PhaseToPhaseVoltageDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850PhaseToPhaseVoltageDbRef?.unit ?? "---" }]` }
								name="cfgIec61850PhaseToPhaseVoltageDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850PhaseToPhaseVoltageDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850PhaseToPhaseVoltageDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850PhaseToPhaseVoltageDbRef.value !== reducerForm.form.cfgIec61850PhaseToPhaseVoltageDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850PhaseToPhaseVoltageDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850PhaseToPhaseVoltageDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850PhaseToPhaseVoltageDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850PhaseToPhaseVoltageDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850PhaseToPhaseVoltageDb.value !== reducerForm.form.cfgIec61850PhaseToPhaseVoltageDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.voltage neutral earth") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850UneVoltageDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850UneVoltageDbRef?.unit ?? "---" }]` }
								name="cfgIec61850UneVoltageDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850UneVoltageDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UneVoltageDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UneVoltageDbRef.value !== reducerForm.form.cfgIec61850UneVoltageDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850UneVoltageDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850UneVoltageDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850UneVoltageDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UneVoltageDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UneVoltageDb.value !== reducerForm.form.cfgIec61850UneVoltageDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.phase currents") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850PhaseCurrentDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850PhaseCurrentDbRef?.unit ?? "---" }]` }
								name="cfgIec61850PhaseCurrentDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850PhaseCurrentDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850PhaseCurrentDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850PhaseCurrentDbRef.value !== reducerForm.form.cfgIec61850PhaseCurrentDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850PhaseCurrentDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850PhaseCurrentDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850PhaseCurrentDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850PhaseCurrentDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850PhaseCurrentDb.value !== reducerForm.form.cfgIec61850PhaseCurrentDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.neutral current") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850NCurrentDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850NCurrentDbRef?.unit ?? "---" }]` }
								name="cfgIec61850NCurrentDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850NCurrentDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850NCurrentDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850NCurrentDbRef.value !== reducerForm.form.cfgIec61850NCurrentDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850NCurrentDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850NCurrentDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850NCurrentDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850NCurrentDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850NCurrentDb.value !== reducerForm.form.cfgIec61850NCurrentDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.earth current") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850ECurrentDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850ECurrentDbRef?.unit ?? "---" }]` }
								name="cfgIec61850ECurrentDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850ECurrentDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ECurrentDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ECurrentDbRef.value !== reducerForm.form.cfgIec61850ECurrentDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850ECurrentDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850ECurrentDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850ECurrentDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ECurrentDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ECurrentDb.value !== reducerForm.form.cfgIec61850ECurrentDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.total active power") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850ActivePowerTotalDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850ActivePowerTotalDbRef?.unit ?? "---" }]` }
								name="cfgIec61850ActivePowerTotalDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850ActivePowerTotalDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ActivePowerTotalDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ActivePowerTotalDbRef.value !== reducerForm.form.cfgIec61850ActivePowerTotalDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850ActivePowerTotalDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850ActivePowerTotalDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850ActivePowerTotalDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ActivePowerTotalDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ActivePowerTotalDb.value !== reducerForm.form.cfgIec61850ActivePowerTotalDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.total reactive power") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850ReactivePowerTotalDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850ReactivePowerTotalDbRef?.unit ?? "---" }]` }
								name="cfgIec61850ReactivePowerTotalDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850ReactivePowerTotalDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ReactivePowerTotalDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ReactivePowerTotalDbRef.value !== reducerForm.form.cfgIec61850ReactivePowerTotalDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850ReactivePowerTotalDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850ReactivePowerTotalDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850ReactivePowerTotalDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ReactivePowerTotalDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ReactivePowerTotalDb.value !== reducerForm.form.cfgIec61850ReactivePowerTotalDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.total apparent power") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850ApparentPowerTotalDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850ApparentPowerTotalDbRef?.unit ?? "---" }]` }
								name="cfgIec61850ApparentPowerTotalDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850ApparentPowerTotalDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ApparentPowerTotalDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ApparentPowerTotalDbRef.value !== reducerForm.form.cfgIec61850ApparentPowerTotalDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850ApparentPowerTotalDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850ApparentPowerTotalDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850ApparentPowerTotalDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ApparentPowerTotalDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ApparentPowerTotalDb.value !== reducerForm.form.cfgIec61850ApparentPowerTotalDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.active power phase") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850ActivePowerPhaseDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850ActivePowerPhaseDbRef?.unit ?? "---" }]` }
								name="cfgIec61850ActivePowerPhaseDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850ActivePowerPhaseDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ActivePowerPhaseDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ActivePowerPhaseDbRef.value !== reducerForm.form.cfgIec61850ActivePowerPhaseDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850ActivePowerPhaseDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850ActivePowerPhaseDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850ActivePowerPhaseDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ActivePowerPhaseDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ActivePowerPhaseDb.value !== reducerForm.form.cfgIec61850ActivePowerPhaseDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.reactive power phase") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850ReactivePowerPhaseDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850ReactivePowerPhaseDbRef?.unit ?? "---" }]` }
								name="cfgIec61850ReactivePowerPhaseDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850ReactivePowerPhaseDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ReactivePowerPhaseDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ReactivePowerPhaseDbRef.value !== reducerForm.form.cfgIec61850ReactivePowerPhaseDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850ReactivePowerPhaseDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850ReactivePowerPhaseDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850ReactivePowerPhaseDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ReactivePowerPhaseDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ReactivePowerPhaseDb.value !== reducerForm.form.cfgIec61850ReactivePowerPhaseDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.apparent power phase") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850ApparentPowerPhaseDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850ApparentPowerPhaseDbRef?.unit ?? "---" }]` }
								name="cfgIec61850ApparentPowerPhaseDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850ApparentPowerPhaseDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ApparentPowerPhaseDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ApparentPowerPhaseDbRef.value !== reducerForm.form.cfgIec61850ApparentPowerPhaseDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850ApparentPowerPhaseDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850ApparentPowerPhaseDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850ApparentPowerPhaseDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850ApparentPowerPhaseDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850ApparentPowerPhaseDb.value !== reducerForm.form.cfgIec61850ApparentPowerPhaseDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.frequency") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850FrequencyDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850FrequencyDbRef?.unit ?? "---" }]` }
								name="cfgIec61850FrequencyDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850FrequencyDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850FrequencyDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850FrequencyDbRef.value !== reducerForm.form.cfgIec61850FrequencyDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850FrequencyDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850FrequencyDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850FrequencyDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850FrequencyDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850FrequencyDb.value !== reducerForm.form.cfgIec61850FrequencyDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.harmonics U") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850VoltageHarmonicsDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850VoltageHarmonicsDbRef?.unit ?? "---" }]` }
								name="cfgIec61850VoltageHarmonicsDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850VoltageHarmonicsDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850VoltageHarmonicsDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850VoltageHarmonicsDbRef.value !== reducerForm.form.cfgIec61850VoltageHarmonicsDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850VoltageHarmonicsDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850VoltageHarmonicsDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850VoltageHarmonicsDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850VoltageHarmonicsDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850VoltageHarmonicsDb.value !== reducerForm.form.cfgIec61850VoltageHarmonicsDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.harmonics I") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850CurrentHarmonicsDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850CurrentHarmonicsDbRef?.unit ?? "---" }]` }
								name="cfgIec61850CurrentHarmonicsDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850CurrentHarmonicsDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850CurrentHarmonicsDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850CurrentHarmonicsDbRef.value !== reducerForm.form.cfgIec61850CurrentHarmonicsDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850CurrentHarmonicsDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850CurrentHarmonicsDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850CurrentHarmonicsDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850CurrentHarmonicsDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850CurrentHarmonicsDb.value !== reducerForm.form.cfgIec61850CurrentHarmonicsDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.interharmonics U") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850VoltageInterharmonicsDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850VoltageInterharmonicsDbRef?.unit ?? "---" }]` }
								name="cfgIec61850VoltageInterharmonicsDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850VoltageInterharmonicsDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850VoltageInterharmonicsDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850VoltageInterharmonicsDbRef.value !== reducerForm.form.cfgIec61850VoltageInterharmonicsDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850VoltageInterharmonicsDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850VoltageInterharmonicsDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850VoltageInterharmonicsDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850VoltageInterharmonicsDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850VoltageInterharmonicsDb.value !== reducerForm.form.cfgIec61850VoltageInterharmonicsDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.interharmonics I") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850CurrentInterharmonicsDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850CurrentInterharmonicsDbRef?.unit ?? "---" }]` }
								name="cfgIec61850CurrentInterharmonicsDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850CurrentInterharmonicsDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850CurrentInterharmonicsDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850CurrentInterharmonicsDbRef.value !== reducerForm.form.cfgIec61850CurrentInterharmonicsDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850CurrentInterharmonicsDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850CurrentInterharmonicsDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850CurrentInterharmonicsDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850CurrentInterharmonicsDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850CurrentInterharmonicsDb.value !== reducerForm.form.cfgIec61850CurrentInterharmonicsDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							<Trans i18nKey="COMMUNICATIONS.voltage THD" components={ { sub: <sub/> } }/>
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850VoltageThdDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850VoltageThdDbRef?.unit ?? "---" }]` }
								name="cfgIec61850VoltageThdDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850VoltageThdDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850VoltageThdDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850VoltageThdDbRef.value !== reducerForm.form.cfgIec61850VoltageThdDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850VoltageThdDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850VoltageThdDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850VoltageThdDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850VoltageThdDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850VoltageThdDb.value !== reducerForm.form.cfgIec61850VoltageThdDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							<Trans i18nKey="COMMUNICATIONS.current THD" components={ { sub: <sub/> } }/>
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850CurrentThdDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850CurrentThdDbRef?.unit ?? "---" }]` }
								name="cfgIec61850CurrentThdDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850CurrentThdDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850CurrentThdDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850CurrentThdDbRef.value !== reducerForm.form.cfgIec61850CurrentThdDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850CurrentThdDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850CurrentThdDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850CurrentThdDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850CurrentThdDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850CurrentThdDb.value !== reducerForm.form.cfgIec61850CurrentThdDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							<Trans i18nKey="COMMUNICATIONS.voltage TID" components={ { sub: <sub/> } }/>
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850VoltageTidDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850VoltageTidDbRef?.unit ?? "---" }]` }
								name="cfgIec61850VoltageTidDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850VoltageTidDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850VoltageTidDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850VoltageTidDbRef.value !== reducerForm.form.cfgIec61850VoltageTidDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850VoltageTidDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850VoltageTidDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850VoltageTidDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850VoltageTidDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850VoltageTidDb.value !== reducerForm.form.cfgIec61850VoltageTidDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							<Trans i18nKey="COMMUNICATIONS.current TID" components={ { sub: <sub/> } }/>
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850CurrentTidDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850CurrentTidDbRef?.unit ?? "---" }]` }
								name="cfgIec61850CurrentTidDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850CurrentTidDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850CurrentTidDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850CurrentTidDbRef.value !== reducerForm.form.cfgIec61850CurrentTidDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850CurrentTidDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850CurrentTidDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850CurrentTidDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850CurrentTidDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850CurrentTidDb.value !== reducerForm.form.cfgIec61850CurrentTidDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.positive sequence voltage") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef?.unit ?? "---" }]` }
								name="cfgIec61850UnbalanceVoltagePositiveSequenceDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850UnbalanceVoltagePositiveSequenceDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceVoltagePositiveSequenceDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850UnbalanceVoltagePositiveSequenceDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850UnbalanceVoltagePositiveSequenceDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850UnbalanceVoltagePositiveSequenceDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceVoltagePositiveSequenceDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDb.value !== reducerForm.form.cfgIec61850UnbalanceVoltagePositiveSequenceDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.negative sequence voltage") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef?.unit ?? "---" }]` }
								name="cfgIec61850UnbalanceVoltageNegativeSequenceDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850UnbalanceVoltageNegativeSequenceDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceVoltageNegativeSequenceDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850UnbalanceVoltageNegativeSequenceDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850UnbalanceVoltageNegativeSequenceDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850UnbalanceVoltageNegativeSequenceDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceVoltageNegativeSequenceDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDb.value !== reducerForm.form.cfgIec61850UnbalanceVoltageNegativeSequenceDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.zero sequence voltage") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDbRef?.unit ?? "---" }]` }
								name="cfgIec61850UnbalanceVoltageZeroSequenceDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850UnbalanceVoltageZeroSequenceDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceVoltageZeroSequenceDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850UnbalanceVoltageZeroSequenceDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850UnbalanceVoltageZeroSequenceDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850UnbalanceVoltageZeroSequenceDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceVoltageZeroSequenceDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDb.value !== reducerForm.form.cfgIec61850UnbalanceVoltageZeroSequenceDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.positive sequence current") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef?.unit ?? "---" }]` }
								name="cfgIec61850UnbalanceCurrentPositiveSequenceDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850UnbalanceCurrentPositiveSequenceDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceCurrentPositiveSequenceDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850UnbalanceCurrentPositiveSequenceDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850UnbalanceCurrentPositiveSequenceDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850UnbalanceCurrentPositiveSequenceDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceCurrentPositiveSequenceDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDb.value !== reducerForm.form.cfgIec61850UnbalanceCurrentPositiveSequenceDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.negative sequence current") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef?.unit ?? "---" }]` }
								name="cfgIec61850UnbalanceCurrentNegativeSequenceDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850UnbalanceCurrentNegativeSequenceDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceCurrentNegativeSequenceDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850UnbalanceCurrentNegativeSequenceDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850UnbalanceCurrentNegativeSequenceDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850UnbalanceCurrentNegativeSequenceDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceCurrentNegativeSequenceDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDb.value !== reducerForm.form.cfgIec61850UnbalanceCurrentNegativeSequenceDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.zero sequence current") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDbRef?.unit ?? "---" }]` }
								name="cfgIec61850UnbalanceCurrentZeroSequenceDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850UnbalanceCurrentZeroSequenceDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceCurrentZeroSequenceDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.value !== reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850UnbalanceCurrentZeroSequenceDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850UnbalanceCurrentZeroSequenceDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850UnbalanceCurrentZeroSequenceDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceCurrentZeroSequenceDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDb.value !== reducerForm.form.cfgIec61850UnbalanceCurrentZeroSequenceDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.unbalance U") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850UnbalanceVoltageNegativeDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850UnbalanceVoltageNegativeDbRef?.unit ?? "---" }]` }
								name="cfgIec61850UnbalanceVoltageNegativeDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850UnbalanceVoltageNegativeDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceVoltageNegativeDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDbRef.value !== reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850UnbalanceVoltageNegativeDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850UnbalanceVoltageNegativeDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850UnbalanceVoltageNegativeDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceVoltageNegativeDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDb.value !== reducerForm.form.cfgIec61850UnbalanceVoltageNegativeDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.unbalance I") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850UnbalanceCurrentNegativeDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850UnbalanceCurrentNegativeDbRef?.unit ?? "---" }]` }
								name="cfgIec61850UnbalanceCurrentNegativeDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850UnbalanceCurrentNegativeDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceCurrentNegativeDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDbRef.value !== reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850UnbalanceCurrentNegativeDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850UnbalanceCurrentNegativeDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850UnbalanceCurrentNegativeDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850UnbalanceCurrentNegativeDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDb.value !== reducerForm.form.cfgIec61850UnbalanceCurrentNegativeDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.flicker short") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850FlickerPstDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") }` }
								name="cfgIec61850FlickerPstDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850FlickerPstDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850FlickerPstDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850FlickerPstDbRef.value !== reducerForm.form.cfgIec61850FlickerPstDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850FlickerPstDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850FlickerPstDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850FlickerPstDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850FlickerPstDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850FlickerPstDb.value !== reducerForm.form.cfgIec61850FlickerPstDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.flicker long") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850FlickerPltDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") }` }
								name="cfgIec61850FlickerPltDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850FlickerPltDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850FlickerPltDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850FlickerPltDbRef.value !== reducerForm.form.cfgIec61850FlickerPltDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850FlickerPltDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850FlickerPltDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850FlickerPltDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850FlickerPltDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850FlickerPltDb.value !== reducerForm.form.cfgIec61850FlickerPltDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.harmonics in the freq 2-9kHz") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec6185029KhzVoltageDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec6185029KhzVoltageDbRef?.unit ?? "---" }]` }
								name="cfgIec6185029KhzVoltageDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec6185029KhzVoltageDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec6185029KhzVoltageDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec6185029KhzVoltageDbRef.value !== reducerForm.form.cfgIec6185029KhzVoltageDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec6185029KhzVoltageDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec6185029KhzVoltageDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec6185029KhzVoltageDb", e.target.value),
									onBlur: () => handleBlur("cfgIec6185029KhzVoltageDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec6185029KhzVoltageDb.value !== reducerForm.form.cfgIec6185029KhzVoltageDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.maximum RMS volt of all harm in the freq 2-9kHz") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec6185029KhzVoltageMaxDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec6185029KhzVoltageMaxDbRef?.unit ?? "---" }]` }
								name="cfgIec6185029KhzVoltageMaxDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec6185029KhzVoltageMaxDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec6185029KhzVoltageMaxDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec6185029KhzVoltageMaxDbRef.value !== reducerForm.form.cfgIec6185029KhzVoltageMaxDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec6185029KhzVoltageMaxDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec6185029KhzVoltageMaxDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec6185029KhzVoltageMaxDb", e.target.value),
									onBlur: () => handleBlur("cfgIec6185029KhzVoltageMaxDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec6185029KhzVoltageMaxDb.value !== reducerForm.form.cfgIec6185029KhzVoltageMaxDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.harmonics in the frequency 9-150kHz") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec618509150KhzVoltageDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec618509150KhzVoltageDbRef?.unit ?? "---" }]` }
								name="cfgIec618509150KhzVoltageDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec618509150KhzVoltageDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec618509150KhzVoltageDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec618509150KhzVoltageDbRef.value !== reducerForm.form.cfgIec618509150KhzVoltageDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec618509150KhzVoltageDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec618509150KhzVoltageDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec618509150KhzVoltageDb", e.target.value),
									onBlur: () => handleBlur("cfgIec618509150KhzVoltageDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec618509150KhzVoltageDb.value !== reducerForm.form.cfgIec618509150KhzVoltageDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.maximum RMS volt of all harm in the freq 9-150kHz") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec618509150KhzVoltageMaxDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec618509150KhzVoltageMaxDbRef?.unit ?? "---" }]` }
								name="cfgIec618509150KhzVoltageMaxDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec618509150KhzVoltageMaxDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec618509150KhzVoltageMaxDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec618509150KhzVoltageMaxDbRef.value !== reducerForm.form.cfgIec618509150KhzVoltageMaxDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec618509150KhzVoltageMaxDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec618509150KhzVoltageMaxDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec618509150KhzVoltageMaxDb", e.target.value),
									onBlur: () => handleBlur("cfgIec618509150KhzVoltageMaxDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec618509150KhzVoltageMaxDb.value !== reducerForm.form.cfgIec618509150KhzVoltageMaxDb.initialValue }
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<h6
							className={
								classNames(
									"text-md sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white leading-none",
									{ "opacity-50": !form.cfgIec61850Enable.value },
								)
							}
						>
							{ t("COMMUNICATIONS.external battery DC voltage") }
						</h6>
						<div className="flex gap-2">
							<Input
								className="flex-[3_1_0%]"
								formItem={ form.cfgIec61850BatteryVoltageDbRef }
								label={ `${ t("COMMUNICATIONS.dead band ref") } [${ settingsIec.communications?.cfgIec61850BatteryVoltageDbRef?.unit ?? "---" }]` }
								name="cfgIec61850BatteryVoltageDbRef"
								inputProps={ {
									type: "number",
									onChange: (e) => handleChange("cfgIec61850BatteryVoltageDbRef", e.target.value),
									onBlur: () => handleBlur("cfgIec61850BatteryVoltageDbRef"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850BatteryVoltageDbRef.value !== reducerForm.form.cfgIec61850BatteryVoltageDbRef.initialValue }
							/>
							<Input
								className="flex-[2_1_0%]"
								formItem={ form.cfgIec61850BatteryVoltageDb }
								label={ `${ t("COMMUNICATIONS.dead band") } [%]` }
								name="cfgIec61850BatteryVoltageDb"
								inputProps={ {
									type: "number",
									step: 0.01,
									onChange: (e) => handleChange("cfgIec61850BatteryVoltageDb", e.target.value),
									onBlur: () => handleBlur("cfgIec61850BatteryVoltageDb"),
								} }
								hasBeenChanged={ reducerForm.form.cfgIec61850BatteryVoltageDb.value !== reducerForm.form.cfgIec61850BatteryVoltageDb.initialValue }
							/>
						</div>
					</div>

				</div>
				{
					isAdmin &&
                    <div className="flex justify-end items-center gap-2">
						{
							isDhcp ?
								<Tooltip content="DHCP is enabled">
									<Button
										color="primary"
										type="submit"
										disabled
									>
										{ t("UTILS.save") }
									</Button>
								</Tooltip>
								:
								<Button
									color="primary"
									type="submit"
								>
									{ t("UTILS.save") }
								</Button>
						}
                    </div>
				}
			</form>
		</Card>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAdmin: state.user.isAdmin,
	isDownloadingProfile: didLoadingRecordExist(state, { loadableType: LoadableType.DOWNLOAD_IEC_PROFILE }),
});

export default connect(mapStateToProps)(Iec61850Card);
