import { EnumOptionsSetting, EnumSetting, EnumUnitSetting, MaxValue, MinMaxUnitValue, MinMaxValue, Nullable, UnitValue, Value } from "src/app/types/util.types";

export enum SystemType {
	ST_1PH = "SYSTEM_TYPE_1PH",
	ST_2PH = "SYSTEM_TYPE_2PH",
	ST_3PH_4W = "SYSTEM_TYPE_3PH_4W",
	ST_3PH_3W = "SYSTEM_TYPE_3PH_3W",
	ST_3PH_3W_ARON = "SYSTEM_TYPE_3PH_3W_ARON",
	// ST_CALIBRATION = "SYSTEM_TYPE_CALIBRATION"
}

export enum NominalFrequency {
	FREQ_50HZ = "FREQ_50HZ",
	FREQ_60HZ = "FREQ_60HZ",
	FREQ_400HZ = "FREQ_400HZ"
}

export enum WaveformFormat {
	WAVEFORM_FORMAT_8BIT_10K = "WAVEFORM_FORMAT_8BIT_10K",
	WAVEFORM_FORMAT_16BIT_10K = "WAVEFORM_FORMAT_16BIT_10K",
	WAVEFORM_FORMAT_8BIT_20K = "WAVEFORM_FORMAT_8BIT_20K",
	WAVEFORM_FORMAT_16BIT_20K = "WAVEFORM_FORMAT_16BIT_20K",
	WAVEFORM_FORMAT_8BIT_40K = "WAVEFORM_FORMAT_8BIT_40K",
	WAVEFORM_FORMAT_16BIT_40K = "WAVEFORM_FORMAT_16BIT_40K",
	WAVEFORM_FORMAT_8BIT_80K = "WAVEFORM_FORMAT_8BIT_80K",
	WAVEFORM_FORMAT_16BIT_80K = "WAVEFORM_FORMAT_16BIT_80K"
}

export enum OnOff {
	ON = "ON",
	OFF = "OFF"
}

export enum PhaseRotation {
	PHASE_ROTATION_CLOCKWISE = "PHASE_ROTATION_CLOCKWISE",
	PHASE_ROTATION_COUNTER_CLOCKWISE = "PHASE_ROTATION_COUNTER_CLOCKWISE"
}

export enum ModbusBaudrate {
	BAUDRATE_57600 = "BAUDRATE_57600",
	BAUDRATE_115200 = "BAUDRATE_115200",
	BAUDRATE_128000 = "BAUDRATE_128000",
	BAUDRATE_230400 = "BAUDRATE_230400",
	BAUDRATE_256000 = "BAUDRATE_256000",
	BAUDRATE_460800 = "BAUDRATE_460800",
	BAUDRATE_921600 = "BAUDRATE_921600",
}

export enum ModbusParity {
	MODBUS_PARITY_EVEN = "MODBUS_PARITY_EVEN",
	MODBUS_PARITY_ODD = "MODBUS_PARITY_ODD",
	MODBUS_PARITY_NONE = "MODBUS_PARITY_NONE",
}

export enum ModbusEndianness {
	ENDIANNESS_LITTLE_ENDIAN = "ENDIANNESS_LITTLE_ENDIAN",
	ENDIANNESS_BIG_ENDIAN = "ENDIANNESS_BIG_ENDIAN",
}

export enum FtpConnectionType {
	CONN_TYPE_FTP = "CONN_TYPE_FTP",
	CONN_TYPE_SFTP = "CONN_TYPE_SFTP",
}

export enum FtpMode {
	FTP_MODE_ACTIVE = "FTP_MODE_ACTIVE",
	FTP_MODE_PASSIVE = "FTP_MODE_PASSIVE",
}

export enum FtpConnectionStatus {
	TEST_OK = "TEST_OK",
	TEST_FAIL = "TEST_FAIL"
}

export enum TimeSyncMode {
	TIME_SYNC_AUTO = "TIME_SYNC_AUTO",
	TIME_SYNC_RTC = "TIME_SYNC_RTC",
}

export enum MemoryModel {
	MEMORY_MODEL_LINEAR = "MEMORY_MODEL_LINEAR",
	MEMORY_MODEL_CIRCULAR = "MEMORY_MODEL_CIRCULAR",
}

export enum Timezone {
	UTC_MINUS_12 = "UTC−12:00",
	UTC_MINUS_11 = "UTC−11:00",
	UTC_MINUS_10 = "UTC−10:00",
	UTC_MINUS_0930 = "UTC−09:30",
	UTC_MINUS_09 = "UTC−09:00",
	UTC_MINUS_08 = "UTC−08:00",
	UTC_MINUS_07 = "UTC−07:00",
	UTC_MINUS_06 = "UTC−06:00",
	UTC_MINUS_05 = "UTC−05:00",
	UTC_MINUS_04 = "UTC−04:00",
	UTC_MINUS_0330 = "UTC−03:30",
	UTC_MINUS_03 = "UTC−03:00",
	UTC_MINUS_02 = "UTC−02:00",
	UTC_MINUS_01 = "UTC−01:00",
	UTC_00 = "UTC±00:00",
	UTC_01 = "UTC+01:00",
	UTC_02 = "UTC+02:00",
	UTC_03 = "UTC+03:00",
	UTC_0330 = "UTC+03:30",
	UTC_04 = "UTC+04:00",
	UTC_0430 = "UTC+04:30",
	UTC_05 = "UTC+05:00",
	UTC_0530 = "UTC+05:30",
	UTC_0545 = "UTC+05:45",
	UTC_06 = "UTC+06:00",
	UTC_0630 = "UTC+06:30",
	UTC_07 = "UTC+07:00",
	UTC_08 = "UTC+08:00",
	UTC_0845 = "UTC+08:45",
	UTC_09 = "UTC+09:00",
	UTC_0930 = "UTC+09:30",
	UTC_10 = "UTC+10:00",
	UTC_1030 = "UTC+10:30",
	UTC_11 = "UTC+11:00",
	UTC_12 = "UTC+12:00",
	UTC_1245 = "UTC+12:45",
	UTC_13 = "UTC+13:00",
	UTC_14 = "UTC+14:00"
}

export enum AggregationPeriodUser {
	AGGR_NOT_DEFINED = "AGGR_NOT_DEFINED",
	AGGR_HALF_PERIOD = "AGGR_HALF_PERIOD",
	AGGR_PERIOD = "AGGR_PERIOD",
	AGGR_200MS = "AGGR_200MS",
	AGGR_1S = "AGGR_1S",
	AGGR_3S = "AGGR_3S",
	AGGR_5S = "AGGR_5S",
	AGGR_10S = "AGGR_10S",
	AGGR_15S = "AGGR_15S",
	AGGR_30S = "AGGR_30S",
	AGGR_1MIN = "AGGR_1MIN",
	AGGR_3MIN = "AGGR_3MIN",
	AGGR_5MIN = "AGGR_5MIN",
	AGGR_10MIN = "AGGR_10MIN",
	AGGR_15MIN = "AGGR_15MIN",
	AGGR_30MIN = "AGGR_30MIN",
	AGGR_60MIN = "AGGR_60MIN",
	AGGR_120MIN = "AGGR_120MIN",
}

export enum PowerCalculationMethod {
	POWER_METHOD_IEEE1459 = "POWER_METHOD_IEEE1459",
	POWER_METHOD_BUDEANU = "POWER_METHOD_BUDEANU",
}

export enum RecordingOption {
	SET_REC_OPT_AVG = "SET_REC_OPT_AVG", // 1
	SET_REC_OPT_MIN = "SET_REC_OPT_MIN", // 2
	SET_REC_OPT_MAX = "SET_REC_OPT_MAX", // 4
	SET_REC_OPT_INST = "SET_REC_OPT_INST", // 8
	SET_REC_OPT_EVT_TH_RELATIVE = "SET_REC_OPT_EVT_TH_RELATIVE", // 16
	SET_REC_OPT_EVT_LOG_ENABLE = "SET_REC_OPT_EVT_LOG_ENABLE", // 32
	SET_REC_OPT_EVT_WAVEFORM_ENABLE = "SET_REC_OPT_EVT_WAVEFORM_ENABLE", // 64
	SET_REC_OPT_EVT_RMS12_ENABLE = "SET_REC_OPT_EVT_RMS12_ENABLE", // 128
}

export enum MapRelay {
	MAP_RELAY1 = "MAP_RELAY1",
	MAP_RELAY2 = "MAP_RELAY2",
}

export enum StandardType {
	STANDARD_TYPE_EN50160_LV = "STANDARD_TYPE_EN50160_LV",
	STANDARD_TYPE_EN50160_MV = "STANDARD_TYPE_EN50160_MV",
	STANDARD_TYPE_EN50160_HV = "STANDARD_TYPE_EN50160_HV",
}

export enum DigitalInputState {
	DIGITAL_INPUT_STATE_LOW = "DIGITAL_INPUT_STATE_LOW",
	DIGITAL_INPUT_STATE_HIGH = "DIGITAL_INPUT_STATE_HIGH",
}

export enum DigitalOutput {
	DIGITAL_OUT_ACTIVE_LOW = "DIGITAL_OUT_ACTIVE_LOW",
	DIGITAL_OUT_ACTIVE_HIGH = "DIGITAL_OUT_ACTIVE_HIGH"
}

export enum DigitalOutputMode {
	DIGITAL_OUT_MODE_ALARM = "DIGITAL_OUT_MODE_ALARM",
	DIGITAL_OUT_MODE_PULSE = "DIGITAL_OUT_MODE_PULSE",
}

export type SettingsRecording = {
	recording: Nullable<{
		cfgSystemType: Nullable<EnumSetting<SystemType>>
		cfgNominalFrequency: Nullable<EnumUnitSetting<NominalFrequency>>
		cfgNominalVoltage: Nullable<MinMaxUnitValue<number>>
		cfgUnpeChannelEnable: Nullable<EnumSetting<OnOff>>
		cfgNominalCurrentCh1: Nullable<UnitValue<number>>
		cfgNominalCurrentCh2: Nullable<UnitValue<number>>
		cfgNominalCurrentCh3: Nullable<UnitValue<number>>
		cfgNominalCurrentCh4: Nullable<UnitValue<number>>
		cfgNominalCurrentCh5: Nullable<UnitValue<number>>
		cfgVoltageTransducerRatio: Nullable<MinMaxValue<number>>
		cfgCurrentCh1TransducerRatio: Nullable<MinMaxValue<number>>
		cfgCurrentCh2TransducerRatio: Nullable<MinMaxValue<number>>
		cfgCurrentCh3TransducerRatio: Nullable<MinMaxValue<number>>
		cfgCurrentCh4TransducerRatio: Nullable<MinMaxValue<number>>
		cfgCurrentCh5TransducerRatio: Nullable<MinMaxValue<number>>
		cfgCurrentCh4Enable: Nullable<EnumSetting<OnOff>>
		cfgCurrentCh5Enable: Nullable<EnumSetting<OnOff>>
		cfgPhaseRotation: Nullable<EnumSetting<PhaseRotation>>
		cfgEventsHysteresis: Nullable<MinMaxUnitValue<number>>
		cfgWaveformFormat: Nullable<EnumSetting<WaveformFormat>>
		cfgWaveformRecDuration: Nullable<MinMaxUnitValue<number>>
		cfgWaveformPretriggerDuration: Nullable<MinMaxUnitValue<number>>
		cfgRms12RecTime: Nullable<MinMaxUnitValue<number>>
		cfgRms12PretriggerTime: Nullable<MinMaxUnitValue<number>>
		varOscDurationPretriggerOffset: Nullable<UnitValue<number>>
	}>
}

export type SetConfigurationPayload = {
	// General
	cfg_system_type?: {
		value: number
	}
	cfg_nominal_frequency?: {
		value: number
	}
	cfg_nominal_voltage?: {
		value: number
	}
	cfg_unpe_channel_enable?: {
		value: number
	}
	cfg_voltage_transducer_ratio?: {
		value: number
	}
	cfg_current_ch1_transducer_ratio?: {
		value: number
	}
	cfg_current_ch2_transducer_ratio?: {
		value: number
	}
	cfg_current_ch3_transducer_ratio?: {
		value: number
	}
	cfg_current_ch4_transducer_ratio?: {
		value: number
	}
	cfg_current_ch5_transducer_ratio?: {
		value: number
	}
	cfg_phase_rotation?: {
		value: number
	}
	// Events
	cfg_events_hysteresis?: {
		value: number
	}
	cfg_waveform_format?: {
		value: number
	}
	cfg_waveform_rec_duration?: {
		value: number
	}
	cfg_waveform_pretrigger_duration?: {
		value: number
	}
	cfg_rms12_rec_time?: {
		value: number
	}
	cfg_rms12_pretrigger_time?: {
		value: number
	}
	cfg_current_ch4_enable?: {
		value: number
	}
	cfg_current_ch5_enable?: {
		value: number
	}
	// 	Modbus
	cfg_modbus_rtu_enable?: {
		value: number
	}
	cfg_modbus_rtu_slave_address?: {
		value: number
	}
	cfg_modbus_rtu_baudrate?: {
		value: number
	}
	cfg_modbus_rtu_parity?: {
		value: number
	}
	cfg_modbus_rtu_endianness?: {
		value: number
	}
	cfg_modbus_tcp_enable?: {
		value: number
	}
	cfg_modbus_tcp_slave_address?: {
		value: number
	}
	cfg_modbus_tcp_port?: {
		value: number
	}
	cfg_modbus_tcp_endianness?: {
		value: number
	}
	// Memory
	cfg_user_recording_space_quota_in_percent?: {
		value: number
	}
	cfg_std_recording_space_quota_in_percent?: {
		value: number
	}
	cfg_energy_counters_space_quota_in_percent?: {
		value: number
	}
	cfg_user_data_memory_model?: {
		value: number
	}
	cfg_std_data_memory_model?: {
		value: number
	}
	cfg_energy_data_memory_model?: {
		value: number
	}
	// FTP
	cfg_ftp_enable?: {
		value: number
	}
	cfg_ftp_connection_type?: {
		value: number
	}
	cfg_ftp_mode?: {
		value: number
	}
	cfg_ftp_upload_time?: {
		value: number
	}
	cfg_ftp_server_ip?: {
		value: string
	}
	cfg_ftp_server_port?: {
		value: number
	}
	cfg_ftp_user_name?: {
		value: string
	}
	cfg_ftp_password?: {
		value: string
	}
	cfg_ftp_dest_directory?: {
		value: string
	}
	// Time
	rtc_utc_timestamp?: {
		value: number
	}
	cfg_time_sync_mode?: {
		value: number
	}
	cfg_timezone?: {
		value: number
	}
	cfg_daylight_savings_time?: {
		value: number
	}
	cfg_ntp_enable?: {
		value: number
	}
	cfg_ntp_server_1_name?: {
		value: string
	}
	cfg_ntp_server_2_name?: {
		value: string
	}
	cfg_ntp_server_3_name?: {
		value: string
	}
	cfg_ntp_server_4_name?: {
		value: string
	}
	// Measurements User
	cfg_user_data_rec_enable?: {
		value: number
	}
	cfg_aggregation_period_user?: {
		value: number
	}
	cfg_user_waveforms_and_rms12_rec_enable?: {
		value: number
	}
	cfg_phasor_rec_opt?: {
		value: number
	}
	cfg_power_calculation_method?: {
		value: number
	}
	cfg_voltage_rms_rec_opt?: {
		value: number
	}
	cfg_voltage_rms_event_threshold_user_dip?: {
		value: number
	}
	cfg_voltage_rms_event_threshold_user_swell?: {
		value: number
	}
	cfg_voltage_rms_event_threshold_user_interruption?: {
		value: number
	}
	cfg_voltage_rms_event_threshold_max_ch4?: {
		value: number
	}
	cfg_relay_voltage_dip1_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_dip1_map?: {
		value: number
	}
	cfg_relay_voltage_dip2_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_dip2_map?: {
		value: number
	}
	cfg_relay_voltage_dip3_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_dip3_map?: {
		value: number
	}
	cfg_relay_voltage_dip4_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_dip4_map?: {
		value: number
	}
	cfg_relay_voltage_swell1_rec_opt?: {
		value: number
	}
	cfg_relay_volatge_swell1_map?: {
		value: number
	}
	cfg_relay_voltage_swell2_rec_opt?: {
		value: number
	}
	cfg_relay_volatge_swell2_map?: {
		value: number
	}
	cfg_relay_voltage_swell3_rec_opt?: {
		value: number
	}
	cfg_relay_volatge_swell3_map?: {
		value: number
	}
	cfg_relay_voltage_swell4_rec_opt?: {
		value: number
	}
	cfg_relay_volatge_swell4_map?: {
		value: number
	}
	cfg_relay_voltage_interrupt1_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_interrupt1_map?: {
		value: number
	}
	cfg_relay_voltage_interrupt2_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_interrupt2_map?: {
		value: number
	}
	cfg_relay_voltage_interrupt3_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_interrupt3_map?: {
		value: number
	}
	cfg_relay_voltage_interrupt4_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_interrupt4_map?: {
		value: number
	}
	cfg_voltage_dc_rec_opt?: {
		value: number
	}
	cfg_waveshape_variations_rec_opt?: {
		value: number
	}
	cfg_waveshape_variations_deadtime?: {
		value: number
	}
	cfg_waveshape_variations_threshold?: {
		value: number
	}
	cfg_relay_waveshape1_rec_opt?: {
		value: number
	}
	cfg_relay_waveshape1_map?: {
		value: number
	}
	cfg_relay_waveshape2_rec_opt?: {
		value: number
	}
	cfg_relay_waveshape2_map?: {
		value: number
	}
	cfg_relay_waveshape3_rec_opt?: {
		value: number
	}
	cfg_relay_waveshape3_map?: {
		value: number
	}
	cfg_relay_waveshape4_rec_opt?: {
		value: number
	}
	cfg_relay_waveshape4_map?: {
		value: number
	}
	cfg_phase_jumps_rec_opt?: {
		value: number
	}
	cfg_phase_jumps_threshold?: {
		value: number
	}
	cfg_relay_phase_jump1_rec_opt?: {
		value: number
	}
	cfg_relay_phase_jump1_map?: {
		value: number
	}
	cfg_relay_phase_jump2_rec_opt?: {
		value: number
	}
	cfg_relay_phase_jump2_map?: {
		value: number
	}
	cfg_relay_phase_jump3_rec_opt?: {
		value: number
	}
	cfg_relay_phase_jump3_map?: {
		value: number
	}
	cfg_relay_phase_jump4_rec_opt?: {
		value: number
	}
	cfg_relay_phase_jump4_map?: {
		value: number
	}
	cfg_frequency_rec_opt?: {
		value: number
	}
	cfg_frequency_event_threshold_min?: {
		value: number
	}
	cfg_frequency_event_threshold_max?: {
		value: number
	}
	cfg_relay_frequency_rec_opt?: {
		value: number
	}
	cfg_relay_frequency_map?: {
		value: number
	}
	cfg_voltage_crest_factor_rec_opt?: {
		value: number
	}
	cfg_voltage_unbalance_rec_opt?: {
		value: number
	}
	cfg_voltage_unbalance_threshold_max?: {
		value: number
	}
	cfg_flicker_pst_rec_opt?: {
		value: number
	}
	cfg_flicker_pst_threshold_max?: {
		value: number
	}
	cfg_relay_flicker_pst1_rec_opt?: {
		value: number
	}
	cfg_relay_flicker_pst1_map?: {
		value: number
	}
	cfg_relay_flicker_pst2_rec_opt?: {
		value: number
	}
	cfg_relay_flicker_pst2_map?: {
		value: number
	}
	cfg_relay_flicker_pst3_rec_opt?: {
		value: number
	}
	cfg_relay_flicker_pst3_map?: {
		value: number
	}
	cfg_relay_flicker_pst4_rec_opt?: {
		value: number
	}
	cfg_relay_flicker_pst4_map?: {
		value: number
	}
	cfg_flicker_plt_rec_opt?: {
		value: number
	}
	cfg_flicker_plt_threshold_max?: {
		value: number
	}
	cfg_relay_flicker_plt1_rec_opt?: {
		value: number
	}
	cfg_relay_flicker_plt1_map?: {
		value: number
	}
	cfg_relay_flicker_plt2_rec_opt?: {
		value: number
	}
	cfg_relay_flicker_plt2_map?: {
		value: number
	}
	cfg_relay_flicker_plt3_rec_opt?: {
		value: number
	}
	cfg_relay_flicker_plt3_map?: {
		value: number
	}
	cfg_relay_flicker_plt4_rec_opt?: {
		value: number
	}
	cfg_relay_flicker_plt4_map?: {
		value: number
	}
	cfg_current_rms_rec_opt?: {
		value: number
	}
	cfg_current_rms_event_threshold_min?: {
		value: number
	}
	cfg_current_rms_event_threshold_max?: {
		value: number
	}
	cfg_current_rms_event_threshold_min_ch4?: {
		value: number
	}
	cfg_current_rms_event_threshold_max_ch4?: {
		value: number
	}
	cfg_current_rms_event_threshold_min_ch5?: {
		value: number
	}
	cfg_current_rms_event_threshold_max_ch5?: {
		value: number
	}
	cfg_current_rms_event_threshold_min_ns?: {
		value: number
	}
	cfg_current_rms_event_threshold_max_ns?: {
		value: number
	}
	cfg_current_rms_event_threshold_min_dns?: {
		value: number
	}
	cfg_current_rms_event_threshold_max_dns?: {
		value: number
	}
	cfg_relay_current1_rec_opt?: {
		value: number
	}
	cfg_relay_current1_map?: {
		value: number
	}
	cfg_relay_current2_rec_opt?: {
		value: number
	}
	cfg_relay_current2_map?: {
		value: number
	}
	cfg_relay_current3_rec_opt?: {
		value: number
	}
	cfg_relay_current3_map?: {
		value: number
	}
	cfg_relay_current4_rec_opt?: {
		value: number
	}
	cfg_relay_current4_map?: {
		value: number
	}
	cfg_relay_current5_rec_opt?: {
		value: number
	}
	cfg_relay_current5_map?: {
		value: number
	}
	cfg_current_crest_factor_rec_opt?: {
		value: number
	}
	cfg_current_unbalance_rec_opt?: {
		value: number
	}
	cfg_active_power_rec_opt?: {
		value: number
	}
	cfg_active_power_event_threshold_max?: {
		value: number
	}
	cfg_active_power_plus_rec_opt?: {
		value: number
	}
	cfg_active_power_plus_event_threshold_max?: {
		value: number
	}
	cfg_relay_active_power_plus1_rec_opt?: {
		value: number
	}
	cfg_relay_active_power_plus1_map?: {
		value: number
	}
	cfg_relay_active_power_plus2_rec_opt?: {
		value: number
	}
	cfg_relay_active_power_plus2_map?: {
		value: number
	}
	cfg_relay_active_power_plus3_rec_opt?: {
		value: number
	}
	cfg_relay_active_power_plus3_map?: {
		value: number
	}
	cfg_relay_active_power_plus4_rec_opt?: {
		value: number
	}
	cfg_relay_active_power_plus4_map?: {
		value: number
	}
	cfg_active_power_minus_rec_opt?: {
		value: number
	}
	cfg_active_power_minus_event_threshold_max?: {
		value: number
	}
	cfg_relay_active_power_minus1_rec_opt?: {
		value: number
	}
	cfg_relay_active_power_minus1_map?: {
		value: number
	}
	cfg_relay_active_power_minus2_rec_opt?: {
		value: number
	}
	cfg_relay_active_power_minus2_map?: {
		value: number
	}
	cfg_relay_active_power_minus3_rec_opt?: {
		value: number
	}
	cfg_relay_active_power_minus3_map?: {
		value: number
	}
	cfg_relay_active_power_minus4_rec_opt?: {
		value: number
	}
	cfg_relay_active_power_minus4_map?: {
		value: number
	}
	cfg_active_power_fundamental_rec_opt?: {
		value: number
	}
	cfg_apparent_power_rec_opt?: {
		value: number
	}
	cfg_apparent_power_event_threshold_max?: {
		value: number
	}
	cfg_relay_apparent_power1_rec_opt?: {
		value: number
	}
	cfg_relay_apparent_power1_map?: {
		value: number
	}
	cfg_relay_apparent_power2_rec_opt?: {
		value: number
	}
	cfg_relay_apparent_power2_map?: {
		value: number
	}
	cfg_relay_apparent_power3_rec_opt?: {
		value: number
	}
	cfg_relay_apparent_power3_map?: {
		value: number
	}
	cfg_relay_apparent_power4_rec_opt?: {
		value: number
	}
	cfg_relay_apparent_power4_map?: {
		value: number
	}
	cfg_apparent_power_fundamental_rec_opt?: {
		value: number
	}
	cfg_apparent_power_nonfundamental_rec_opt?: {
		value: number
	}
	cfg_distortion_power_budeanu_rec_opt?: {
		value: number
	}
	cfg_reactive_power_rec_opt?: {
		value: number
	}
	cfg_reactive_power_event_threshold_max?: {
		value: number
	}
	cfg_relay_reactive_power1_rec_opt?: {
		value: number
	}
	cfg_relay_reactive_power1_map?: {
		value: number
	}
	cfg_relay_reactive_power2_rec_opt?: {
		value: number
	}
	cfg_relay_reactive_power2_map?: {
		value: number
	}
	cfg_relay_reactive_power3_rec_opt?: {
		value: number
	}
	cfg_relay_reactive_power3_map?: {
		value: number
	}
	cfg_relay_reactive_power4_rec_opt?: {
		value: number
	}
	cfg_relay_reactive_power4_map?: {
		value: number
	}
	cfg_power_factor_rec_opt?: {
		value: number
	}
	cfg_power_factor_event_threshold_max?: {
		value: number
	}
	cfg_relay_power_factor1_rec_opt?: {
		value: number
	}
	cfg_relay_power_factor1_map?: {
		value: number
	}
	cfg_relay_power_factor2_rec_opt?: {
		value: number
	}
	cfg_relay_power_factor2_map?: {
		value: number
	}
	cfg_relay_power_factor3_rec_opt?: {
		value: number
	}
	cfg_relay_power_factor3_map?: {
		value: number
	}
	cfg_relay_power_factor4_rec_opt?: {
		value: number
	}
	cfg_relay_power_factor4_map?: {
		value: number
	}
	cfg_power_factor_fundamental_rec_opt?: {
		value: number
	}
	cfg_displacement_power_factor_rec_opt?: {
		value: number
	}
	cfg_displacement_power_factor_event_threshold_max?: {
		value: number
	}
	cfg_relay_displacement_power_factor1_rec_opt?: {
		value: number
	}
	cfg_relay_displacement_power_factor1_map?: {
		value: number
	}
	cfg_relay_displacement_power_factor2_rec_opt?: {
		value: number
	}
	cfg_relay_displacement_power_factor2_map?: {
		value: number
	}
	cfg_relay_displacement_power_factor3_rec_opt?: {
		value: number
	}
	cfg_relay_displacement_power_factor3_map?: {
		value: number
	}
	cfg_relay_displacement_power_factor4_rec_opt?: {
		value: number
	}
	cfg_relay_displacement_power_factor4_map?: {
		value: number
	}
	cfg_tangent4q_rec_opt?: {
		value: number
	}
	cfg_tangent_quadrant1_event_threshold_min?: {
		value: number
	}
	cfg_tangent_quadrant1_event_threshold_max?: {
		value: number
	}
	cfg_tangent_quadrant2_event_threshold_min?: {
		value: number
	}
	cfg_tangent_quadrant2_event_threshold_max?: {
		value: number
	}
	cfg_tangent_quadrant3_event_threshold_min?: {
		value: number
	}
	cfg_tangent_quadrant3_event_threshold_max?: {
		value: number
	}
	cfg_tangent_quadrant4_event_threshold_min?: {
		value: number
	}
	cfg_tangent_quadrant4_event_threshold_max?: {
		value: number
	}
	cfg_active_energy_rec_opt?: {
		value: number
	}
	cfg_active_energy_plus_event_threshold_max?: {
		value: number
	}
	cfg_active_energy_minus_event_threshold_max?: {
		value: number
	}
	cfg_apparent_energy_rec_opt?: {
		value: number
	}
	cfg_apparent_energy_event_threshold_max?: {
		value: number
	}
	cfg_reactive_energy_4q_rec_opt?: {
		value: number
	}
	cfg_reactive_energy_quadrant1_event_threshold_max?: {
		value: number
	}
	cfg_reactive_energy_quadrant2_event_threshold_max?: {
		value: number
	}
	cfg_reactive_energy_quadrant3_event_threshold_max?: {
		value: number
	}
	cfg_reactive_energy_quadrant4_event_threshold_max?: {
		value: number
	}
	cfg_voltage_thdf_rec_opt?: {
		value: number
	}
	cfg_voltage_thdf_threshold_max?: {
		value: number
	}
	cfg_relay_voltage_thdf1_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_thdf1_map?: {
		value: number
	}
	cfg_relay_voltage_thdf2_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_thdf2_map?: {
		value: number
	}
	cfg_relay_voltage_thdf3_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_thdf3_map?: {
		value: number
	}
	cfg_relay_voltage_thdf4_rec_opt?: {
		value: number
	}
	cfg_relay_voltage_thdf4_map?: {
		value: number
	}
	cfg_voltage_thdr_rec_opt?: {
		value: number
	}
	cfg_voltage_harmonics_rec_opt?: {
		value: number
	}
	cfg_current_thdf_rec_opt?: {
		value: number
	}
	cfg_current_thdf_threshold_max?: {
		value: number
	}
	cfg_relay_current_thdf1_rec_opt?: {
		value: number
	}
	cfg_relay_current_thdf1_map?: {
		value: number
	}
	cfg_relay_current_thdf2_rec_opt?: {
		value: number
	}
	cfg_relay_current_thdf2_map?: {
		value: number
	}
	cfg_relay_current_thdf3_rec_opt?: {
		value: number
	}
	cfg_relay_current_thdf3_map?: {
		value: number
	}
	cfg_relay_current_thdf4_rec_opt?: {
		value: number
	}
	cfg_relay_current_thdf4_map?: {
		value: number
	}
	cfg_current_thdr_rec_opt?: {
		value: number
	}
	cfg_current_harmonics_rec_opt?: {
		value: number
	}
	cfg_active_power_harmonics_rec_opt?: {
		value: number
	}
	cfg_harmonics_angles_ui_rec_opt?: {
		value: number
	}
	cfg_reactive_power_harmonics_rec_opt?: {
		value: number
	}
	cfg_voltage_tidf_rec_opt?: {
		value: number
	}
	cfg_voltage_tidr_rec_opt?: {
		value: number
	}
	cfg_current_tidf_rec_opt?: {
		value: number
	}
	cfg_current_tidr_rec_opt?: {
		value: number
	}
	cfg_voltage_interharmonics_rec_opt?: {
		value: number
	}
	cfg_current_interharmonics_rec_opt?: {
		value: number
	}
	cfg_kfactor_rec_opt?: {
		value: number
	}
	cfg_mains_signalling_ur1_rec_opt?: {
		value: number
	}
	cfg_mains_signalling_ur1_frequency?: {
		value: number
	}
	cfg_mains_signalling_ur2_rec_opt?: {
		value: number
	}
	cfg_mains_signalling_ur2_frequency?: {
		value: number
	}
	cfg_voltage_2_9k_rec_opt?: {
		value: number
	}
	cfg_voltage_8_150k_rec_opt?: {
		value: number
	}

	cfg_std_data_rec_enable?: {
		value: number
	}
	cfg_std_type?: {
		value: number
	}
	cfg_std_waveforms_and_rms12_rec_enable?: {
		value: number
	}

	// Iec
	cfg_iec61850_enable?: {
		value: number
	}
	cfg_iec61850_ip_address?: {
		value: string
	}
	cfg_iec61850_ied_name?: {
		value: string
	}
	cfg_iec61850_phase_voltage_db_ref?: {
		value: number
	}
	cfg_iec61850_phase_voltage_db?: {
		value: number
	}
	cfg_iec61850_phase_to_phase_voltage_db_ref?: {
		value: number
	}
	cfg_iec61850_phase_to_phase_voltage_db?: {
		value: number
	}
	cfg_iec61850_une_voltage_db_ref?: {
		value: number
	}
	cfg_iec61850_une_voltage_db?: {
		value: number
	}
	cfg_iec61850_phase_current_db_ref?: {
		value: number
	}
	cfg_iec61850_phase_current_db?: {
		value: number
	}
	cfg_iec61850_n_current_db_ref?: {
		value: number
	}
	cfg_iec61850_n_current_db?: {
		value: number
	}
	cfg_iec61850_e_current_db_ref?: {
		value: number
	}
	cfg_iec61850_e_current_db?: {
		value: number
	}
	cfg_iec61850_active_power_total_db_ref?: {
		value: number
	}
	cfg_iec61850_active_power_total_db?: {
		value: number
	}
	cfg_iec61850_reactive_power_total_db_ref?: {
		value: number
	}
	cfg_iec61850_reactive_power_total_db?: {
		value: number
	}
	cfg_iec61850_apparent_power_total_db_ref?: {
		value: number
	}
	cfg_iec61850_apparent_power_total_db?: {
		value: number
	}
	cfg_iec61850_active_power_phase_db_ref?: {
		value: number
	}
	cfg_iec61850_active_power_phase_db?: {
		value: number
	}
	cfg_iec61850_reactive_power_phase_db_ref?: {
		value: number
	}
	cfg_iec61850_reactive_power_phase_db?: {
		value: number
	}
	cfg_iec61850_apparent_power_phase_db_ref?: {
		value: number
	}
	cfg_iec61850_apparent_power_phase_db?: {
		value: number
	}
	cfg_iec61850_frequency_db_ref?: {
		value: number
	}
	cfg_iec61850_frequency_db?: {
		value: number
	}
	cfg_iec61850_voltage_harmonics_db_ref?: {
		value: number
	}
	cfg_iec61850_voltage_harmonics_db?: {
		value: number
	}
	cfg_iec61850_current_harmonics_db_ref?: {
		value: number
	}
	cfg_iec61850_current_harmonics_db?: {
		value: number
	}
	cfg_iec61850_voltage_interharmonics_db_ref?: {
		value: number
	}
	cfg_iec61850_voltage_interharmonics_db?: {
		value: number
	}
	cfg_iec61850_current_interharmonics_db_ref?: {
		value: number
	}
	cfg_iec61850_current_interharmonics_db?: {
		value: number
	}
	cfg_iec61850_voltage_thd_db_ref?: {
		value: number
	}
	cfg_iec61850_voltage_thd_db?: {
		value: number
	}
	cfg_iec61850_current_thd_db_ref?: {
		value: number
	}
	cfg_iec61850_current_thd_db?: {
		value: number
	}
	cfg_iec61850_voltage_tid_db_ref?: {
		value: number
	}
	cfg_iec61850_voltage_tid_db?: {
		value: number
	}
	cfg_iec61850_current_tid_db_ref?: {
		value: number
	}
	cfg_iec61850_current_tid_db?: {
		value: number
	}
	cfg_iec61850_unbalance_voltage_positive_sequence_db_ref?: {
		value: number
	}
	cfg_iec61850_unbalance_voltage_positive_sequence_db?: {
		value: number
	}
	cfg_iec61850_unbalance_voltage_negative_sequence_db_ref?: {
		value: number
	}
	cfg_iec61850_unbalance_voltage_negative_sequence_db?: {
		value: number
	}
	cfg_iec61850_unbalance_voltage_zero_sequence_db_ref?: {
		value: number
	}
	cfg_iec61850_unbalance_voltage_zero_sequence_db?: {
		value: number
	}
	cfg_iec61850_unbalance_voltage_negative_db_ref?: {
		value: number
	}
	cfg_iec61850_unbalance_voltage_negative_db?: {
		value: number
	}
	cfg_iec61850_unbalance_current_positive_sequence_db_ref?: {
		value: number
	}
	cfg_iec61850_unbalance_current_positive_sequence_db?: {
		value: number
	}
	cfg_iec61850_unbalance_current_negative_sequence_db_ref?: {
		value: number
	}
	cfg_iec61850_unbalance_current_negative_sequence_db?: {
		value: number
	}
	cfg_iec61850_unbalance_current_zero_sequence_db_ref?: {
		value: number
	}
	cfg_iec61850_unbalance_current_zero_sequence_db?: {
		value: number
	}
	cfg_iec61850_unbalance_current_negative_db_ref?: {
		value: number
	}
	cfg_iec61850_unbalance_current_negative_db?: {
		value: number
	}
	cfg_iec61850_flicker_pst_db_ref?: {
		value: number
	}
	cfg_iec61850_flicker_pst_db?: {
		value: number
	}
	cfg_iec61850_flicker_plt_db_ref?: {
		value: number
	}
	cfg_iec61850_flicker_plt_db?: {
		value: number
	}
	cfg_iec61850_2_9_khz_voltage_db_ref?: {
		value: number
	}
	cfg_iec61850_2_9_khz_voltage_db?: {
		value: number
	}
	cfg_iec61850_2_9_khz_voltage_max_db_ref?: {
		value: number
	}
	cfg_iec61850_2_9_khz_voltage_max_db?: {
		value: number
	}
	cfg_iec61850_9_150_khz_voltage_db_ref?: {
		value: number
	}
	cfg_iec61850_9_150_khz_voltage_db?: {
		value: number
	}
	cfg_iec61850_9_150_khz_voltage_max_db_ref?: {
		value: number
	}
	cfg_iec61850_9_150_khz_voltage_max_db?: {
		value: number
	}
	cfg_iec61850_battery_voltage_db_ref?: {
		value: number
	}
	cfg_iec61850_battery_voltage_db?: {
		value: number
	}

	cfg_digital_input_internal1_active_state?: {
		value: number
	}
	cfg_digital_input_internal1_rec_opt?: {
		value: number
	}
	cfg_digital_input_internal2_active_state?: {
		value: number
	}
	cfg_digital_input_internal2_rec_opt?: {
		value: number
	}
	cfg_relay1_active_state?: {
		value: number
	}
	cfg_relay1_mode?: {
		value: number
	}
	cfg_relay1_active_time?: {
		value: number
	}
	cfg_relay1_dead_time?: {
		value: number
	}
	cfg_relay2_active_state?: {
		value: number
	}
	cfg_relay2_mode?: {
		value: number
	}
	cfg_relay2_active_time?: {
		value: number
	}
	cfg_relay2_dead_time?: {
		value: number
	}
}

export type TestFtpConnectionRequest = {
	cfg_ftp_connection_type: number
	cfg_ftp_mode: number
	cfg_ftp_server_ip: string
	cfg_ftp_server_port: number
	cfg_ftp_user_name: string
	cfg_ftp_password: string
	cfg_ftp_dest_directory: string
}

export type TestFtpConnectionResponse = {
	ftpTest: {
		status: number
	}
	status: {
		value: number
		text: FtpConnectionStatus
	}
}

export type SettingsModbus = {
	communications: Nullable<{
		cfgModbusRtuEnable: Nullable<EnumSetting<OnOff>>
		cfgModbusRtuSlaveAddress: Nullable<MinMaxValue<number>>
		cfgModbusRtuBaudrate: Nullable<EnumSetting<ModbusBaudrate>>
		cfgModbusRtuParity: Nullable<EnumSetting<ModbusParity>>
		cfgModbusRtuEndianness: Nullable<EnumSetting<ModbusEndianness>>
		cfgModbusTcpEnable: Nullable<EnumSetting<OnOff>>
		cfgModbusTcpSlaveAddress: Nullable<MinMaxValue<number>>
		cfgModbusTcpPort: Nullable<MinMaxValue<number>>
		cfgModbusTcpEndianness: Nullable<EnumSetting<ModbusEndianness>>
	}>
}

export type SettingsFtp = {
	communications: Nullable<{
		cfgFtpEnable: Nullable<EnumSetting<OnOff>>
		cfgFtpConnectionType: Nullable<EnumSetting<FtpConnectionType>>
		cfgFtpMode: Nullable<EnumSetting<FtpMode>>
		cfgFtpUploadTime: Nullable<MinMaxUnitValue<number>>
		cfgFtpServerIp: Nullable<Value<string>>
		cfgFtpServerPort: Nullable<MinMaxValue<number>>
		cfgFtpUserName: Nullable<MaxValue<string>>
		cfgFtpPassword: Nullable<MaxValue<string>>
		cfgFtpDestDirectory: Nullable<MaxValue<string>>
	}>
}

export type SettingsTime = {
	time: Nullable<{
		rtcUtcTimestamp: Nullable<number>
		cfgTimeSyncMode: Nullable<EnumSetting<TimeSyncMode>>
		cfgTimezone: Nullable<MinMaxUnitValue<number>>
		cfgDaylightSavingsTime: Nullable<EnumSetting<OnOff>>
		cfgNtpEnable: Nullable<EnumSetting<OnOff>>
		cfgNtpServer1Name: Nullable<MaxValue<string>>
		cfgNtpServer2Name: Nullable<MaxValue<string>>
		cfgNtpServer3Name: Nullable<MaxValue<string>>
		cfgNtpServer4Name: Nullable<MaxValue<string>>
	}>
}

export type SettingsMemory = {
	memory: Nullable<{
		infoSpaceAvailable: Nullable<UnitValue<number>>
		infoUserRecordingMinSpace: Nullable<UnitValue<number>>
		infoStdRecordingMinSpace: Nullable<UnitValue<number>>
		infoEnergyCountersMinSpace: Nullable<UnitValue<number>>
		cfgUserRecordingSpaceQuotaInPercent: Nullable<UnitValue<number>>
		cfgStdRecordingSpaceQuotaInPercent: Nullable<UnitValue<number>>
		cfgEnergyCountersSpaceQuotaInPercent: Nullable<UnitValue<number>>
		cfgUserDataMemoryModel: Nullable<EnumSetting<MemoryModel>>
		cfgStdDataMemoryModel: Nullable<EnumSetting<MemoryModel>>
		cfgEnergyDataMemoryModel: Nullable<EnumSetting<MemoryModel>>
	}>
}

export type SettingsMeasurementsUser = {
	measurementUser: Nullable<{
		cfgUserDataRecEnable: Nullable<EnumSetting<OnOff>>
		cfgAggregationPeriodUser: Nullable<EnumSetting<AggregationPeriodUser>>
		cfgPowerCalculationMethod: Nullable<EnumSetting<PowerCalculationMethod>>
		cfgUserWaveformsAndRms12RecEnable: Nullable<EnumSetting<OnOff>>
		cfgPhasorRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgPhaseVoltageRms: Nullable<PhaseVoltageConfiguration> // Phase voltage
	cfgPhaseToPhaseVoltageRms: Nullable<PhaseVoltageConfiguration> // Phase-to-phase voltage
	//
	cfgCurrentRms: Nullable<{
		cfgCurrentRmsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgCurrentRmsEventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgCurrentRmsEventThresholdMaxCh4: Nullable<MinMaxUnitValue<number>>
		cfgCurrentRmsEventThresholdMaxCh5: Nullable<MinMaxUnitValue<number>>
		cfgRelayCurrent1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayCurrent1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayCurrent2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayCurrent2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayCurrent3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayCurrent3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayCurrent4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayCurrent4Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayCurrent5RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayCurrent5Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgFrequency: Nullable<{ // Frequency
		cfgFrequencyRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgFrequencyEventThresholdMin: Nullable<MinMaxUnitValue<number>>
		cfgFrequencyEventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgRelayFrequencyRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayFrequencyMap: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgVoltageCrestFactor: Nullable<{ // Crest factor
		cfgVoltageCrestFactorRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgCurrentCrestFactor: Nullable<{
		cfgCurrentCrestFactorRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgVoltageDc: Nullable<{ // UDC Component
		cfgVoltageDcRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgVoltageUnbalance: Nullable<{ // Unbalance and symmetrical components
		cfgVoltageUnbalanceRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgVoltageUnbalanceThresholdMax: Nullable<MinMaxUnitValue<number>>
	}>
	//
	cfgCurrentUnbalance: Nullable<{
		cfgCurrentUnbalanceRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgFlickerPst: Nullable<{ // Pst
		cfgFlickerPstRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgFlickerPstThresholdMax: Nullable<MinMaxValue<number>>
		cfgRelayFlickerPst1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayFlickerPst1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayFlickerPst2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayFlickerPst2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayFlickerPst3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayFlickerPst3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayFlickerPst4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayFlickerPst4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgFlickerPlt: Nullable<{ // Plt
		cfgFlickerPltRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgFlickerPltThresholdMax: Nullable<MinMaxValue<number>>
		cfgRelayFlickerPlt1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayFlickerPlt1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayFlickerPlt2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayFlickerPlt2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayFlickerPlt3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayFlickerPlt3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayFlickerPlt4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayFlickerPlt4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgWaveshapeVariations: Nullable<{
		cfgWaveshapeVariationsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgWaveshapeVariationsDeadtime: Nullable<MinMaxUnitValue<number>>
		cfgWaveshapeVariationsThreshold: Nullable<MinMaxUnitValue<number>>
		cfgRelayWaveshape1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayWaveshape1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayWaveshape2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayWaveshape2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayWaveshape3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayWaveshape3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayWaveshape4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayWaveshape4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgPhaseJumps: Nullable<{
		cfgPhaseJumpsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgPhaseJumpsThreshold: Nullable<MinMaxUnitValue<number>>
		cfgRelayPhaseJump1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayPhaseJump1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayPhaseJump2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayPhaseJump2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayPhaseJump3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayPhaseJump3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayPhaseJump4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayPhaseJump4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgActivePower: Nullable<{
		cfgActivePowerRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgActivePowerEventThresholdMax: Nullable<MinMaxUnitValue<number>>
	}>
	//
	cfgActivePowerPlus: Nullable<{
		cfgActivePowerPlusRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgActivePowerPlusEventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgRelayActivePowerPlus1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayActivePowerPlus1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayActivePowerPlus2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayActivePowerPlus2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayActivePowerPlus3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayActivePowerPlus3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayActivePowerPlus4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayActivePowerPlus4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgActivePowerMinus: Nullable<{
		cfgActivePowerMinusRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgActivePowerMinusEventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgRelayActivePowerMinus1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayActivePowerMinus1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayActivePowerMinus2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayActivePowerMinus2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayActivePowerMinus3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayActivePowerMinus3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayActivePowerMinus4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayActivePowerMinus4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgActivePowerFundamental: Nullable<{
		cfgActivePowerFundamentalRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgReactivePower: Nullable<{
		cfgReactivePowerRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgReactivePowerEventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgRelayReactivePower1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayReactivePower1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayReactivePower2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayReactivePower2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayReactivePower3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayReactivePower3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayReactivePower4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayReactivePower4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgApparentPower: Nullable<{
		cfgApparentPowerRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgApparentPowerEventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgRelayApparentPower1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayApparentPower1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayApparentPower2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayApparentPower2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayApparentPower3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayApparentPower3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayApparentPower4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayApparentPower4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgApparentPowerFundamental: Nullable<{
		cfgApparentPowerFundamentalRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgApparentPowerNonfundamental: Nullable<{
		cfgApparentPowerNonfundamentalRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgDistortionPowerBudeanu: Nullable<{
		cfgDistortionPowerBudeanuRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgDisplacementPowerFactor: Nullable<{
		cfgDisplacementPowerFactorRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgDisplacementPowerFactorEventThresholdMax: Nullable<MinMaxValue<number>>
		cfgRelayDisplacementPowerFactor1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayDisplacementPowerFactor1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayDisplacementPowerFactor2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayDisplacementPowerFactor2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayDisplacementPowerFactor3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayDisplacementPowerFactor3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayDisplacementPowerFactor4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayDisplacementPowerFactor4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgPowerFactor: Nullable<{
		cfgPowerFactorRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgPowerFactorEventThresholdMax: Nullable<MinMaxValue<number>>
		cfgRelayPowerFactor1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayPowerFactor1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayPowerFactor2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayPowerFactor2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayPowerFactor3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayPowerFactor3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayPowerFactor4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayPowerFactor4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgPowerFactorFundamental: Nullable<{
		cfgPowerFactorFundamentalRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgTangent4Q: Nullable<{
		cfgTangent4QRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgTangentQuadrant1EventThresholdMin: Nullable<MinMaxValue<number>>
		cfgTangentQuadrant1EventThresholdMax: Nullable<MinMaxValue<number>>
		cfgTangentQuadrant2EventThresholdMin: Nullable<MinMaxValue<number>>
		cfgTangentQuadrant2EventThresholdMax: Nullable<MinMaxValue<number>>
		cfgTangentQuadrant3EventThresholdMin: Nullable<MinMaxValue<number>>
		cfgTangentQuadrant3EventThresholdMax: Nullable<MinMaxValue<number>>
		cfgTangentQuadrant4EventThresholdMin: Nullable<MinMaxValue<number>>
		cfgTangentQuadrant4EventThresholdMax: Nullable<MinMaxValue<number>>
	}>
	//
	cfgActiveEnergy: Nullable<{
		cfgActiveEnergyRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgActiveEnergyPlusEventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgActiveEnergyMinusEventThresholdMax: Nullable<MinMaxUnitValue<number>>
	}>
	//
	cfgReactiveEnergy4Q: Nullable<{
		cfgReactiveEnergy4QRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgReactiveEnergyQuadrant1EventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgReactiveEnergyQuadrant2EventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgReactiveEnergyQuadrant3EventThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgReactiveEnergyQuadrant4EventThresholdMax: Nullable<MinMaxUnitValue<number>>
	}>
	//
	cfgApparentEnergy: Nullable<{
		cfgApparentEnergyRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgApparentEnergyEventThresholdMax: Nullable<MinMaxUnitValue<number>>
	}>
	//
	cfgVoltageThdf: Nullable<{
		cfgVoltageThdfRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgVoltageThdfThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgRelayVoltageThdf1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayVoltageThdf1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayVoltageThdf2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayVoltageThdf2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayVoltageThdf3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayVoltageThdf3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayVoltageThdf4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayVoltageThdf4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgVoltageThdr: Nullable<{
		cfgVoltageThdrRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgCurrentThdf: Nullable<{
		cfgCurrentThdfRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgCurrentThdfThresholdMax: Nullable<MinMaxUnitValue<number>>
		cfgRelayCurrentThdf1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayCurrentThdf1Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayCurrentThdf2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayCurrentThdf2Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayCurrentThdf3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayCurrentThdf3Map: Nullable<EnumOptionsSetting<MapRelay>>
		cfgRelayCurrentThdf4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelayCurrentThdf4Map: Nullable<EnumOptionsSetting<MapRelay>>
		relayChannelLabel: Nullable<RelayChannelLabel>
	}>
	//
	cfgCurrentThdr: Nullable<{
		cfgCurrentThdrRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgVoltageTidf: Nullable<{
		cfgVoltageTidfRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgVoltageTidr: Nullable<{
		cfgVoltageTidrRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgCurrentTidf: Nullable<{
		cfgCurrentTidfRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgCurrentTidr: Nullable<{
		cfgCurrentTidrRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgVoltageHarmonics: Nullable<{
		cfgVoltageHarmonicsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgCurrentHarmonics: Nullable<{
		cfgCurrentHarmonicsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgVoltageInterharmonics: Nullable<{
		cfgVoltageInterharmonicsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	cfgCurrentInterharmonics: Nullable<{
		cfgCurrentInterharmonicsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgActivePowerHarmonics: Nullable<{
		cfgActivePowerHarmonicsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgReactivePowerHarmonics: Nullable<{
		cfgReactivePowerHarmonicsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgHarmonicsAnglesUi: Nullable<{
		cfgHarmonicsAnglesUiRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	//
	cfgKfactor: Nullable<{
		cfgKfactorRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	cfgMainsSignallingUr1: Nullable<{
		cfgMainsSignallingUr1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgMainsSignallingUr1Frequency: Nullable<MinMaxUnitValue<number>>
	}>
	cfgMainsSignallingUr2: Nullable<{
		cfgMainsSignallingUr2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgMainsSignallingUr2Frequency: Nullable<MinMaxUnitValue<number>>
	}>
	cfgVoltage29K: Nullable<{
		cfgVoltage29KRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
	cfgVoltage8150K: Nullable<{
		cfgVoltage8150KRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	}>
}

export type PhaseVoltageConfiguration = {
	cfgVoltageRmsRecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgVoltageRmsEventThresholdUserDip: Nullable<MinMaxUnitValue<number>>
	cfgVoltageRmsEventThresholdUserSwell: Nullable<MinMaxUnitValue<number>>
	cfgVoltageRmsEventThresholdUserInterruption: Nullable<MinMaxUnitValue<number>>
	cfgVoltageRmsEventThresholdMaxCh4: Nullable<MinMaxUnitValue<number>>
	cfgRelayVoltageDip1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVoltageDip1Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageDip2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVoltageDip2Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageDip3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVoltageDip3Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageDip4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVoltageDip4Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageSwell1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVolatgeSwell1Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageSwell2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVolatgeSwell2Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageSwell3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVolatgeSwell3Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageSwell4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVolatgeSwell4Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageInterrupt1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVoltageInterrupt1Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageInterrupt2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVoltageInterrupt2Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageInterrupt3RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVoltageInterrupt3Map: Nullable<EnumOptionsSetting<MapRelay>>
	cfgRelayVoltageInterrupt4RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
	cfgRelayVoltageInterrupt4Map: Nullable<EnumOptionsSetting<MapRelay>>
	relayChannelLabel: Nullable<RelayChannelLabel>
}

export type RelayChannelLabel = {
	ch1: Nullable<string>
	ch2: Nullable<string>
	ch3: Nullable<string>
	ch4: Nullable<string>
	ch5: Nullable<string>
}

export type SettingsMeasurementsStandard = {
	measurementStandard: Nullable<{
		cfgStdDataRecEnable: Nullable<EnumSetting<OnOff>>
		cfgStdType: Nullable<EnumSetting<StandardType>>
		cfgStdWaveformsAndRms12RecEnable: Nullable<EnumSetting<OnOff>>
	}>
}

export type SettingsIo = {
	io: Nullable<{
		cfgDigitalInputInternal1ActiveState: Nullable<EnumSetting<DigitalInputState>>
		cfgDigitalInputInternal1RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgDigitalInputInternal2ActiveState: Nullable<EnumSetting<DigitalInputState>>
		cfgDigitalInputInternal2RecOpt: Nullable<EnumOptionsSetting<RecordingOption>>
		cfgRelay1ActiveState: Nullable<EnumSetting<DigitalOutput>>
		cfgRelay1Mode: Nullable<EnumSetting<DigitalOutputMode>>
		cfgRelay1ActiveTime: Nullable<MinMaxUnitValue<number>>
		cfgRelay1DeadTime: Nullable<MinMaxUnitValue<number>>
		cfgRelay2ActiveState: Nullable<EnumSetting<DigitalOutput>>
		cfgRelay2Mode: Nullable<EnumSetting<DigitalOutputMode>>
		cfgRelay2ActiveTime: Nullable<MinMaxUnitValue<number>>
		cfgRelay2DeadTime: Nullable<MinMaxUnitValue<number>>
	}>
}

export type SettingsIec = {
	communications: Nullable<{
		cfgEthernetDhcpEnable: Nullable<EnumSetting<OnOff>>
		cfgEthernetIpAddress: Nullable<Value<string>>
		cfgIec61850Enable: Nullable<EnumSetting<OnOff>>
		cfgIec61850IedName: Nullable<MaxValue<string>>
		cfgIec61850IpAddress: Nullable<Value<string>>
		cfgIec61850IpPort: Nullable<MinMaxValue<number>>
		// cfgIec61850NetworkMask: Value<string>
		// cfgIec61850GatewayAddress: Value<string>
		cfgIec61850PhaseVoltageDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850PhaseVoltageDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850PhaseToPhaseVoltageDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850PhaseToPhaseVoltageDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UneVoltageDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UneVoltageDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850PhaseCurrentDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850PhaseCurrentDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850NCurrentDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850NCurrentDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ECurrentDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ECurrentDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ActivePowerTotalDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ActivePowerTotalDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ReactivePowerTotalDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ReactivePowerTotalDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ApparentPowerTotalDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ApparentPowerTotalDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ActivePowerPhaseDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ActivePowerPhaseDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ReactivePowerPhaseDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ReactivePowerPhaseDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ApparentPowerPhaseDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850ApparentPowerPhaseDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850FrequencyDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850FrequencyDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850VoltageHarmonicsDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850VoltageHarmonicsDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850CurrentHarmonicsDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850CurrentHarmonicsDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850VoltageInterharmonicsDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850VoltageInterharmonicsDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850CurrentInterharmonicsDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850CurrentInterharmonicsDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850VoltageThdDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850VoltageThdDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850CurrentThdDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850CurrentThdDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850VoltageTidDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850VoltageTidDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850CurrentTidDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850CurrentTidDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceVoltagePositiveSequenceDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceVoltageNegativeSequenceDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceVoltageZeroSequenceDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceVoltageZeroSequenceDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceVoltageNegativeDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceVoltageNegativeDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceCurrentPositiveSequenceDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceCurrentNegativeSequenceDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceCurrentZeroSequenceDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceCurrentZeroSequenceDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceCurrentNegativeDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850UnbalanceCurrentNegativeDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850FlickerPstDbRef: Nullable<MinMaxValue<number>>
		cfgIec61850FlickerPstDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850FlickerPltDbRef: Nullable<MinMaxValue<number>>
		cfgIec61850FlickerPltDb: Nullable<MinMaxUnitValue<number>>
		cfgIec6185029KhzVoltageDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec6185029KhzVoltageDb: Nullable<MinMaxUnitValue<number>>
		cfgIec6185029KhzVoltageMaxDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec6185029KhzVoltageMaxDb: Nullable<MinMaxUnitValue<number>>
		cfgIec618509150KhzVoltageDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec618509150KhzVoltageDb: Nullable<MinMaxUnitValue<number>>
		cfgIec618509150KhzVoltageMaxDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec618509150KhzVoltageMaxDb: Nullable<MinMaxUnitValue<number>>
		cfgIec61850BatteryVoltageDbRef: Nullable<MinMaxUnitValue<number>>
		cfgIec61850BatteryVoltageDb: Nullable<MinMaxUnitValue<number>>
	}>
}
