import { Nullable } from "src/app/types/util.types";
import moment from "moment";
import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
// Breadcrumbs selector import plop - don't remove

export const isAppLoading = createSelector(
	[
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.AUTH_ME }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SETTINGS_RECORDING }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SETTINGS_MODBUS }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SETTINGS_TIME }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SETTINGS_MEASUREMENTS_USER }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SETTINGS_MEASUREMENTS_STANDARD }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SETTINGS_MEMORY }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SETTINGS_FTP }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SETTINGS_IEC }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_SETTINGS_IO }),
	],
	(
		isAuthMeLoading,
		isFetchingRecording,
		isFetchingModbus,
		isFetchingTime,
		isFetchingMeasurementsUser,
		isFetchingMemory,
		isFetchingFtp,
	) =>
		isAuthMeLoading ||
		isFetchingRecording ||
		isFetchingModbus ||
		isFetchingTime ||
		isFetchingMeasurementsUser ||
		isFetchingMemory ||
		isFetchingFtp,
);

export const isDashboardNoSidebarLoading = createSelector(
	[
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.AUTH_ME }),
	],
	(isAuthMeLoading) => isAuthMeLoading,
);

// Breadcrumbs selector plop - don't remove

type ModelInfo = {
	name: string
	fetchedAt: moment.Moment
}

const getFresherModel = (models: ModelInfo[], deputy: Nullable<string | number>): string => {
	const sortedModels = models.sort((a, b) => b.fetchedAt.diff(a.fetchedAt));

	const [ firstModel ] = sortedModels;
	return firstModel?.name ?? (deputy ?? "").toString();
};
