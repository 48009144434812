import { RootEpic } from "src/app/store/root.epic";
import { delay, filter, map, mergeMap, switchMap, take, withLatestFrom } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { uiFetchSettings, uiFetchSettingsIecProfile, uiSetBufforConfiguration, uiTestFtpConnection } from "src/app/store/features/ui/settings/ui.settings.actions";
import { concat, merge, of } from "rxjs";
import { fetchSettingsFtpAsync, fetchSettingsIecAsync, fetchSettingsIecProfileAsync, fetchSettingsIoAsync, fetchSettingsMeasurementsStandardAsync, fetchSettingsMeasurementsUserAsync, fetchSettingsMemoryAsync, fetchSettingsModbusAsync, fetchSettingsRecordingAsync, fetchSettingsTimeAsync, setConfigurationAsync, testFtpConnectionAsync } from "src/app/store/features/settings/settings.actions";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { settingsLoadingRecords } from "src/app/store/features/ui/loading/ui.loading.reducer";
import { forgetSession } from "src/app/store/features/user/user.actions";
import { LoadableType } from "src/app/types/ui/loading.types";
import { bufforFormActions } from "src/app/store/features/form/form.actions";
import { createFormField } from "src/app/utils/forms";
import { OnOff } from "src/app/types/api/settings.types";
import { getTimezone, translateValueToPercent } from "src/app/utils/helpers";
import moment from "moment/moment";
import { browserTimeZoneOffsetMinutes } from "src/app/containers/Settings/Time.container";
import { mapActivePowerMinusConfig, mapActivePowerPlusConfig, mapApparentPowerConfig, mapCurrentConfig, mapCurrentThdfConfig, mapDipsConfig, mapDisplacementPowerFactorConfig, mapFlickerPltConfig, mapFlickerPstConfig, mapFrequencyConfig, mapInterruptConfig, mapPhaseJumpsConfig, mapPowerFactorConfig, mapReactivePowerConfig, mapSwellConfig, mapVoltageThdfConfig, mapWaveshapeVariationsConfig } from "src/app/utils/configuration";
import { addMessage, displayToast, removeMessagesFromView } from "src/app/store/features/message/message.actions";
import { AddMessageContent, SnackbarMessageType, ToastType, View } from "src/app/types/ui/message.types";
import i18n from "src/app/translations/i18n";
import { DataState } from "src/app/types/redux.types";

export const uiFetchSettingsEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(action => isActionOf(uiFetchSettings, action) && state$.value.user.loggedUser.dataState === DataState.PRESENT),
		mergeMap(() =>
			concat(
				of(fetchSettingsRecordingAsync.request()),
				of(fetchSettingsMemoryAsync.request()),
				of(fetchSettingsModbusAsync.request()),
				of(fetchSettingsTimeAsync.request()),
				of(fetchSettingsFtpAsync.request()),
				of(fetchSettingsMeasurementsUserAsync.request()),
				of(fetchSettingsMeasurementsStandardAsync.request()),
				of(fetchSettingsIecAsync.request()),
				of(fetchSettingsIoAsync.request()),
				action$.pipe(
					filter(action =>
						isActionOf(fetchSettingsRecordingAsync.success, action) ||
						isActionOf(fetchSettingsRecordingAsync.failure, action) ||

						isActionOf(fetchSettingsMemoryAsync.success, action) ||
						isActionOf(fetchSettingsMemoryAsync.failure, action) ||

						isActionOf(fetchSettingsModbusAsync.success, action) ||
						isActionOf(fetchSettingsModbusAsync.failure, action) ||

						isActionOf(fetchSettingsTimeAsync.success, action) ||
						isActionOf(fetchSettingsTimeAsync.failure, action) ||

						isActionOf(fetchSettingsFtpAsync.success, action) ||
						isActionOf(fetchSettingsFtpAsync.failure, action) ||

						isActionOf(fetchSettingsMeasurementsUserAsync.success, action) ||
						isActionOf(fetchSettingsMeasurementsUserAsync.failure, action) ||

						isActionOf(fetchSettingsMeasurementsStandardAsync.success, action) ||
						isActionOf(fetchSettingsMeasurementsStandardAsync.failure, action) ||

						isActionOf(fetchSettingsIecAsync.success, action) ||
						isActionOf(fetchSettingsIecAsync.failure, action) ||

						isActionOf(fetchSettingsIoAsync.success, action) ||
						isActionOf(fetchSettingsIoAsync.failure, action),
					),
					take(9),
					withLatestFrom(state$),
					mergeMap(([ action, state ]) => {
						if (isActionOf(fetchSettingsRecordingAsync.success, action)) {
							const settings = action.payload.data;
							return merge(
								of(bufforFormActions.setForm({
									form: {
										...state.form.buffor.form,
										type: createFormField(settings?.recording?.cfgSystemType?.value ?? 0),
										ch4Enabled: createFormField(settings?.recording?.cfgCurrentCh4Enable?.enum?.find(enumValue => enumValue.value === settings?.recording?.cfgCurrentCh4Enable?.value)?.text === OnOff.ON),
										ch5Enabled: createFormField(settings?.recording?.cfgCurrentCh5Enable?.enum?.find(enumValue => enumValue.value === settings?.recording?.cfgCurrentCh5Enable?.value)?.text === OnOff.ON),
										nominalFrequency: createFormField(settings?.recording?.cfgNominalFrequency?.value ?? 0),
										nominalVoltage: createFormField(settings?.recording?.cfgNominalVoltage?.value?.toFixed(2) ?? ""),
										unpeChannelEnable: createFormField(settings?.recording?.cfgUnpeChannelEnable?.enum?.find(enumValue => enumValue.value === settings?.recording?.cfgUnpeChannelEnable?.value)?.text === OnOff.ON),
										voltageTransducerRatio: createFormField(settings?.recording?.cfgVoltageTransducerRatio?.value?.toFixed(2) ?? ""),
										currentCh1TransducerRatio: createFormField(settings?.recording?.cfgCurrentCh1TransducerRatio?.value?.toFixed(2) ?? ""),
										currentCh2TransducerRatio: createFormField(settings?.recording?.cfgCurrentCh2TransducerRatio?.value?.toFixed(2) ?? ""),
										currentCh3TransducerRatio: createFormField(settings?.recording?.cfgCurrentCh3TransducerRatio?.value?.toFixed(2) ?? ""),
										currentCh4TransducerRatio: createFormField(settings?.recording?.cfgCurrentCh4TransducerRatio?.value?.toFixed(2) ?? ""),
										currentCh5TransducerRatio: createFormField(settings?.recording?.cfgCurrentCh5TransducerRatio?.value?.toFixed(2) ?? ""),
										phaseRotation: createFormField(settings?.recording?.cfgPhaseRotation?.value ?? 0),

										eventsHysteresis: createFormField(settings?.recording?.cfgEventsHysteresis?.value?.toFixed(2) ?? ""),
										waveformFormat: createFormField(settings?.recording?.cfgWaveformFormat?.value ?? 0),
										waveformRecDuration: createFormField(settings?.recording?.cfgWaveformRecDuration?.value?.toFixed(0) ?? ""),
										waveformPretriggerDuration: createFormField(settings?.recording?.cfgWaveformPretriggerDuration?.value?.toFixed(0) ?? ""),
										rms12RecTime: createFormField(settings?.recording?.cfgRms12RecTime?.value?.toFixed(0) ?? ""),
										rms12PretriggerTime: createFormField(settings?.recording?.cfgRms12PretriggerTime?.value?.toFixed(0) ?? ""),
									},
								})),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_SETTINGS_RECORDING })),
							);
						} else if (isActionOf(fetchSettingsMemoryAsync.success, action)) {
							const settings = action.payload.data;
							return merge(
								of(bufforFormActions.setForm({
									form: {
										...state.form.buffor.form,
										userRecordingPerc: createFormField(settings?.memory?.cfgUserRecordingSpaceQuotaInPercent?.value?.toFixed(1) ?? ""),
										stdRecordingPerc: createFormField(settings?.memory?.cfgStdRecordingSpaceQuotaInPercent?.value?.toFixed(1) ?? ""),
										energyCountersPerc: createFormField(settings?.memory?.cfgEnergyCountersSpaceQuotaInPercent?.value?.toFixed(1) ?? "", { disabled: true }),
										userDataModel: createFormField(settings?.memory?.cfgUserDataMemoryModel?.value ?? 0),
										stdDataModel: createFormField(settings?.memory?.cfgStdDataMemoryModel?.value ?? 0),
										energyDataModel: createFormField(settings?.memory?.cfgEnergyDataMemoryModel?.value ?? 0),
									},
								})),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_SETTINGS_MEMORY })),
							);
						} else if (isActionOf(fetchSettingsModbusAsync.success, action)) {
							const settings = action.payload.data;
							return merge(
								of(bufforFormActions.setForm({
									form: {
										...state.form.buffor.form,
										tcpEnabled: createFormField(settings?.communications?.cfgModbusTcpEnable?.enum?.find(enumValue => enumValue.value === settings?.communications?.cfgModbusTcpEnable?.value)?.text === OnOff.ON),
										tcpSlaveAddress: createFormField(settings?.communications?.cfgModbusTcpSlaveAddress?.value?.toString() ?? ""),
										tcpPort: createFormField(settings?.communications?.cfgModbusTcpPort?.value.toString() ?? ""),
										tcpEndianness: createFormField(settings?.communications?.cfgModbusTcpEndianness?.value ?? 0),
										rtuEnabled: createFormField(settings?.communications?.cfgModbusRtuEnable?.enum?.find(enumValue => enumValue.value === settings?.communications?.cfgModbusRtuEnable?.value)?.text === OnOff.ON),
										rtuSlaveAddress: createFormField(settings?.communications?.cfgModbusRtuSlaveAddress?.value?.toString() ?? ""),
										rtuBaudrate: createFormField(settings?.communications?.cfgModbusRtuBaudrate?.value ?? 0),
										rtuParity: createFormField(settings?.communications?.cfgModbusRtuParity?.value ?? 0),
										rtuEndianness: createFormField(settings?.communications?.cfgModbusRtuEndianness?.value ?? 0),
									},
								})),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_SETTINGS_MODBUS })),
							);
						} else if (isActionOf(fetchSettingsTimeAsync.success, action)) {
							const settings = action.payload.data;

							const daylightSavingsTime = settings?.time?.cfgDaylightSavingsTime?.enum?.find(enumValue => enumValue.value === settings?.time?.cfgDaylightSavingsTime?.value)?.text === OnOff.ON;

							const rtc = moment((settings?.time?.rtcUtcTimestamp ?? 0) * 1000)
								.subtract({ minutes: browserTimeZoneOffsetMinutes })
								.add({ minutes: ((settings?.time?.cfgTimezone?.value ?? 0) + (daylightSavingsTime ? 60 : 0)) })
								.format("YYYY-MM-DD HH:mm:ss");

							return merge(
								of(bufforFormActions.setForm({
									form: {
										...state.form.buffor.form,
										// rtcUtcTimestamp: createFormField(rtc),
										timeSyncMode: createFormField(settings?.time?.cfgTimeSyncMode?.value ?? 0),
										timezone: createFormField(getTimezone(settings?.time?.cfgTimezone?.value ?? 0)),
										daylightSavingsTime: createFormField(settings?.time?.cfgDaylightSavingsTime?.enum?.find(enumValue => enumValue.value === settings?.time?.cfgDaylightSavingsTime?.value)?.text === OnOff.ON),
										NTPEnable: createFormField(settings?.time?.cfgNtpEnable?.enum?.find(enumValue => enumValue.value === settings?.time?.cfgNtpEnable?.value)?.text === OnOff.ON),
										NTPServer1Name: createFormField(settings?.time?.cfgNtpServer1Name?.value ?? ""),
										NTPServer2Name: createFormField(settings?.time?.cfgNtpServer2Name?.value ?? ""),
										NTPServer3Name: createFormField(settings?.time?.cfgNtpServer3Name?.value ?? ""),
										NTPServer4Name: createFormField(settings?.time?.cfgNtpServer4Name?.value ?? ""),
									},
								})),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_SETTINGS_TIME })),
							);
						} else if (isActionOf(fetchSettingsFtpAsync.success, action)) {
							const settings = action.payload.data;
							return merge(
								of(bufforFormActions.setForm({
									form: {
										...state.form.buffor.form,
										ftpEnable: createFormField(settings?.communications?.cfgFtpEnable?.enum?.find(enumValue => enumValue.value === settings?.communications?.cfgFtpEnable?.value)?.text === OnOff.ON),
										ftpConnectionType: createFormField(settings?.communications?.cfgFtpConnectionType?.value ?? 0),
										ftpMode: createFormField(settings?.communications?.cfgFtpMode?.value ?? 0),
										ftpUploadTime: createFormField(settings?.communications?.cfgFtpUploadTime?.value ?? 0),
										ftpServerIp: createFormField(settings?.communications?.cfgFtpServerIp?.value?.toString() ?? ""),
										ftpServerPort: createFormField(settings?.communications?.cfgFtpServerPort?.value?.toString() ?? ""),
										ftpUserName: createFormField(settings?.communications?.cfgFtpUserName?.value?.toString() ?? ""),
										ftpPassword: createFormField(settings?.communications?.cfgFtpPassword?.value?.toString() ?? ""),
										ftpDestDirectory: createFormField(settings?.communications?.cfgFtpDestDirectory?.value?.toString() ?? ""),
									},
								})),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_SETTINGS_FTP })),
							);
						} else if (isActionOf(fetchSettingsMeasurementsUserAsync.success, action)) {
							const settings = action.payload.data;

							const [ cfgDipChannels, cfgDipActions ] = mapDipsConfig(settings?.cfgPhaseVoltageRms ?? settings?.cfgPhaseToPhaseVoltageRms);
							const [ cfgSwellChannels, cfgSwellActions ] = mapSwellConfig(settings?.cfgPhaseVoltageRms ?? settings?.cfgPhaseToPhaseVoltageRms);
							const [ cfgInterruptChannels, cfgInterruptActions ] = mapInterruptConfig(settings?.cfgPhaseVoltageRms ?? settings?.cfgPhaseToPhaseVoltageRms);
							const [ cfgWaveshapeVariationsChannels, cfgWaveshapeVariationsActions ] = mapWaveshapeVariationsConfig(settings?.cfgWaveshapeVariations);
							const [ cfgPhaseJumpsChannels, cfgPhaseJumpsActions ] = mapPhaseJumpsConfig(settings?.cfgPhaseJumps);

							const [ cfgFrequencyChannels, cfgFrequencyActions ] = mapFrequencyConfig(settings?.cfgFrequency);

							const [ cfgFlickerPstChannels, cfgFlickerPstActions ] = mapFlickerPstConfig(settings?.cfgFlickerPst);
							const [ cfgFlickerPltChannels, cfgFlickerPltActions ] = mapFlickerPltConfig(settings?.cfgFlickerPlt);

							const [ cfgCurrentChannels, cfgCurrentActions ] = mapCurrentConfig(settings?.cfgCurrentRms);

							const [ cfgReactivePowerChannels, cfgReactivePowerActions ] = mapReactivePowerConfig(settings?.cfgReactivePower);
							const [ cfgApparentPowerChannels, cfgApparentPowerActions ] = mapApparentPowerConfig(settings?.cfgApparentPower);
							const [ cfgActivePowerPlusChannels, cfgActivePowerPlusActions ] = mapActivePowerPlusConfig(settings?.cfgActivePowerPlus);
							const [ cfgActivePowerMinusChannels, cfgActivePowerMinusActions ] = mapActivePowerMinusConfig(settings?.cfgActivePowerMinus);
							const [ cfgDisplacementPowerFactorChannels, cfgDisplacementPowerFactorActions ] = mapDisplacementPowerFactorConfig(settings?.cfgDisplacementPowerFactor);
							const [ cfgPowerFactorChannels, cfgPowerFactorActions ] = mapPowerFactorConfig(settings?.cfgPowerFactor);

							const [ cfgVoltageThdfChannels, cfgVoltageThdfActions ] = mapVoltageThdfConfig(settings?.cfgVoltageThdf);
							const [ cfgCurrentThdfChannels, cfgCurrentThdfActions ] = mapCurrentThdfConfig(settings?.cfgCurrentThdf);

							return merge(
								of(bufforFormActions.setForm({
									form: {
										...state.form.buffor.form,
										cfgUserEnable: createFormField(settings.measurementUser?.cfgUserDataRecEnable?.enum?.find(enumValue => enumValue.value === settings.measurementUser?.cfgUserDataRecEnable?.value)?.text === OnOff.ON),
										cfgAggregationPeriodUser: createFormField(settings?.measurementUser?.cfgAggregationPeriodUser?.value ?? 0),
										cfgUserAggregationWaveformEnable: createFormField(settings?.measurementUser?.cfgUserWaveformsAndRms12RecEnable?.enum?.find(enumValue => enumValue.value === settings?.measurementUser?.cfgUserWaveformsAndRms12RecEnable?.value)?.text === OnOff.ON),
										cfgPowerCalculationMethod: createFormField(settings?.measurementUser?.cfgPowerCalculationMethod?.value ?? 0),

										cfgVoltageRmsRecOpt: createFormField((settings?.cfgPhaseVoltageRms ?? settings?.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsRecOpt?.value ?? 0),
										cfgVoltageRmsEventThresholdUserDip: createFormField((settings?.cfgPhaseVoltageRms ?? settings?.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdUserDip?.value?.toFixed(2) ?? ""),
										cfgVoltageRmsEventThresholdUserSwell: createFormField((settings?.cfgPhaseVoltageRms ?? settings?.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdUserSwell?.value?.toFixed(2) ?? ""),
										cfgVoltageRmsEventThresholdUserInterruption: createFormField((settings?.cfgPhaseVoltageRms ?? settings?.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdUserInterruption?.value?.toFixed(2) ?? ""),
										cfgVoltageRmsEventThresholdMaxCh4: createFormField((settings?.cfgPhaseVoltageRms ?? settings?.cfgPhaseToPhaseVoltageRms)?.cfgVoltageRmsEventThresholdMaxCh4?.value?.toFixed(2) ?? ""),
										cfgDipChannels: createFormField(cfgDipChannels),
										cfgDipActions: createFormField(cfgDipActions),
										cfgSwellChannels: createFormField(cfgSwellChannels),
										cfgSwellActions: createFormField(cfgSwellActions),
										cfgInterruptChannels: createFormField(cfgInterruptChannels),
										cfgInterruptActions: createFormField(cfgInterruptActions),
										cfgVoltageDcRecOpt: createFormField(settings?.cfgVoltageDc?.cfgVoltageDcRecOpt?.value ?? 0),
										cfgWaveshapeVariationsRecOpt: createFormField(settings?.cfgWaveshapeVariations?.cfgWaveshapeVariationsRecOpt?.value ?? 0),
										cfgWaveshapeVariationsDeadtime: createFormField(settings?.cfgWaveshapeVariations?.cfgWaveshapeVariationsDeadtime?.value?.toFixed(2) ?? ""),
										cfgWaveshapeVariationsThreshold: createFormField(settings?.cfgWaveshapeVariations?.cfgWaveshapeVariationsThreshold?.value?.toFixed(2) ?? ""),
										cfgWaveshapeVariationsChannels: createFormField(cfgWaveshapeVariationsChannels),
										cfgWaveshapeVariationsActions: createFormField(cfgWaveshapeVariationsActions),
										cfgPhaseJumpsRecOpt: createFormField(settings?.cfgPhaseJumps?.cfgPhaseJumpsRecOpt?.value ?? 0),
										cfgPhaseJumpsThreshold: createFormField(settings?.cfgPhaseJumps?.cfgPhaseJumpsThreshold?.value?.toFixed(2) ?? ""),
										cfgPhaseJumpsChannels: createFormField(cfgPhaseJumpsChannels),
										cfgPhaseJumpsActions: createFormField(cfgPhaseJumpsActions),
										cfgFrequencyRecOpt: createFormField(settings?.cfgFrequency?.cfgFrequencyRecOpt?.value ?? 0),
										cfgFrequencyEventThresholdMin: createFormField(settings?.cfgFrequency?.cfgFrequencyEventThresholdMin?.value?.toFixed(2) ?? ""),
										cfgFrequencyEventThresholdMax: createFormField(settings?.cfgFrequency?.cfgFrequencyEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgFrequencyChannels: createFormField(cfgFrequencyChannels),
										cfgFrequencyActions: createFormField(cfgFrequencyActions),
										cfgVoltageCrestFactorRecOpt: createFormField(settings?.cfgVoltageCrestFactor?.cfgVoltageCrestFactorRecOpt?.value ?? 0),
										cfgVoltageUnbalanceRecOpt: createFormField(settings?.cfgVoltageUnbalance?.cfgVoltageUnbalanceRecOpt?.value ?? 0),
										cfgVoltageUnbalanceThresholdMax: createFormField(settings?.cfgVoltageUnbalance?.cfgVoltageUnbalanceThresholdMax?.value?.toFixed(2) ?? ""),
										cfgFlickerPstRecOpt: createFormField(settings?.cfgFlickerPst?.cfgFlickerPstRecOpt?.value ?? 0),
										cfgFlickerPstThresholdMax: createFormField(settings?.cfgFlickerPst?.cfgFlickerPstThresholdMax?.value?.toFixed(2) ?? ""),
										cfgFlickerPstChannels: createFormField(cfgFlickerPstChannels),
										cfgFlickerPstActions: createFormField(cfgFlickerPstActions),
										cfgFlickerPltRecOpt: createFormField(settings?.cfgFlickerPlt?.cfgFlickerPltRecOpt?.value ?? 0),
										cfgFlickerPltThresholdMax: createFormField(settings?.cfgFlickerPlt?.cfgFlickerPltThresholdMax?.value?.toFixed(2) ?? ""),
										cfgFlickerPltChannels: createFormField(cfgFlickerPltChannels),
										cfgFlickerPltActions: createFormField(cfgFlickerPltActions),
										cfgCurrentRmsRecOpt: createFormField(settings?.cfgCurrentRms?.cfgCurrentRmsRecOpt?.value ?? 0),
										cfgCurrentRmsEventThresholdMax: createFormField(settings?.cfgCurrentRms?.cfgCurrentRmsEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgCurrentRmsEventThresholdMaxCh4: createFormField(settings?.cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh4?.value?.toFixed(2) ?? ""),
										cfgCurrentRmsEventThresholdMaxCh5: createFormField(settings?.cfgCurrentRms?.cfgCurrentRmsEventThresholdMaxCh5?.value?.toFixed(2) ?? ""),
										cfgCurrentChannels: createFormField(cfgCurrentChannels),
										cfgCurrentActions: createFormField(cfgCurrentActions),
										cfgCurrentCrestFactorRecOpt: createFormField(settings?.cfgCurrentCrestFactor?.cfgCurrentCrestFactorRecOpt?.value ?? 0),
										cfgCurrentUnbalanceRecOpt: createFormField(settings?.cfgCurrentUnbalance?.cfgCurrentUnbalanceRecOpt?.value ?? 0),
										cfgActivePowerRecOpt: createFormField(settings?.cfgActivePower?.cfgActivePowerRecOpt?.value ?? 0),
										cfgActivePowerEventThresholdMax: createFormField(settings?.cfgActivePower?.cfgActivePowerEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgActivePowerPlusRecOpt: createFormField(settings?.cfgActivePowerPlus?.cfgActivePowerPlusRecOpt?.value ?? 0),
										cfgActivePowerPlusEventThresholdMax: createFormField(settings?.cfgActivePowerPlus?.cfgActivePowerPlusEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgActivePowerPlusChannels: createFormField(cfgActivePowerPlusChannels),
										cfgActivePowerPlusActions: createFormField(cfgActivePowerPlusActions),
										cfgActivePowerMinusRecOpt: createFormField(settings?.cfgActivePowerMinus?.cfgActivePowerMinusRecOpt?.value ?? 0),
										cfgActivePowerMinusEventThresholdMax: createFormField(settings?.cfgActivePowerMinus?.cfgActivePowerMinusEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgActivePowerMinusChannels: createFormField(cfgActivePowerMinusChannels),
										cfgActivePowerMinusActions: createFormField(cfgActivePowerMinusActions),
										cfgActivePowerFundamentalRecOpt: createFormField(settings?.cfgActivePowerFundamental?.cfgActivePowerFundamentalRecOpt?.value ?? 0),
										cfgApparentPowerRecOpt: createFormField(settings?.cfgApparentPower?.cfgApparentPowerRecOpt?.value ?? 0),
										cfgApparentPowerEventThresholdMax: createFormField(settings?.cfgApparentPower?.cfgApparentPowerEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgApparentPowerChannels: createFormField(cfgApparentPowerChannels),
										cfgApparentPowerActions: createFormField(cfgApparentPowerActions),
										cfgApparentPowerFundamentalRecOpt: createFormField(settings?.cfgApparentPowerFundamental?.cfgApparentPowerFundamentalRecOpt?.value ?? 0),
										cfgApparentPowerNonfundamentalRecOpt: createFormField(settings?.cfgApparentPowerNonfundamental?.cfgApparentPowerNonfundamentalRecOpt?.value ?? 0),
										cfgDistortionPowerBudeanuRecOpt: createFormField(settings?.cfgDistortionPowerBudeanu?.cfgDistortionPowerBudeanuRecOpt?.value ?? 0),
										cfgReactivePowerRecOpt: createFormField(settings?.cfgReactivePower?.cfgReactivePowerRecOpt?.value ?? 0),
										cfgReactivePowerEventThresholdMax: createFormField(settings?.cfgReactivePower?.cfgReactivePowerEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgReactivePowerChannels: createFormField(cfgReactivePowerChannels),
										cfgReactivePowerActions: createFormField(cfgReactivePowerActions),
										cfgPowerFactorRecOpt: createFormField(settings?.cfgPowerFactor?.cfgPowerFactorRecOpt?.value ?? 0),
										cfgPowerFactorEventThresholdMax: createFormField(settings?.cfgPowerFactor?.cfgPowerFactorEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgPowerFactorChannels: createFormField(cfgPowerFactorChannels),
										cfgPowerFactorActions: createFormField(cfgPowerFactorActions),
										cfgPowerFactorFundamentalRecOpt: createFormField(settings?.cfgPowerFactorFundamental?.cfgPowerFactorFundamentalRecOpt?.value ?? 0),
										cfgDisplacementPowerFactorRecOpt: createFormField(settings?.cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorRecOpt?.value ?? 0),
										cfgDisplacementPowerFactorEventThresholdMax: createFormField(settings?.cfgDisplacementPowerFactor?.cfgDisplacementPowerFactorEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgDisplacementPowerFactorChannels: createFormField(cfgDisplacementPowerFactorChannels),
										cfgDisplacementPowerFactorActions: createFormField(cfgDisplacementPowerFactorActions),
										cfgTangent4QRecOpt: createFormField(settings?.cfgTangent4Q?.cfgTangent4QRecOpt?.value ?? 0),
										// cfgTangentQuadrant1EventThresholdMin: createFormField(settings?.cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMin?.value?.toString() ?? ""),
										// cfgTangentQuadrant1EventThresholdMax: createFormField(settings?.cfgTangent4Q?.cfgTangentQuadrant1EventThresholdMax?.value?.toString() ?? ""),
										// cfgTangentQuadrant2EventThresholdMin: createFormField(settings?.cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMin?.value?.toString() ?? ""),
										// cfgTangentQuadrant2EventThresholdMax: createFormField(settings?.cfgTangent4Q?.cfgTangentQuadrant2EventThresholdMax?.value?.toString() ?? ""),
										// cfgTangentQuadrant3EventThresholdMin: createFormField(settings?.cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMin?.value?.toString() ?? ""),
										// cfgTangentQuadrant3EventThresholdMax: createFormField(settings?.cfgTangent4Q?.cfgTangentQuadrant3EventThresholdMax?.value?.toString() ?? ""),
										// cfgTangentQuadrant4EventThresholdMin: createFormField(settings?.cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMin?.value?.toString() ?? ""),
										// cfgTangentQuadrant4EventThresholdMax: createFormField(settings?.cfgTangent4Q?.cfgTangentQuadrant4EventThresholdMax?.value?.toString() ?? ""),
										cfgActiveEnergyRecOpt: createFormField(settings?.cfgActiveEnergy?.cfgActiveEnergyRecOpt?.value ?? 0),
										cfgActiveEnergyPlusEventThresholdMax: createFormField(settings?.cfgActiveEnergy?.cfgActiveEnergyPlusEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgActiveEnergyMinusEventThresholdMax: createFormField(settings?.cfgActiveEnergy?.cfgActiveEnergyMinusEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgApparentEnergyRecOpt: createFormField(settings?.cfgApparentEnergy?.cfgApparentEnergyRecOpt?.value ?? 0),
										cfgApparentEnergyEventThresholdMax: createFormField(settings?.cfgApparentEnergy?.cfgApparentEnergyEventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgReactiveEnergy4QRecOpt: createFormField(settings?.cfgReactiveEnergy4Q?.cfgReactiveEnergy4QRecOpt?.value ?? 0),
										cfgReactiveEnergyQuadrant1EventThresholdMax: createFormField(settings?.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant1EventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgReactiveEnergyQuadrant2EventThresholdMax: createFormField(settings?.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant2EventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgReactiveEnergyQuadrant3EventThresholdMax: createFormField(settings?.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant3EventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgReactiveEnergyQuadrant4EventThresholdMax: createFormField(settings?.cfgReactiveEnergy4Q?.cfgReactiveEnergyQuadrant4EventThresholdMax?.value?.toFixed(2) ?? ""),
										cfgVoltageThdfRecOpt: createFormField(settings?.cfgVoltageThdf?.cfgVoltageThdfRecOpt?.value ?? 0),
										cfgVoltageThdfThresholdMax: createFormField(settings?.cfgVoltageThdf?.cfgVoltageThdfThresholdMax?.value?.toFixed(2) ?? ""),
										cfgVoltageThdfChannels: createFormField(cfgVoltageThdfChannels),
										cfgVoltageThdfActions: createFormField(cfgVoltageThdfActions),
										cfgVoltageThdrRecOpt: createFormField(settings?.cfgVoltageThdr?.cfgVoltageThdrRecOpt?.value ?? 0),
										cfgVoltageHarmonicsRecOpt: createFormField(settings?.cfgVoltageHarmonics?.cfgVoltageHarmonicsRecOpt?.value ?? 0),
										cfgCurrentThdfRecOpt: createFormField(settings?.cfgCurrentThdf?.cfgCurrentThdfRecOpt?.value ?? 0),
										cfgCurrentThdfThresholdMax: createFormField(settings?.cfgCurrentThdf?.cfgCurrentThdfThresholdMax?.value?.toFixed(2) ?? ""),
										cfgCurrentThdfChannels: createFormField(cfgCurrentThdfChannels),
										cfgCurrentThdfActions: createFormField(cfgCurrentThdfActions),
										cfgCurrentThdrRecOpt: createFormField(settings?.cfgCurrentThdr?.cfgCurrentThdrRecOpt?.value ?? 0),
										cfgCurrentHarmonicsRecOpt: createFormField(settings?.cfgCurrentHarmonics?.cfgCurrentHarmonicsRecOpt?.value ?? 0),
										cfgActivePowerHarmonicsRecOpt: createFormField(settings?.cfgActivePowerHarmonics?.cfgActivePowerHarmonicsRecOpt?.value ?? 0),
										cfgHarmonicsAnglesUiRecOpt: createFormField(settings?.cfgHarmonicsAnglesUi?.cfgHarmonicsAnglesUiRecOpt?.value ?? 0),
										cfgReactivePowerHarmonicsRecOpt: createFormField(settings?.cfgReactivePowerHarmonics?.cfgReactivePowerHarmonicsRecOpt?.value ?? 0),
										cfgVoltageTidfRecOpt: createFormField(settings?.cfgVoltageTidf?.cfgVoltageTidfRecOpt?.value ?? 0),
										cfgVoltageTidrRecOpt: createFormField(settings?.cfgVoltageTidr?.cfgVoltageTidrRecOpt?.value ?? 0),
										cfgCurrentTidfRecOpt: createFormField(settings?.cfgCurrentTidf?.cfgCurrentTidfRecOpt?.value ?? 0),
										cfgCurrentTidrRecOpt: createFormField(settings?.cfgCurrentTidr?.cfgCurrentTidrRecOpt?.value ?? 0),
										cfgVoltageInterharmonicsRecOpt: createFormField(settings?.cfgVoltageInterharmonics?.cfgVoltageInterharmonicsRecOpt?.value ?? 0),
										cfgCurrentInterharmonicsRecOpt: createFormField(settings?.cfgCurrentInterharmonics?.cfgCurrentInterharmonicsRecOpt?.value ?? 0),
										cfgKfactorRecOpt: createFormField(settings?.cfgKfactor?.cfgKfactorRecOpt?.value ?? 0),
										cfgPhasorRecOpt: createFormField(settings?.measurementUser?.cfgPhasorRecOpt?.value ?? 0),

										cfgVoltage29KRecOpt: createFormField(settings?.cfgVoltage29K?.cfgVoltage29KRecOpt?.value ?? 0),
										cfgVoltage8150KRecOpt: createFormField(settings?.cfgVoltage8150K?.cfgVoltage8150KRecOpt?.value ?? 0),

										cfgMainsSignallingUr1RecOpt: createFormField(settings?.cfgMainsSignallingUr1?.cfgMainsSignallingUr1RecOpt?.value ?? 0),
										cfgMainsSignallingUr1Frequency: createFormField(settings?.cfgMainsSignallingUr1?.cfgMainsSignallingUr1Frequency?.value?.toFixed(2) ?? ""),
										cfgMainsSignallingUr2RecOpt: createFormField(settings?.cfgMainsSignallingUr2?.cfgMainsSignallingUr2RecOpt?.value ?? 0),
										cfgMainsSignallingUr2Frequency: createFormField(settings?.cfgMainsSignallingUr2?.cfgMainsSignallingUr2Frequency?.value?.toFixed(2) ?? ""),
									},
								})),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_SETTINGS_MEASUREMENTS_USER })),
							);
						} else if (isActionOf(fetchSettingsMeasurementsStandardAsync.success, action)) {
							const settings = action.payload.data;
							return merge(
								of(bufforFormActions.setForm({
									form: {
										...state.form.buffor.form,
										cfgStdEnable: createFormField(settings?.measurementStandard?.cfgStdDataRecEnable?.enum?.find(enumValue => enumValue.value === settings?.measurementStandard?.cfgStdDataRecEnable?.value)?.text === OnOff.ON),
										cfgStdType: createFormField(settings?.measurementStandard?.cfgStdType?.value ?? 0),
										cfgStdAggregationWaveformEnable: createFormField(settings?.measurementStandard?.cfgStdWaveformsAndRms12RecEnable?.enum?.find(enumValue => enumValue.value === settings?.measurementStandard?.cfgStdWaveformsAndRms12RecEnable?.value)?.text === OnOff.ON),
									},
								})),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_SETTINGS_MEASUREMENTS_STANDARD })),
							);
						} else if (isActionOf(fetchSettingsIecAsync.success, action)) {
							const settings = action.payload.data;
							return merge(
								of(bufforFormActions.setForm({
									form: {
										...state.form.buffor.form,
										cfgIec61850Enable: createFormField(settings?.communications?.cfgIec61850Enable?.enum?.find(enumValue => enumValue.value === settings?.communications?.cfgIec61850Enable?.value)?.text === OnOff.ON),
										cfgIec61850IedName: createFormField(settings?.communications?.cfgIec61850IedName?.value?.toString() ?? ""),
										cfgIec61850PhaseVoltageDbRef: createFormField(settings?.communications?.cfgIec61850PhaseVoltageDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850PhaseVoltageDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850PhaseVoltageDb).toFixed(2)),
										cfgIec61850PhaseToPhaseVoltageDbRef: createFormField(settings?.communications?.cfgIec61850PhaseToPhaseVoltageDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850PhaseToPhaseVoltageDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850PhaseToPhaseVoltageDb).toFixed(2)),
										cfgIec61850UneVoltageDbRef: createFormField(settings?.communications?.cfgIec61850UneVoltageDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850UneVoltageDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850UneVoltageDb).toFixed(2)),
										cfgIec61850PhaseCurrentDbRef: createFormField(settings?.communications?.cfgIec61850PhaseCurrentDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850PhaseCurrentDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850PhaseCurrentDb).toFixed(2)),
										cfgIec61850NCurrentDbRef: createFormField(settings?.communications?.cfgIec61850NCurrentDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850NCurrentDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850NCurrentDb).toFixed(2)),
										cfgIec61850ECurrentDbRef: createFormField(settings?.communications?.cfgIec61850ECurrentDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850ECurrentDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850ECurrentDb).toFixed(2)),
										cfgIec61850ActivePowerTotalDbRef: createFormField(settings?.communications?.cfgIec61850ActivePowerTotalDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850ActivePowerTotalDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850ActivePowerTotalDb).toFixed(2)),
										cfgIec61850ReactivePowerTotalDbRef: createFormField(settings?.communications?.cfgIec61850ReactivePowerTotalDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850ReactivePowerTotalDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850ReactivePowerTotalDb).toFixed(2)),
										cfgIec61850ApparentPowerTotalDbRef: createFormField(settings?.communications?.cfgIec61850ApparentPowerTotalDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850ApparentPowerTotalDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850ApparentPowerTotalDb).toFixed(2)),
										cfgIec61850ActivePowerPhaseDbRef: createFormField(settings?.communications?.cfgIec61850ActivePowerPhaseDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850ActivePowerPhaseDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850ActivePowerPhaseDb).toFixed(2)),
										cfgIec61850ReactivePowerPhaseDbRef: createFormField(settings?.communications?.cfgIec61850ReactivePowerPhaseDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850ReactivePowerPhaseDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850ReactivePowerPhaseDb).toFixed(2)),
										cfgIec61850ApparentPowerPhaseDbRef: createFormField(settings?.communications?.cfgIec61850ApparentPowerPhaseDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850ApparentPowerPhaseDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850ApparentPowerPhaseDb).toFixed(2)),
										cfgIec61850FrequencyDbRef: createFormField(settings?.communications?.cfgIec61850FrequencyDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850FrequencyDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850FrequencyDb).toFixed(2)),
										cfgIec61850VoltageHarmonicsDbRef: createFormField(settings?.communications?.cfgIec61850VoltageHarmonicsDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850VoltageHarmonicsDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850VoltageHarmonicsDb).toFixed(2)),
										cfgIec61850CurrentHarmonicsDbRef: createFormField(settings?.communications?.cfgIec61850CurrentHarmonicsDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850CurrentHarmonicsDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850CurrentHarmonicsDb).toFixed(2)),
										cfgIec61850VoltageInterharmonicsDbRef: createFormField(settings?.communications?.cfgIec61850VoltageInterharmonicsDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850VoltageInterharmonicsDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850VoltageInterharmonicsDb).toFixed(2)),
										cfgIec61850CurrentInterharmonicsDbRef: createFormField(settings?.communications?.cfgIec61850CurrentInterharmonicsDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850CurrentInterharmonicsDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850CurrentInterharmonicsDb).toFixed(2)),
										cfgIec61850VoltageThdDbRef: createFormField(settings?.communications?.cfgIec61850VoltageThdDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850VoltageThdDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850VoltageThdDb).toFixed(2)),
										cfgIec61850CurrentThdDbRef: createFormField(settings?.communications?.cfgIec61850CurrentThdDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850CurrentThdDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850CurrentThdDb).toFixed(2)),
										cfgIec61850VoltageTidDbRef: createFormField(settings?.communications?.cfgIec61850VoltageTidDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850VoltageTidDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850VoltageTidDb).toFixed(2)),
										cfgIec61850CurrentTidDbRef: createFormField(settings?.communications?.cfgIec61850CurrentTidDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850CurrentTidDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850CurrentTidDb).toFixed(2)),
										cfgIec61850UnbalanceVoltagePositiveSequenceDbRef: createFormField(settings?.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850UnbalanceVoltagePositiveSequenceDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850UnbalanceVoltagePositiveSequenceDb).toFixed(2)),
										cfgIec61850UnbalanceVoltageNegativeSequenceDbRef: createFormField(settings?.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850UnbalanceVoltageNegativeSequenceDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850UnbalanceVoltageNegativeSequenceDb).toFixed(2)),
										cfgIec61850UnbalanceVoltageZeroSequenceDbRef: createFormField(settings?.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850UnbalanceVoltageZeroSequenceDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850UnbalanceVoltageZeroSequenceDb).toFixed(2)),
										cfgIec61850UnbalanceVoltageNegativeDbRef: createFormField(settings?.communications?.cfgIec61850UnbalanceVoltageNegativeDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850UnbalanceVoltageNegativeDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850UnbalanceVoltageNegativeDb).toFixed(2)),
										cfgIec61850UnbalanceCurrentPositiveSequenceDbRef: createFormField(settings?.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850UnbalanceCurrentPositiveSequenceDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850UnbalanceCurrentPositiveSequenceDb).toFixed(2)),
										cfgIec61850UnbalanceCurrentNegativeSequenceDbRef: createFormField(settings?.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850UnbalanceCurrentNegativeSequenceDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850UnbalanceCurrentNegativeSequenceDb).toFixed(2)),
										cfgIec61850UnbalanceCurrentZeroSequenceDbRef: createFormField(settings?.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850UnbalanceCurrentZeroSequenceDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850UnbalanceCurrentZeroSequenceDb).toFixed(2)),
										cfgIec61850UnbalanceCurrentNegativeDbRef: createFormField(settings?.communications?.cfgIec61850UnbalanceCurrentNegativeDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850UnbalanceCurrentNegativeDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850UnbalanceCurrentNegativeDb).toFixed(2)),
										cfgIec61850FlickerPstDbRef: createFormField(settings?.communications?.cfgIec61850FlickerPstDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850FlickerPstDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850FlickerPstDb).toFixed(2)),
										cfgIec61850FlickerPltDbRef: createFormField(settings?.communications?.cfgIec61850FlickerPltDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850FlickerPltDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850FlickerPltDb).toFixed(2)),
										cfgIec6185029KhzVoltageDbRef: createFormField(settings?.communications?.cfgIec6185029KhzVoltageDbRef?.value?.toFixed(2) ?? ""),
										cfgIec6185029KhzVoltageDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec6185029KhzVoltageDb).toFixed(2)),
										cfgIec6185029KhzVoltageMaxDbRef: createFormField(settings?.communications?.cfgIec6185029KhzVoltageMaxDbRef?.value?.toFixed(2) ?? ""),
										cfgIec6185029KhzVoltageMaxDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec6185029KhzVoltageMaxDb).toFixed(2)),
										cfgIec618509150KhzVoltageDbRef: createFormField(settings?.communications?.cfgIec618509150KhzVoltageDbRef?.value?.toFixed(2) ?? ""),
										cfgIec618509150KhzVoltageDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec618509150KhzVoltageDb).toFixed(2)),
										cfgIec618509150KhzVoltageMaxDbRef: createFormField(settings?.communications?.cfgIec618509150KhzVoltageMaxDbRef?.value?.toFixed(2) ?? ""),
										cfgIec618509150KhzVoltageMaxDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec618509150KhzVoltageMaxDb).toFixed(2)),
										cfgIec61850BatteryVoltageDbRef: createFormField(settings?.communications?.cfgIec61850BatteryVoltageDbRef?.value?.toFixed(2) ?? ""),
										cfgIec61850BatteryVoltageDb: createFormField(translateValueToPercent(settings?.communications?.cfgIec61850BatteryVoltageDb).toFixed(2)),
									},
								})),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_SETTINGS_IEC })),
							);
						} else if (isActionOf(fetchSettingsIoAsync.success, action)) {
							const settings = action.payload.data;
							return merge(
								of(bufforFormActions.setForm({
									form: {
										...state.form.buffor.form,
										cfgDigitalInputInternal1ActiveState: createFormField(settings?.io?.cfgDigitalInputInternal1ActiveState?.value ?? 0),
										cfgDigitalInputInternal1RecOpt: createFormField(settings?.io?.cfgDigitalInputInternal1RecOpt?.value ?? 0),
										cfgDigitalInputInternal2ActiveState: createFormField(settings?.io?.cfgDigitalInputInternal2ActiveState?.value ?? 0),
										cfgDigitalInputInternal2RecOpt: createFormField(settings?.io?.cfgDigitalInputInternal2RecOpt?.value ?? 0),
										cfgRelay1ActiveState: createFormField(settings?.io?.cfgRelay1ActiveState?.value ?? 0),
										cfgRelay1Mode: createFormField(settings?.io?.cfgRelay1Mode?.value ?? 0),
										cfgRelay1ActiveTime: createFormField(settings?.io?.cfgRelay1ActiveTime?.value?.toString() ?? ""),
										cfgRelay1DeadTime: createFormField(settings?.io?.cfgRelay1DeadTime?.value?.toFixed(0) ?? ""),
										cfgRelay2ActiveState: createFormField(settings?.io?.cfgRelay2ActiveState?.value ?? 0),
										cfgRelay2Mode: createFormField(settings?.io?.cfgRelay2Mode?.value ?? 0),
										cfgRelay2ActiveTime: createFormField(settings?.io?.cfgRelay2ActiveTime?.value?.toString() ?? ""),
										cfgRelay2DeadTime: createFormField(settings?.io?.cfgRelay2DeadTime?.value?.toFixed(0) ?? ""),
									},
								})),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_SETTINGS_IO })),
							);
						}

						return merge(
							...settingsLoadingRecords.map(record => of(removeLoadingRecord(record))),
							of(forgetSession()),
						);
					}),
				),
			),
		),
	);

export const uiFetchSuccessSettingsTimeEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(fetchSettingsTimeAsync.success)),
		delay(50),
		map(action => {
			const settings = action.payload.data;

			const daylightSavingsTime = settings?.time?.cfgDaylightSavingsTime?.enum?.find(enumValue => enumValue.value === settings?.time?.cfgDaylightSavingsTime?.value)?.text === OnOff.ON;

			const rtc = moment((settings?.time?.rtcUtcTimestamp ?? 0) * 1000)
				.subtract({ minutes: browserTimeZoneOffsetMinutes })
				.add({ minutes: ((settings?.time?.cfgTimezone?.value ?? 0) + (daylightSavingsTime ? 60 : 0)) })
				.format("YYYY-MM-DD HH:mm:ss");

			return bufforFormActions.setForm({
				form: {
					...state$.value.form.buffor.form,
					rtcUtcTimestamp: createFormField(rtc),
				},
			});
		}),
	);

export const uiFetchSettingsIecProfileEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchSettingsIecProfile)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.DOWNLOAD_IEC_PROFILE })),
				of(fetchSettingsIecProfileAsync.request()),
				action$.pipe(
					filter(action => isActionOf(fetchSettingsIecProfileAsync.success, action) || isActionOf(fetchSettingsIecProfileAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(fetchSettingsIecProfileAsync.success, responseAction)) {
							const blob = new Blob([ responseAction.payload.data ], { type: "text/xml" });

							// Utwórz link do pobrania
							const downloadLink = document.createElement("a");
							downloadLink.href = URL.createObjectURL(blob);
							downloadLink.download = `${ action.payload }.icd`; // nazwa pliku do pobrania

							// Dodaj link do dokumentu i kliknij go programowo
							document.body.appendChild(downloadLink);
							downloadLink.click();

							// Usuń link po pobraniu
							document.body.removeChild(downloadLink);
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.DOWNLOAD_IEC_PROFILE })),
							);
						}

						return merge(
							of(removeLoadingRecord({ loadableType: LoadableType.DOWNLOAD_IEC_PROFILE })),
							of(displayToast({ type: ToastType.ERROR, content: i18n.t("RESPONSES.SETTINGS.no profile file") })),
						);
					}),
				),
			),
		),
	);

export const uiTestFtpConnectionEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiTestFtpConnection)),
		switchMap(action =>
			concat(
				of(removeMessagesFromView(View.FTP)),
				of(addLoadingRecord({ loadableType: LoadableType.TEST_FTP_CONNECTION })),
				of(testFtpConnectionAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(testFtpConnectionAsync.success, action) || isActionOf(testFtpConnectionAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(testFtpConnectionAsync.success, responseAction) && responseAction.payload.data.ftpTest.status === 0) { // TEST_OK
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.TEST_FTP_CONNECTION })),
								of(addMessage({ view: View.FTP, type: SnackbarMessageType.SUCCESS, message: AddMessageContent.FTP_TEST_SUCCESS })),
							);
						}

						return merge(
							of(removeLoadingRecord({ loadableType: LoadableType.TEST_FTP_CONNECTION })),
							of(addMessage({ view: View.FTP, type: SnackbarMessageType.ERROR, message: AddMessageContent.FTP_TEST_FAILURE })),
						);
					}),
				),
			),
		),
	);

export const uiSetBufforConfigurationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiSetBufforConfiguration)),
		mergeMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.SET_BUFFOR_CONFIGURATION })),
				of(setConfigurationAsync.request(action.payload.payload)),
				action$.pipe(
					filter(action => isActionOf(setConfigurationAsync.success, action) || isActionOf(setConfigurationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(setConfigurationAsync.success, responseAction)) {
							return merge(
								// of(bufforFormActions.setForm({ form: action.payload.form })),
								of(uiFetchSettings()),
								of(removeLoadingRecord({ loadableType: LoadableType.SET_BUFFOR_CONFIGURATION })),
							);
						}

						return of(removeLoadingRecord({ loadableType: LoadableType.SET_BUFFOR_CONFIGURATION }));
					}),
				),
			),
		),
	);
